import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export const CheckCircle = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: IIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=check_circle, Size=24">
        <path
          id="Vector"
          d="M10.5131 14.1195L8.17666 11.7831C8.01073 11.6172 7.80724 11.5342 7.56621 11.5342C7.32519 11.5342 7.11183 11.6255 6.92611 11.8081C6.76018 11.9781 6.67721 12.1833 6.67721 12.4239C6.67721 12.6645 6.76218 12.8677 6.93211 13.0337L9.91036 16.0369C10.0821 16.2069 10.2825 16.2918 10.5114 16.2918C10.7404 16.2918 10.9419 16.2069 11.1158 16.0369L17.0191 10.1277C17.2057 9.9451 17.299 9.73428 17.299 9.49524C17.299 9.25623 17.2057 9.04386 17.0191 8.85814C16.8364 8.69221 16.6205 8.61341 16.3711 8.62174C16.1218 8.63008 15.9126 8.71721 15.7435 8.88314L10.5131 14.1195ZM12.0009 22.1494C10.6097 22.1494 9.29689 21.8838 8.06234 21.3525C6.82779 20.8212 5.74951 20.0945 4.82751 19.1725C3.90551 18.2505 3.17885 17.1725 2.64754 15.9385C2.11624 14.7045 1.85059 13.392 1.85059 12.0008C1.85059 10.593 2.11624 9.27184 2.64754 8.03729C3.17885 6.80274 3.90519 5.72853 4.82656 4.81464C5.74794 3.90078 6.82579 3.17729 8.06009 2.64419C9.29437 2.11109 10.6072 1.84454 11.9987 1.84454C13.4068 1.84454 14.7284 2.11094 15.9634 2.64374C17.1985 3.17653 18.2728 3.89959 19.1863 4.81294C20.0999 5.72629 20.8232 6.80032 21.3561 8.03502C21.889 9.26974 22.1555 10.5914 22.1555 12C22.1555 13.3917 21.8889 14.7048 21.3558 15.9392C20.8227 17.1737 20.0993 18.2517 19.1854 19.1732C18.2715 20.0948 17.1976 20.8212 15.9636 21.3525C14.7296 21.8838 13.4087 22.1494 12.0009 22.1494ZM12 20.4462C14.3508 20.4462 16.3461 19.6233 17.9862 17.9774C19.6262 16.3315 20.4462 14.3391 20.4462 12C20.4462 9.64928 19.6262 7.65389 17.9862 6.01384C16.3461 4.37381 14.3498 3.55379 11.997 3.55379C9.66099 3.55379 7.66978 4.37381 6.02341 6.01384C4.37703 7.65389 3.55384 9.65028 3.55384 12.003C3.55384 14.339 4.37677 16.3303 6.02264 17.9766C7.6685 19.623 9.66097 20.4462 12 20.4462Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
