import { RefreshInfoToolbar } from "../shared/RefreshInfoToolbar";
import { ExpenseAccountsTable } from "./ExpenseAccountsTable";

type ExpenseAccountsProps = {
  accounts?: ExpenseAccountsWithPagination;
};

export const ExpenseAccounts = ({ accounts }: ExpenseAccountsProps) => {
  return (
    <section className="flex flex-col gap-24">
      <RefreshInfoToolbar />
      <ExpenseAccountsTable accounts={accounts} />
    </section>
  );
};
