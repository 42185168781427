import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import LoaderBusiness from "components/Loader/LoaderBusiness";
import { Card } from "design_system/Card";
import { MamoCardRounded } from "design_system/Illustrations/Cards";
import { CardsUpsellBanner } from "./components/CardsUpsellBanner";
import { PageHeader } from "./components/PageHeader";

import { useGetWalletsAndCards } from "./hooks/useGetWalletsAndCards";

import routes from "constants/routes";
import { currencyMappings } from "./constants/currencyMappings";
import type { CardDetail, WalletDetail, WalletV2Props } from "./typeDefs";

const cardClasses =
  "flex flex-col gap-40 bg-surface-2 cursor-pointer max-w-320 hover:bg-surface-3 hover:border hover:border-border-1";

export const WalletV2 = ({ isFreelancer }: WalletV2Props) => {
  const navigate = useNavigate();

  const { getWalletsAndCards } = useGetWalletsAndCards();
  const walletsAndCards = getWalletsAndCards.data;

  // Freelancer will never have cards, business might or might not have cards
  const showCards =
    !isFreelancer &&
    !!walletsAndCards?.cards &&
    walletsAndCards?.cards?.length > 0;

  // If business is not freelancer and does not have cards, show upsell banner
  const showCardsUpsellBanner = !isFreelancer && !showCards;

  const handleSelectWallet = (wallet) => {
    navigate(routes.MANAGE.WALLET.SHOW(wallet.id));
  };

  useEffect(() => {
    localStorage.removeItem("wallet");
  }, []);

  if (!getWalletsAndCards.isError && getWalletsAndCards.isLoading) {
    return (
      <section>
        <LoaderBusiness />
      </section>
    );
  }

  return (
    <>
      <PageHeader />

      <section className="px-24 md:px-40">
        {/* ACCOUNTS SECTION */}
        <section className="space-y-24 py-40">
          {walletsAndCards?.wallets?.map((wallet: WalletDetail) => {
            const mapping = currencyMappings[wallet.currency];

            return (
              <Card
                key={wallet.id}
                cardClasses={cardClasses}
                onClick={() => handleSelectWallet(wallet)}
              >
                <mapping.Icon size="40" />
                <div className="space-y-4">
                  <h2 className="font-medium text-18 text-text-color-01">
                    {wallet.totalBalanceFormatted}
                  </h2>
                  <p className="text-14 text-text-color-02">{mapping.label}</p>
                </div>
              </Card>
            );
          })}
        </section>

        {/* CARDS SECTION */}

        {/* Merchant is not a freelancer */}
        {showCards || showCardsUpsellBanner ? (
          <section className="space-y-24 py-40">
            <h1 className="label-large md:header-medium">Cards</h1>

            {showCards ? (
              walletsAndCards.cards.map((card: CardDetail) => {
                return (
                  <Card
                    key={card.id}
                    cardClasses={cardClasses}
                    onClick={() =>
                      (window.location.href = routes.MANAGE.CARDS.INDEX())
                    }
                  >
                    <MamoCardRounded size="40" />
                    <div className="space-y-4">
                      <h2 className="font-medium text-18 text-text-color-01">
                        {card.totalBalanceFormatted}
                      </h2>
                      <p className="text-14 text-text-color-02">UAE Dirham</p>
                    </div>
                  </Card>
                );
              })
            ) : (
              // They are not using cards feature
              <CardsUpsellBanner />
            )}
          </section>
        ) : // They are using cards feature but do not have any cards created at the moment
        null}
      </section>
    </>
  );
};
