import { useReducer } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import SuccessPage from "components/shared/SuccessPage";
import routes from "constants/routes";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import type IChoosePlanProps from "./interfaces/IChoosePlanProps";
import choosePlanReducer, { initialState } from "./reducers/choosePlanReducer";

const ChoosePlan = (props: IChoosePlanProps) => {
  const [state, dispatch] = useReducer(choosePlanReducer, initialState);
  const { activePlan, plans, eligibleForExpeditedSettlements } = props;

  const showSuccessText = () => {
    return (
      <p>
        Your pricing plan is all sorted - we can’t wait to see how you put our
        Mamo Business features to use!
      </p>
    );
  };

  return (
    <main>
      <Router>
        <Routes>
          <Route
            path={routes.MANAGE.PLANS.INDEX()}
            element={
              <Step1
                activePlan={activePlan}
                plans={plans}
                state={state}
                dispatch={dispatch}
              />
            }
          />
          <Route
            path={routes.MANAGE.PLANS.CHOOSE_SETTLEMENT()}
            element={
              <Step2
                state={state}
                dispatch={dispatch}
                activePlan={activePlan}
                eligibleForExpeditedSettlements={
                  eligibleForExpeditedSettlements
                }
              />
            }
          />
          <Route
            path={routes.MANAGE.PLANS.CONFIRM_CHANGES()}
            element={<Step3 state={state} dispatch={dispatch} />}
          />
          <Route
            path={routes.MANAGE.PLANS.SUCCESS()}
            element={
              <SuccessPage
                title={`Thank you for choosing ${state.selectedPlan}!`}
                subtext={showSuccessText()}
              />
            }
          />
        </Routes>
      </Router>
    </main>
  );
};

export default ChoosePlan;
