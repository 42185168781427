import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export const ErrorFill = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: IIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.9982 17.1494C12.2726 17.1494 12.5009 17.0599 12.6832 16.8809C12.8654 16.7018 12.9566 16.4751 12.9566 16.2007C12.9566 15.9263 12.866 15.697 12.685 15.5128C12.504 15.3285 12.2762 15.2364 12.0018 15.2364C11.7275 15.2364 11.4991 15.3284 11.3169 15.5123C11.1346 15.6963 11.0435 15.9254 11.0435 16.1998C11.0435 16.4742 11.134 16.7011 11.3151 16.8804C11.4961 17.0598 11.7238 17.1494 11.9982 17.1494ZM12.0794 13.181C12.3211 13.181 12.5227 13.0991 12.6843 12.9354C12.8459 12.7718 12.9267 12.5697 12.9267 12.3293V7.60597C12.9267 7.36557 12.8444 7.16354 12.6799 6.99987C12.5154 6.83619 12.3123 6.75434 12.0707 6.75434C11.829 6.75434 11.6274 6.83619 11.4658 6.99987C11.3042 7.16354 11.2234 7.36557 11.2234 7.60597V12.3293C11.2234 12.5697 11.3057 12.7718 11.4702 12.9354C11.6347 13.0991 11.8378 13.181 12.0794 13.181ZM12.0075 22.1495C10.6041 22.1495 9.28714 21.8838 8.05649 21.3525C6.82584 20.8212 5.74951 20.0945 4.82751 19.1725C3.90551 18.2505 3.17885 17.1738 2.64754 15.9425C2.11624 14.7112 1.85059 13.3931 1.85059 11.9883C1.85059 10.5835 2.11624 9.2652 2.64754 8.03332C3.17885 6.80142 3.90519 5.72853 4.82656 4.81464C5.74794 3.90078 6.82445 3.17729 8.05609 2.64419C9.28774 2.11109 10.6061 1.84454 12.0112 1.84454C13.4163 1.84454 14.7351 2.11094 15.9674 2.64374C17.1998 3.17653 18.2728 3.89959 19.1863 4.81294C20.0999 5.72629 20.8232 6.80032 21.3561 8.03502C21.889 9.26974 22.1555 10.5892 22.1555 11.9933C22.1555 13.3973 21.8889 14.7145 21.3558 15.9451C20.8227 17.1757 20.0993 18.2502 19.1854 19.1686C18.2715 20.0871 17.1976 20.8135 15.9636 21.3479C14.7296 21.8823 13.4109 22.1495 12.0075 22.1495Z"
        fill={fill}
      />
    </svg>
  );
};
