import {
  type PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import type { Location, NavigateFunction } from "react-router-dom";

import { getVerificationInfo } from "features/Verification/hooks/useGetVerificationInfo";
import type { AppDispatch, RootState } from "store";
import { createPayload, saveChanges } from "./helpers";
import type {
  CreateBusinessOwnerPayload,
  UpdateDataPayload,
  UpdateErrorMessagePayload,
  VerificationAcquisitionSource,
  VerificationData,
  VerificationState,
} from "./typeDefs";

export const fetchAndPrefillVerificationInfo = createAsyncThunk(
  "verification/fetchAndPrefillVerificationInfo",
  async (_, { dispatch, getState }) => {
    try {
      const verificationInfo: { data: VerificationData } =
        await getVerificationInfo();

      dispatch(
        updateData({
          data: {
            ...verificationInfo.data,
          },
        }),
      );
    } catch (error) {
      console.error("verification", error);
    }
  },
);

export const fetchAndGetPercentComplete = createAsyncThunk(
  "verification/fetchAndGetPercentComplete",
  async (_, { dispatch, getState }) => {
    try {
      const progress: { data: VerificationData } = await getVerificationInfo();

      dispatch(
        updateData({
          data: {
            percentComplete: {
              ...progress.data.percentComplete,
            },
          },
        }),
      );
    } catch (error) {
      console.error("verification", error);
    }
  },
);

interface AutoSaveParams {
  navigate: NavigateFunction;
  dispatch: AppDispatch;
  location: Location<any>;
}

export const autoSave = createAsyncThunk(
  "verification/autoSave",
  async (params: AutoSaveParams, { getState }) => {
    const { dispatch, location, navigate } = params;
    const state: RootState = getState() as RootState;
    const { signedUpFor, personalDetails } = state.verification.data;
    const payload = createPayload(location, state.verification.data);

    await saveChanges(
      signedUpFor,
      personalDetails.complianceType,
      payload,
      dispatch,
      location,
      navigate,
      false,
    );
  },
);

export const initialState: VerificationState = {
  data: {
    personalDetails: {
      acquisitionSource: [],
      complianceType: null,
      email: "",
      newEmail: "",
      emailVerified: false,
      firstName: "",
      lastName: "",
      otp: null,
      phoneNumber: "",
      referral: "",
      onboardingRole: null,
    },
    businessInformation: {
      businessDetails: {
        name: "",
        planToUse: [],
        websiteUrl: "",
        industryId: 0,
        whatTheySell: "",
        howTheyAcquire: [],
        notes: "",
      },
      revenueInformation: {
        howTheyAcceptPayments: "",
        annualEstimatedGmv: null,
        expectedShareOfWallet: null,
        percentUaeCustomers: null,
        averageTransactionAmount: null,
      },
      spendInformation: {
        planToUseCorporateCards: "",
        expectNumberOfCards: null,
        expectSpendOnCardsMonthly: null,
        expectAtmCashWithdrawalMonthly: null,
        howTheyPayExpenses: [],
      },
    },
    businessDocuments: {
      tradeLicense: [],
      memorandum: [],
      skipMemorandum: null,
      proofOfAddress: [],
      otherDocuments: [],
    },
    bankInformation: {
      iban: "",
      accountHolderName: "",
      bankAccountProof: [],
    },
    businessOwners: [],
    percentComplete: {
      personalDetails: {
        value: 0,
      },
      businessInformation: {
        value: 0,
        isCompleteBusinessDetail: false,
        isCompleteRevenueInformation: false,
        isCompleteSpendInformation: false,
      },
      businessDocuments: {
        value: 0,
        isCompleteTradeLicense: false,
        isCompleteMemorandumOfAssociation: false,
        isCompleteProofOfAddress: false,
        isCompleteOthers: false,
      },
      bankInformation: {
        value: 0,
      },
      businessOwners: {
        value: 0,
      },
    },
    signedUpFor: null,
    triggeredGtm: null,
  },
  createBusinessOwner: {
    firstName: "",
    lastName: "",
    countryOfBirth: "",
    address: "",
    city: "",
    country: "",
    frontImage: null,
    backImage: null,
  },
  autoSaving: null,
  businessOwnerId: null,
  errorMessage: {
    newEmail: null,
    otp: null,
    phoneNumber: null,
  },
  fileToRemove: null,
  loading: false,
};

export const verificationSlice = createSlice({
  name: "verification",
  initialState,
  reducers: {
    updateData(state, action: PayloadAction<UpdateDataPayload>) {
      state.data = {
        ...state.data,
        ...action.payload.data,
      };
    },
    updateAcquisitionSource(
      state,
      action: PayloadAction<VerificationAcquisitionSource[]>,
    ) {
      state.data.personalDetails.acquisitionSource = action.payload;
    },
    updateFileToRemove(state, action: PayloadAction<any>) {
      state.fileToRemove = action.payload;
    },
    updateCreateBusinessOwner(
      state,
      action: PayloadAction<CreateBusinessOwnerPayload>,
    ) {
      state.createBusinessOwner = {
        ...state.createBusinessOwner,
        ...action.payload.createBusinessOwner,
      };
    },
    updateBusinessOwnerId(state, action: PayloadAction<any>) {
      state.businessOwnerId = action.payload;
    },
    updateErrorMessage(
      state,
      action: PayloadAction<UpdateErrorMessagePayload>,
    ) {
      state.errorMessage = {
        ...state.errorMessage,
        ...action.payload.errorMessage,
      };
    },
    resetCreateBusinessOwner(state) {
      state.businessOwnerId = initialState.businessOwnerId;
      state.createBusinessOwner = { ...initialState.createBusinessOwner };
    },
    resetAutoSave(state) {
      if (state.autoSaving !== initialState.autoSaving) {
        state.autoSaving = initialState.autoSaving;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAndPrefillVerificationInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAndPrefillVerificationInfo.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(fetchAndPrefillVerificationInfo.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchAndGetPercentComplete.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAndGetPercentComplete.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(fetchAndGetPercentComplete.rejected, (state) => {
        state.loading = false;
      })
      .addCase(autoSave.pending, (state) => {
        state.autoSaving = true;
      })
      .addCase(autoSave.fulfilled, (state) => {
        state.autoSaving = false;
      })
      .addCase(autoSave.rejected, (state) => {
        state.autoSaving = false;
      });
  },
});

export const {
  resetAutoSave,
  resetCreateBusinessOwner,
  updateAcquisitionSource,
  updateBusinessOwnerId,
  updateCreateBusinessOwner,
  updateData,
  updateErrorMessage,
  updateFileToRemove,
} = verificationSlice.actions;

export default verificationSlice.reducer;
