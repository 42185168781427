import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export const Logout = ({ size, fill = colorsV2.text[1], ...rest }: IIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=logout, Size=24">
        <path
          id="Vector"
          d="M4.55384 21.1495C4.09385 21.1495 3.69498 20.9806 3.35721 20.6428C3.01946 20.3051 2.85059 19.9062 2.85059 19.4462V4.5538C2.85059 4.09218 3.01946 3.69191 3.35721 3.35298C3.69498 3.01403 4.09385 2.84455 4.55384 2.84455H11.1593C11.3997 2.84455 11.6017 2.92798 11.7654 3.09485C11.9291 3.26172 12.0109 3.46579 12.0109 3.70708C12.0109 3.94838 11.9291 4.14982 11.7654 4.31142C11.6017 4.47301 11.3997 4.5538 11.1593 4.5538H4.55384V19.4462H11.1593C11.3997 19.4462 11.6017 19.5285 11.7654 19.693C11.9291 19.8575 12.0109 20.0606 12.0109 20.3022C12.0109 20.5439 11.9291 20.7455 11.7654 20.9071C11.6017 21.0687 11.3997 21.1495 11.1593 21.1495H4.55384ZM17.8919 12.8516H9.81579C9.5754 12.8516 9.37337 12.7694 9.20969 12.6048C9.046 12.4403 8.96416 12.2373 8.96416 11.9956C8.96416 11.754 9.046 11.5524 9.20969 11.3907C9.37337 11.2292 9.5754 11.1484 9.81579 11.1484H17.8419L15.9185 9.225C15.7486 9.05423 15.6678 8.85412 15.6761 8.62465C15.6844 8.39517 15.7736 8.19646 15.9435 8.02852C16.1134 7.85661 16.3158 7.77164 16.5507 7.77362C16.7855 7.77562 16.9893 7.86159 17.162 8.03152L20.5587 11.4283C20.7247 11.5996 20.8076 11.7989 20.8076 12.0261C20.8076 12.2533 20.7247 12.4518 20.5587 12.6217L17.187 15.9935C17.0204 16.1634 16.8223 16.2452 16.5928 16.2389C16.3632 16.2325 16.1602 16.1444 15.9837 15.9745C15.8203 15.8045 15.7375 15.6017 15.7354 15.366C15.7332 15.1302 15.8193 14.9269 15.9935 14.756L17.8919 12.8516Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
