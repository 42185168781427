import { cn } from "utils/classNames";

import { colorsV2 } from "constants/colors";
import type { TCommonProps, TIconOnlyProps } from "./typeDefs";

export type TTagProps = TCommonProps & TIconOnlyProps;

export const Tag = ({
  color,
  Icon,
  iconOnly,
  iconPosition = "left",
  label,
  simplified,
  size = "lg",
  iconProps,
}: TTagProps) => {
  return (
    <div
      className={cn("flex w-fit-content items-center rounded-full", {
        // ICON CONDITIONAL STYLING
        "px-8": !Icon,
        "": iconOnly && Icon,

        // SIZE CONDITIONAL STYLING
        "body-small h-18": size === "sm" && !iconOnly,
        "body-small h-24": size === "md" && !iconOnly,
        "body-medium h-32": size === "lg" && !iconOnly,
        "body-medium h-40": size === "xl" && !iconOnly,

        // SIZE AND ICON POSITIONG STYLING
        "pr-8": !iconOnly && Icon && iconPosition === "left" && size === "md",
        "pr-12":
          !iconOnly &&
          Icon &&
          iconPosition === "left" &&
          (size === "lg" || size === "xl"),

        // COLOR CONDITIONAL STYLING
        "bg-surface-4": color === "gray" && !simplified,
        "bg-accent-blue-02": color === "blue" && !simplified,
        "bg-accent-yellow-03": color === "yellow" && !simplified,
        "bg-accent-green-02": color === "green" && !simplified,
        "bg-accent-red-02": color === "red" && !simplified,
        "bg-accent-pink-02": color === "pink" && !simplified,
        "bg-wl-black-500 text-surface-1": color === "black" && !simplified,
      })}
    >
      {Icon && (
        <div
          className={cn("", {
            "p-8": size === "xl",
            "p-6": size !== "xl" && size !== "sm",
            "p-2": size === "sm",
          })}
        >
          <Icon
            size={
              size === "sm"
                ? "12"
                : size === "md"
                  ? "12"
                  : size === "lg"
                    ? "18"
                    : size === "xl" && "24"
            }
            fill={
              color === "gray"
                ? colorsV2.text[2]
                : color === "blue"
                  ? colorsV2.accent.blue[1]
                  : color === "yellow"
                    ? colorsV2.accent.yellow[1]
                    : color === "green"
                      ? colorsV2.accent.green[1]
                      : color === "red"
                        ? colorsV2.accent.red[1]
                        : color === "pink"
                          ? colorsV2.accent.pink[1]
                          : color === "black" && colorsV2.surface[1]
            }
            {...iconProps}
          />
        </div>
      )}
      {!iconOnly && label}
    </div>
  );
};
