import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export const Toll = ({ size, fill = colorsV2.text[1], ...rest }: IIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M14.9895 20.1495C12.7174 20.1495 10.7899 19.3595 9.20705 17.7795C7.62405 16.1997 6.83255 14.2737 6.83255 12.0015C6.83255 9.72933 7.62405 7.80175 9.20705 6.21875C10.7899 4.63591 12.7174 3.8445 14.9895 3.8445C17.2617 3.8445 19.1878 4.63591 20.7678 6.21875C22.3476 7.80175 23.1375 9.72933 23.1375 12.0015C23.1375 14.2737 22.3476 16.1997 20.7678 17.7795C19.1878 19.3595 17.2617 20.1495 14.9895 20.1495ZM6.1473 19.525C4.56113 19.0757 3.2838 18.1465 2.3153 16.7375C1.3468 15.3285 0.862549 13.749 0.862549 11.999C0.862549 10.249 1.3468 8.66983 2.3153 7.2615C3.2838 5.85316 4.55913 4.92633 6.1413 4.481C6.41047 4.3705 6.6513 4.39308 6.8638 4.54875C7.0763 4.70425 7.18255 4.92491 7.18255 5.21075C7.18255 5.36875 7.12838 5.52241 7.02005 5.67175C6.91172 5.82108 6.78255 5.92666 6.63255 5.9885C5.39238 6.38783 4.40455 7.147 3.66905 8.266C2.93355 9.38483 2.5658 10.6295 2.5658 12C2.5658 13.3705 2.93355 14.6152 3.66905 15.734C4.40455 16.853 5.39238 17.6102 6.63255 18.0055C6.78255 18.0713 6.91172 18.1747 7.02005 18.3157C7.12838 18.4567 7.18255 18.6146 7.18255 18.7892C7.18255 19.0787 7.0783 19.3024 6.8698 19.4602C6.6613 19.6179 6.42047 19.6395 6.1473 19.525ZM14.987 18.4462C16.7552 18.4462 18.2718 17.8141 19.5368 16.5497C20.8018 15.2854 21.4343 13.7692 21.4343 12.001C21.4343 10.233 20.8021 8.71641 19.5378 7.45125C18.2735 6.18625 16.7572 5.55375 14.989 5.55375C13.221 5.55375 11.7045 6.18591 10.4395 7.45025C9.17438 8.71458 8.5418 10.2308 8.5418 11.999C8.5418 13.767 9.17397 15.2836 10.4383 16.5487C11.7026 17.8137 13.2189 18.4462 14.987 18.4462Z"
        fill={fill}
      />
    </svg>
  );
};
