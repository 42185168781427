import LoaderBusiness from "components/Loader/LoaderBusiness";
import { TransactionsTable } from "./TransactionTable/TransactionTable";

import { useAppSelector } from "store";
import { useGetWalletTransactions } from "./hook/useGetWalletTransactions";

import { SideSheetProvider } from "design_system/Sidesheet/SideSheetContext";
import type { WalletActivityProps } from "../../typeDefs";

export const WalletActivity = ({ wallet }: WalletActivityProps) => {
  const { wallets } = useAppSelector((store) => store.table);

  const { getWalletTransactions } = useGetWalletTransactions({
    wallet: wallet.selectedWallet,
    configs: {
      page: wallets.page,
    },
  });

  if (getWalletTransactions.isLoading) {
    return <LoaderBusiness />;
  }
  return (
    <>
      <SideSheetProvider>
        <TransactionsTable transactions={getWalletTransactions.data} />
      </SideSheetProvider>
    </>
  );
};
