export enum PERMISSION {
  ACCOUNTING = "accounting",
  CARD_FUNDS = "card_funds",
  CARDS = "cards",
  DEVELOPER = "developer",
  DISPUTES = "disputes",
  EXPENSES = "expenses", // admin & finance operator
  INVOICES = "invoices",
  MY_CARDS = "my_cards",
  MY_EXPENSES = "my_expenses", // card holder expenses
  OVERVIEW = "overview",
  PAYMENT_LINKS = "payment_links",
  PAYMENTS = "payments",
  PAYOUTS = "disbursements",
  POS = "in_store_payments",
  SETTINGS = "settings",
  SETTLEMENTS = "settlements",
  SPEND_LIMITS = "spend_limits",
  SUBSCRIPTIONS = "recurring_payments",
  WALLET = "wallet",
}

export type TPermissions = {
  hasReadAccess: boolean;
  hasWriteAccess: boolean;
};

export type GeneratePermissionObject = (
  basePermission: PERMISSION | "init",
) => TPermissions;
