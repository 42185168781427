import type IRailsContext from "interfaces/IRailsContext";
import { PERMISSION, type TPermissions } from "types/Permissions";
import { useGetRole } from "utils/rbacUtils";

export const checkPermission = (railsContext: IRailsContext) => {
  const { hasPermission } = useGetRole();
  const permissions = railsContext.sessionContext.currentUser?.permissions!;

  const generatePermissionObject = (
    basePermission: PERMISSION,
  ): TPermissions => {
    return {
      hasReadAccess: hasPermission(`${basePermission}:read`, permissions),
      hasWriteAccess: hasPermission(`${basePermission}:write`, permissions),
    };
  };

  const permissionsMap = {
    cardFunds: generatePermissionObject(PERMISSION.CARD_FUNDS),
    cards: generatePermissionObject(PERMISSION.CARDS),
    developer: generatePermissionObject(PERMISSION.DEVELOPER),
    disputes: generatePermissionObject(PERMISSION.DISPUTES),
    expenses: generatePermissionObject(PERMISSION.EXPENSES),
    invoices: generatePermissionObject(PERMISSION.INVOICES),
    myCards: generatePermissionObject(PERMISSION.MY_CARDS),
    myExpenses: generatePermissionObject(PERMISSION.MY_EXPENSES),
    overview: generatePermissionObject(PERMISSION.OVERVIEW),
    paymentLinks: generatePermissionObject(PERMISSION.PAYMENT_LINKS),
    payments: generatePermissionObject(PERMISSION.PAYMENTS),
    payouts: generatePermissionObject(PERMISSION.PAYOUTS),
    pos: generatePermissionObject(PERMISSION.POS),
    settings: generatePermissionObject(PERMISSION.SETTINGS),
    settlements: generatePermissionObject(PERMISSION.SETTLEMENTS),
    spendLimitsPermissions: generatePermissionObject(PERMISSION.SPEND_LIMITS),
    subscriptions: generatePermissionObject(PERMISSION.SUBSCRIPTIONS),
    wallet: generatePermissionObject(PERMISSION.WALLET),
  };

  // +Create button in Topbar
  const createButtonPermissions = {
    hasNoAccess:
      !permissionsMap.paymentLinks.hasWriteAccess &&
      !permissionsMap.subscriptions.hasWriteAccess &&
      !permissionsMap.invoices.hasWriteAccess,
  };

  // Payments accordion
  const paymentsConditionsArray = [
    permissionsMap.paymentLinks.hasWriteAccess,
    permissionsMap.subscriptions.hasWriteAccess,
    permissionsMap.invoices.hasWriteAccess,
    permissionsMap.pos.hasWriteAccess,
  ];
  const paymentsAccordionPermissions = {
    hasNoAccess:
      !permissionsMap.paymentLinks.hasWriteAccess &&
      !permissionsMap.subscriptions.hasWriteAccess &&
      !permissionsMap.invoices.hasWriteAccess &&
      !permissionsMap.pos.hasWriteAccess,
    count: paymentsConditionsArray.reduce(
      (count, condition) => count + (condition ? 1 : 0),
      0,
    ),
  };

  // Spend management accordion
  const spendManagementConditionsArray = [
    permissionsMap.cards.hasReadAccess,
    permissionsMap.payouts.hasReadAccess,
  ];
  const spendManagementAccordionPermissions = {
    hasNoAccess:
      !permissionsMap.myCards.hasReadAccess &&
      !permissionsMap.cards.hasReadAccess &&
      !permissionsMap.cardFunds.hasReadAccess &&
      !permissionsMap.payouts.hasReadAccess &&
      !permissionsMap.expenses.hasReadAccess &&
      !permissionsMap.myExpenses.hasReadAccess,
    count: spendManagementConditionsArray.reduce(
      (count, condition) => count + (condition ? 1 : 0),
      0,
    ),
  };

  // Transactions accordion
  const transactionsConditionsArray = [
    permissionsMap.payments.hasReadAccess,
    permissionsMap.settlements.hasReadAccess,
  ];
  const transactionsAccordionPermissions = {
    hasNoAccess:
      !permissionsMap.payments.hasReadAccess &&
      !permissionsMap.settlements.hasReadAccess,
    count: transactionsConditionsArray.reduce(
      (count, condition) => count + (condition ? 1 : 0),
      0,
    ),
  };

  return {
    ...permissionsMap,

    createButton: createButtonPermissions,
    paymentsAccordion: paymentsAccordionPermissions,
    spendManagementAccordion: spendManagementAccordionPermissions,
    transactionsAccordion: transactionsAccordionPermissions,
  };
};
