import { useDispatch } from "react-redux";

import { useGetRole } from "utils/rbacUtils";
import { useGetExpenseVisibility } from "./useGetExpenseVisibility";

import { posIdentifiers } from "constants/posIdentifiers";
import type Role from "interfaces/iRole";
import { isAppSession_v2 } from "pages/Payment/Pay/helpers";
import { setPermissions } from "reducers/redux/Permissions/permissionsSlice";
import { PERMISSION, type TPermissions } from "types/Permissions";

interface CheckPermissionsForNavigationProps {
  currentMerchant: CurrentMerchant;
  featureToggles: TMerchantInfo["featureToggles"];
  platform: string;
}

export const useCheckPermissionsV2 = (permissions: Role) => {
  const { hasPermission } = useGetRole();
  const { getExpenseVisibility } = useGetExpenseVisibility();
  const dispatch = useDispatch();

  const generatePermissionObject = (
    basePermission: PERMISSION,
  ): TPermissions => {
    return {
      hasReadAccess: hasPermission(`${basePermission}:read`, permissions),
      hasWriteAccess: hasPermission(`${basePermission}:write`, permissions),
    };
  };

  const permissionsMap = {
    accounting: generatePermissionObject(PERMISSION.ACCOUNTING),
    cardFunds: generatePermissionObject(PERMISSION.CARD_FUNDS),
    cards: generatePermissionObject(PERMISSION.CARDS),
    developer: generatePermissionObject(PERMISSION.DEVELOPER),
    disputes: generatePermissionObject(PERMISSION.DISPUTES),
    expenses: generatePermissionObject(PERMISSION.EXPENSES),
    invoices: generatePermissionObject(PERMISSION.INVOICES),
    myCards: generatePermissionObject(PERMISSION.MY_CARDS),
    myExpenses: generatePermissionObject(PERMISSION.MY_EXPENSES),
    overview: generatePermissionObject(PERMISSION.OVERVIEW),
    paymentLinks: generatePermissionObject(PERMISSION.PAYMENT_LINKS),
    payments: generatePermissionObject(PERMISSION.PAYMENTS),
    payouts: generatePermissionObject(PERMISSION.PAYOUTS),
    pos: generatePermissionObject(PERMISSION.POS),
    settings: generatePermissionObject(PERMISSION.SETTINGS),
    settlements: generatePermissionObject(PERMISSION.SETTLEMENTS),
    spendLimitsPermissions: generatePermissionObject(PERMISSION.SPEND_LIMITS),
    subscriptions: generatePermissionObject(PERMISSION.SUBSCRIPTIONS),
    wallet: generatePermissionObject(PERMISSION.WALLET),
  };

  const {
    accounting,
    cardFunds,
    cards,
    expenses,
    invoices,
    myCards,
    myExpenses,
    overview,
    paymentLinks,
    payments,
    payouts,
    pos,
    settings,
    settlements,
    subscriptions,
    wallet,
  } = permissionsMap;

  // +Create button in Topbar
  const createButtonPermissions = {
    hasNoAccess:
      !paymentLinks.hasWriteAccess &&
      !subscriptions.hasWriteAccess &&
      !invoices.hasWriteAccess,
  };

  // Checks permissions for navigation
  const checkPermissionsForNavigation = ({
    currentMerchant,
    featureToggles,
    platform,
  }: CheckPermissionsForNavigationProps) => {
    const { complianceType, identifier, signedUpFor } = currentMerchant;
    const signedUpForCardsOnly = signedUpFor === "cards_only";

    const hasFinancesTabAccess =
      wallet.hasReadAccess ||
      settlements.hasReadAccess ||
      payouts.hasReadAccess;
    const hasPaymentsTabAccess =
      (payments.hasReadAccess ||
        paymentLinks.hasReadAccess ||
        subscriptions.hasReadAccess ||
        invoices.hasReadAccess ||
        pos.hasReadAccess) &&
      !signedUpForCardsOnly;
    const hasCardsTabAccess =
      featureToggles.cardsFeatureEnabled &&
      !isAppSession_v2(platform) &&
      (myCards.hasReadAccess || cards.hasReadAccess || cardFunds.hasReadAccess);

    if (hasFinancesTabAccess) {
      dispatch(setPermissions("finances-tab"));
    }
    if (wallet.hasReadAccess) {
      dispatch(setPermissions("accounts"));
    }
    if (settlements.hasReadAccess && !signedUpForCardsOnly) {
      dispatch(setPermissions("settlements"));
    }
    if (payouts.hasReadAccess && !signedUpForCardsOnly) {
      dispatch(setPermissions("payouts"));
    }
    if (hasPaymentsTabAccess) {
      dispatch(setPermissions("payments-tab"));
    }
    if (payments.hasReadAccess) {
      dispatch(setPermissions("payments"));
    }
    if (paymentLinks.hasReadAccess) {
      dispatch(setPermissions("payment-links"));
    }
    if (subscriptions.hasReadAccess) {
      dispatch(setPermissions("subscriptions"));
    }
    if (invoices.hasReadAccess) {
      dispatch(setPermissions("invoices"));
    }
    if (pos.hasWriteAccess && posIdentifiers[identifier!] != null) {
      dispatch(setPermissions("pos"));
    }
    if (hasCardsTabAccess) {
      dispatch(setPermissions("cards-tab"));

      // Note: Transactions can be seen by anyone so there's no specific permission tied to this.
      // As long as they have access to the cards-tab, they can see transactions by default
      dispatch(setPermissions("transactions"));
    }
    if (cards.hasReadAccess || myCards.hasReadAccess) {
      dispatch(setPermissions("cards"));
    }
    if (cardFunds.hasReadAccess) {
      dispatch(setPermissions("balance"));
    }
    if (cards.hasReadAccess) {
      dispatch(setPermissions("cardholders"));
    }
    if (
      getExpenseVisibility({
        cards,
        complianceType,
        expenses,
        expensesFeatureEnabled: !!featureToggles.expensesFeatureEnabled,
        location: "sidebar",
        myExpenses,
        platform,
      })
    ) {
      dispatch(setPermissions("expenses-tab"));
    }
    if (accounting.hasReadAccess) {
      dispatch(setPermissions("accounting-tab"));
    }
    if (overview.hasReadAccess) {
      dispatch(setPermissions("insights-tab"));
    }
    if (settings.hasReadAccess) {
      dispatch(setPermissions("settings-tab"));
    }
  };

  return {
    ...permissionsMap,
    createButton: createButtonPermissions,
    checkPermissionsForNavigation,
  };
};
