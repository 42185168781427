import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export const OpenInNew = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: IIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=open_in_new, Size=24">
        <path
          id="Vector"
          d="M3.41538 15.8621C3.07039 15.8621 2.77123 15.7354 2.51791 15.4821C2.2646 15.2288 2.13794 14.9296 2.13794 14.5847V3.41536C2.13794 3.06915 2.2646 2.76894 2.51791 2.51474C2.77123 2.26053 3.07039 2.13342 3.41538 2.13342H7.96756C8.14786 2.13342 8.29938 2.196 8.42213 2.32115C8.5449 2.4463 8.60628 2.59935 8.60628 2.78032C8.60628 2.96129 8.5449 3.11238 8.42213 3.23358C8.29938 3.35477 8.14786 3.41536 7.96756 3.41536H3.41538V14.5847H14.5847V10.0325C14.5847 9.85218 14.6464 9.70065 14.7698 9.57791C14.8931 9.45514 15.0453 9.39376 15.2263 9.39376C15.4073 9.39376 15.5592 9.45514 15.6822 9.57791C15.8051 9.70065 15.8666 9.85218 15.8666 10.0325V14.5847C15.8666 14.9296 15.7395 15.2288 15.4853 15.4821C15.2311 15.7354 14.9309 15.8621 14.5847 15.8621H3.41538ZM14.5847 4.32474L7.71321 11.2007C7.58344 11.3281 7.43442 11.388 7.26616 11.3802C7.09788 11.3725 6.95003 11.3049 6.82259 11.1774C6.69514 11.05 6.63141 10.9016 6.63141 10.7321C6.63141 10.5627 6.69514 10.4143 6.82259 10.2868L13.6941 3.41536H10.37C10.1897 3.41536 10.0382 3.35367 9.91542 3.23028C9.79266 3.10689 9.73128 2.95471 9.73128 2.77374C9.73128 2.59277 9.79266 2.44082 9.91542 2.31787C10.0382 2.1949 10.1897 2.13342 10.37 2.13342H15.8666V7.63004C15.8666 7.81034 15.804 7.96187 15.6789 8.08462C15.5537 8.20738 15.4007 8.26876 15.2197 8.26876C15.0387 8.26876 14.8877 8.20738 14.7665 8.08462C14.6453 7.96187 14.5847 7.81034 14.5847 7.63004V4.32474Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
