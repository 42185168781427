import { useMutation } from "@tanstack/react-query";
import axios, { type AxiosError, type AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "store";

import { queryParams } from "constants/queryParams";
import routes from "constants/routes";
import { SentryLoggingService } from "init/SentryLoggingService";
import { SignUpSteps } from "../../shared/constant";

export const useCreatePassword = () => {
  const { data } = useAppSelector((state) => state.signUp);
  const navigate = useNavigate();

  const createPassword = useMutation({
    mutationKey: ["createPassword"],
    mutationFn: async (password: string) => {
      await axios
        .post(routes.ONBOARDING.ACCOUNTS.SIGN_UP_VALIDATION(), {
          ...queryParams,
          step: SignUpSteps.CREATE_PASSWORD,
          password,
          email: data.email,
        })
        .then((res: AxiosResponse) => {
          navigate(routes.ONBOARDING.TELL_US_MORE_BUSINESS());
        });
    },
    onError: (error: AxiosError, payload) => {
      SentryLoggingService.captureException(error.message, {
        feature: "[SignUp][CreatePassword]",
        function: "createPassword",
        file: "useCreatePassword",
        data: payload ? JSON.stringify(payload) : null,
      });
    },
  });
  return {
    createPassword,
  };
};
