import {
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useCallback, useState } from "react";

import { RECORDS_PER_PAGE } from "constants/api";
import { getColumnVisibility } from "../TableUtils";

export const useTableLogic = ({
  columns,
  data,
  hiddenColumns,
  name,
  rowProps,
}) => {
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);

  const { selectable, selectedRows, setSelectedRows } = rowProps;

  const table = useReactTable({
    data,
    columns,
    state: {
      pagination: {
        pageIndex: 0,
        pageSize: RECORDS_PER_PAGE,
      },
      columnVisibility: getColumnVisibility(hiddenColumns),
    },
    enableRowSelection: selectable,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const tableRows = table.getRowModel().rows || [];
  const isEmptyTable = tableRows.length === 0;
  const topLevelHeadings = table.getHeaderGroups()[0].headers || [];
  const allRowsSelected =
    data.length > 0 && rowProps.selectedRows.length === data.length;

  if (!table?.getHeaderGroups()?.length) {
    console.error(`[Table: ${name}] getHeaderGroups is empty`);
  }

  const toggleRowSelected = useCallback(
    (rowData) => {
      const newSelectedRows = new Set(rowProps.selectedRows);

      const existingRow = [...newSelectedRows].find(
        (row: any) => row.id === rowData.id,
      );

      if (existingRow) {
        newSelectedRows.delete(existingRow);
      } else {
        newSelectedRows.add(rowData);
      }

      setSelectedRows?.(Array.from(newSelectedRows));
    },
    [rowProps.selectedRows.length],
  );

  const selectAllRows = useCallback(() => {
    if (allRowsSelected) {
      rowProps.setSelectedRows([]);
    } else {
      setSelectedRows(
        tableRows.map((row: any) => {
          return {
            rowId: row.id,
            ...row.original,
          };
        }),
      );
    }
  }, [data.length, selectedRows.size, tableRows]);

  return {
    selectable,
    hoveredRow,
    allRowsSelected,
    isEmptyTable,
    table,
    tableRows,
    topLevelHeadings,
    setHoveredRow,
    toggleRowSelected,
    selectAllRows,
  };
};

export default useTableLogic;
