import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import TextField, { type TextFieldProps } from "@mui/material/TextField";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import isUndefined from "lodash/isUndefined";
import { forwardRef, useState } from "react";

import { colorsV2 } from "constants/colors";
import { ErrorOutline } from "design_system/Icons";
import { customTheme } from "../../shared/theme";

export type ITextInputProps = TextFieldProps & {
  formHelperText?: string;
  highlightColor?:
    | "error"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning";
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
};

export const TextInput = forwardRef((props: ITextInputProps, ref) => {
  const outerTheme = useTheme();
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    if (props.onBlur) {
      props.onBlur(event);
    }
  };

  const updatedProps: ITextInputProps = {
    ...props,
    color: !props.highlightColor
      ? props.color
      : isFocused
        ? props.color
        : props.highlightColor,
    onFocus: handleFocus,
    onBlur: handleBlur,
  };

  const maxLengthProperty = updatedProps.inputProps?.maxLength;
  const hasMaxLengthProperty = !isUndefined(maxLengthProperty);
  const inputValueLength = (updatedProps.value as string)?.length;

  return (
    <div className="flex w-full flex-col">
      <ThemeProvider theme={customTheme(outerTheme)}>
        <TextField
          inputRef={ref}
          variant="filled"
          error={updatedProps.error}
          InputProps={{
            endAdornment: updatedProps.error ? (
              <InputAdornment position="end">
                <ErrorOutline size="18" fill={colorsV2.error[1]} />
              </InputAdornment>
            ) : undefined,
          }}
          {...updatedProps}
          helperText={
            updatedProps.helperText ||
            (hasMaxLengthProperty && inputValueLength) ? (
              <span className="flex justify-between">
                <span>{updatedProps.helperText}</span>
                {hasMaxLengthProperty && inputValueLength >= 1 && (
                  <span className="text-text-color-03">{`${inputValueLength} / ${maxLengthProperty}`}</span>
                )}
              </span>
            ) : null
          }
        />
        {!updatedProps.helperText && props.formHelperText && (
          <FormHelperText className="BlazeHelperText body-small px-14 text-text-color-03">
            {props.formHelperText}
          </FormHelperText>
        )}
      </ThemeProvider>
    </div>
  );
});
