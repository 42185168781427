import { useDispatch } from "react-redux";

import { TableCell } from "components/Table/TableCell";

import { setPagination } from "reducers/redux/TableReducer/tableReducer";

type TColumnCell = {
  row: {
    original: any;
  };
};
export const useExpenseAccountsTable = () => {
  const dispatch = useDispatch();

  const COLUMNS = [
    {
      header: "Name",
      accessorKey: "name",
      cell: (props: TColumnCell) => (
        <TableCell mainContent={props.row.original.name} />
      ),
    },
    {
      header: "Currency",
      accessorKey: "currency",
      cell: (props: TColumnCell) => (
        <TableCell mainContent={props.row.original.currency} />
      ),
    },
  ];

  const handleSetPage = (newPageNumber: number) => {
    dispatch(
      setPagination({ tableName: "expenseAccounts", page: newPageNumber }),
    );
  };

  return {
    COLUMNS,
    handleSetPage,
  };
};
