import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export const Diamond = ({ size, fill = colorsV2.text[1], ...rest }: IIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9.93639 9.39157L16.0243 9.35857L13.4116 3.9319L12.4901 3.9369L9.93639 9.39157ZM12.4203 21.3608L12.3621 10.6284L3.41651 10.6769L12.4203 21.3608ZM13.6703 21.354L22.5577 10.5732L13.6121 10.6217L13.6703 21.354ZM17.4423 9.35088L22.733 9.3222L20.4825 4.82608C20.3317 4.54106 20.118 4.31539 19.8416 4.14905C19.565 3.98255 19.2632 3.90018 18.936 3.90195L14.8176 3.92428L17.4423 9.35088ZM3.22774 9.42794L8.52441 9.39923L11.0841 3.94452L6.96569 3.96685C6.63852 3.96862 6.33757 4.05426 6.06282 4.22375C5.78823 4.39307 5.57905 4.62104 5.43526 4.90766L3.22774 9.42794Z"
        fill={fill}
      />
    </svg>
  );
};
