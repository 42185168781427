import { Tag } from "design_system/Tag";
import type { TTagProps } from "design_system/Tag/Tag";
import type { IIcon } from "types/Icon";

// Types for the props
type SearchRecordProps = {
  children: React.ReactNode;
  variant?: "noAvatar";
  onClick?: () => void;
};

type TagProps = {
  tagProps: TTagProps;
};

type IconProps = {
  Icon: any;
  iconProps: IIcon;
};

type ColumnProps = {
  label: React.ReactNode;
  supportingText?: string | string[];
  onClick?: () => void;
};

/**
 * Main search record component
 * @param props - Props containing children and optional onClick handler
 * @returns JSX Element
 */
export const SearchRecord: React.FC<SearchRecordProps> & {
  Tag: React.FC<TagProps>;
  Icon: React.FC<IconProps>;
  Column1: React.FC<ColumnProps>;
  Column2: React.FC<ColumnProps>;
} = ({ children, onClick }: SearchRecordProps) => {
  return (
    <div
      className="grid w-full cursor-pointer grid-cols-search-record gap-x-12 px-16 py-8 hover:bg-surface-2"
      onClick={onClick}
    >
      {children}
    </div>
  );
};

/**
 * Tag component for the table record
 * @param props - Props containing tagProps
 * @returns JSX Element
 */
SearchRecord.Tag = ({ tagProps }: TagProps) => (
  <div className="mt-4 flex items-start">
    <Tag {...tagProps} />
  </div>
);

/**
 * Icon component for the table record
 * @param props - Props containing IconProps
 * @returns JSX Element
 */
SearchRecord.Icon = ({ Icon, iconProps }: IconProps) => (
  <div className="mt-4 flex items-start">
    <Icon {...iconProps} />
  </div>
);

/**
 * Column component for the table record
 * @param props - Props containing label, supportingText, optional onClick, isFixedWidth, and isLargeLabel
 * @returns JSX Element
 */
SearchRecord.Column1 = ({ label, supportingText, onClick }: ColumnProps) => {
  const columnClasses = "flex flex-col";
  const labelClass = "flex-grow label-large";
  const supportingTextClass =
    "flex-grow body-small text-text-color-02 space-y-4";

  return (
    <div className={columnClasses} onClick={onClick}>
      <div className={labelClass}>{label}</div>
      <div className={supportingTextClass}>
        {typeof supportingText === "object"
          ? supportingText.map((text, index) => (
              <div key={`${label}-${index}`}>{text}</div>
            ))
          : supportingText}
      </div>
    </div>
  );
};

SearchRecord.Column2 = ({ label, supportingText, onClick }: ColumnProps) => {
  const columnClasses = "flex flex-col";
  const labelClass = "flex-grow body-small text-text-color-02 text-right";
  const supportingTextClass =
    "flex-grow body-small text-text-color-02 text-right";

  return (
    <div className={columnClasses} onClick={onClick}>
      <div className={labelClass}>{label}</div>
      <div className={supportingTextClass}>
        {typeof supportingText === "object"
          ? supportingText.map((text, index) => (
              <div key={`${label}-${index}`}>{text}</div>
            ))
          : supportingText}
      </div>
    </div>
  );
};
