import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export const Work = ({ size, fill = colorsV2.text[1], ...rest }: IIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="work_FILL0_wght500_GRAD0_opsz48 1">
        <path
          id="Vector"
          d="M3.55384 21.1494C3.09385 21.1494 2.69498 20.9806 2.35721 20.6428C2.01946 20.305 1.85059 19.9062 1.85059 19.4462V7.62554C1.85059 7.1639 2.01946 6.76362 2.35721 6.42469C2.69498 6.08575 3.09385 5.91629 3.55384 5.91629H7.86254V3.41629C7.86254 2.96049 8.03142 2.56266 8.36919 2.22281C8.70694 1.88295 9.1058 1.71301 9.56579 1.71301H14.4343C14.8943 1.71301 15.2931 1.88295 15.6309 2.22281C15.9687 2.56266 16.1375 2.96049 16.1375 3.41629V5.91629H20.4462C20.9079 5.91629 21.3081 6.08575 21.6471 6.42469C21.986 6.76362 22.1555 7.1639 22.1555 7.62554V19.4462C22.1555 19.9062 21.986 20.305 21.6471 20.6428C21.3081 20.9806 20.9079 21.1494 20.4462 21.1494H3.55384ZM3.55384 19.4462H20.4462V7.62554H3.55384V19.4462ZM9.56579 5.91629H14.4343V3.41629H9.56579V5.91629Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
