import { Route, Routes, useLocation } from "react-router-dom";

import { SlideIn } from "components/AnimatedElements/SlideIn";

import { SignUpForMamo } from "./1_SignUpForMamo";
import { VerifyEmail } from "./2_VerifyEmail";
import { StartWithBasics } from "./3_StartWithBasics";
import { CreatePassword } from "./4_CreatePassword";
import { TellUsMoreBusiness } from "./5_TellUsMoreBusiness";
import { PlanToUse } from "./6_PlanToUse";
import { SignUpComplete } from "./7_SignUpComplete";

import { useAppSelector } from "store";

import routes from "constants/routes";

export const SignUpAnimatedRoutes = () => {
  const { stepAnimationDirection } = useAppSelector((state) => state.signUp);
  const location = useLocation();

  return (
    <Routes location={location} key={location.pathname}>
      <Route
        path={routes.ONBOARDING.SIGN_UP()}
        element={<SlideIn element={<SignUpForMamo />} />}
      />
      <Route
        path={routes.ONBOARDING.VERIFY_OTP()}
        element={<SlideIn element={<VerifyEmail />} />}
      />
      <Route
        path={routes.ONBOARDING.START_WITH_BASICS()}
        element={
          <SlideIn
            direction={stepAnimationDirection}
            element={<StartWithBasics />}
          />
        }
      />
      <Route
        path={routes.ONBOARDING.CREATE_PASSWORD()}
        element={
          <SlideIn
            direction={stepAnimationDirection}
            element={<CreatePassword />}
          />
        }
      />
      <Route
        path={routes.ONBOARDING.TELL_US_MORE_BUSINESS()}
        element={
          <SlideIn
            direction={stepAnimationDirection}
            element={<TellUsMoreBusiness />}
          />
        }
      />
      <Route
        path={routes.ONBOARDING.PLAN_TO_USE()}
        element={
          <SlideIn direction={stepAnimationDirection} element={<PlanToUse />} />
        }
      />
      <Route
        path={routes.ONBOARDING.SUCCESS()}
        element={<SlideIn element={<SignUpComplete />} />}
      />
    </Routes>
  );
};
