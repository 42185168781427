import type { IIcon } from "types/Icon";

export const UaeFlag = ({ size }: IIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
    >
      <g clipPath="url(#clip0_128_10093)">
        <mask
          id="mask0_128_10093"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="40"
          height="40"
        >
          <rect width="40" height="40" rx="20" fill="#C4C4C4" />
        </mask>
        <g mask="url(#mask0_128_10093)">
          <path
            d="M39.9997 13.3333H13.333V0H38.333C39.2538 0 39.9997 0.994444 39.9997 2.22222V13.3333Z"
            fill="#169D4D"
          />
          <path
            d="M39.9997 13.3335H13.333V26.6668H39.9997V13.3335Z"
            fill="white"
          />
          <path
            d="M39.9997 37.7779C39.9997 39.0056 39.2538 40.0001 38.333 40.0001H13.333V26.6667H39.9997V37.7779Z"
            fill="#000105"
          />
          <path
            d="M13.3333 40H1.66667C0.745833 40 0 39.0056 0 37.7778V2.22222C0 0.994444 0.745833 0 1.66667 0H13.3333V40Z"
            fill="#CC162C"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_128_10093">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
