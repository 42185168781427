import { RefreshInfoToolbar } from "../shared/RefreshInfoToolbar";
import { VendorsTable } from "./VendorsTable";

type VendorsProps = {
  vendors?: VendorsWithPagination;
};

export const Vendors = ({ vendors }: VendorsProps) => {
  return (
    <section className="flex flex-col gap-24">
      <RefreshInfoToolbar />
      <VendorsTable vendors={vendors} />
    </section>
  );
};
