import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export const Sell = ({ size, fill = colorsV2.text[1], ...rest }: IIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M14.1184 21.7185C13.7785 22.0583 13.3682 22.2283 12.8874 22.2283C12.4066 22.2283 11.9963 22.0583 11.6564 21.7185L2.28141 12.3435C2.09481 12.1569 1.96736 11.96 1.89906 11.7527C1.83077 11.5455 1.79663 11.3279 1.79663 11.1V3.50001C1.79663 3.01087 1.95823 2.60371 2.28143 2.27853C2.6046 1.95335 3.01075 1.79076 3.49988 1.79076H11.0999C11.3278 1.79076 11.5537 1.82591 11.7776 1.89621C12.0015 1.96649 12.2068 2.09493 12.3934 2.28153L21.7184 11.6006C22.0749 11.9571 22.2532 12.3767 22.2532 12.8595C22.2532 13.3423 22.0749 13.762 21.7184 14.1185L14.1184 21.7185ZM12.9559 20.55L20.5559 12.95L11.0999 3.50001H3.49988V11.1L12.9559 20.55ZM6.12488 7.40001C6.47488 7.40001 6.77905 7.27084 7.03738 7.01251C7.29571 6.75417 7.42488 6.45001 7.42488 6.10001C7.42488 5.75001 7.29571 5.44584 7.03738 5.18751C6.77905 4.92917 6.47488 4.80001 6.12488 4.80001C5.77488 4.80001 5.47071 4.92917 5.21238 5.18751C4.95405 5.44584 4.82488 5.75001 4.82488 6.10001C4.82488 6.45001 4.95405 6.75417 5.21238 7.01251C5.47071 7.27084 5.77488 7.40001 6.12488 7.40001Z"
        fill={fill}
      />
    </svg>
  );
};
