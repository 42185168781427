import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { Button } from "design_system/Button";

import routes from "constants/routes";
import {
  initialState,
  updateData,
  updateErrorMessage,
} from "reducers/redux/Access/SignUp/signUpSlice";

import { Card } from "design_system/Card";
import { CheckCircleFill } from "design_system/Icons";

import { colorsV2 } from "constants/colors";

const listItems = [
  "Only takes 15 minutes",
  "Increases trust with your customers",
  "Reduces the risk of disputes and chargebacks",
  "Ensures compliance with financial regulations",
  "Unlocks Mamo products and features",
];

export const SignUpComplete = () => {
  const dispatch = useDispatch();

  const handleGoToVerification = () =>
    (window.location.href = routes.MANAGE.VERIFICATION.PERSONAL_DETAILS());
  const handleGoToHomePage = () =>
    (window.location.href = routes.MANAGE.HOME_PAGE());

  useEffect(() => {
    dispatch(updateData(initialState));
    dispatch(updateErrorMessage({ errorMessage: null }));
  }, []);

  return (
    <div className="flex flex-col gap-32">
      <div className="space-y-8 md:space-y-32">
        <p className="text-center text-40 md:text-56">🙌</p>

        <div className="space-y-12 text-center">
          <h1 className="header-medium md:display-medium">
            Your account is live! <br />
            Next up: account verification
          </h1>
          <p className="body-medium md:body-large">
            Verifying your account helps us create a safe, secure, and reliable
            platform for your business.
          </p>
        </div>
      </div>

      <Card cardClasses="shadow-lg space-y-8">
        <p className="label-large">A verified account:</p>
        <ul className="body-large space-y-4 text-text-color-02">
          {listItems.map((item, index) => (
            <div key={index} className="flex items-start gap-8">
              <div className="flex-shrink-0 py-6">
                <CheckCircleFill size="12" fill={colorsV2.accent.green[1]} />
              </div>
              <li>{item}</li>
            </div>
          ))}
        </ul>
      </Card>

      <div className="flex flex-col gap-16 md:flex-row">
        <Button
          variant="outlined"
          size="lg"
          label="Remind me later"
          classes="w-full"
          onClick={handleGoToHomePage}
        />
        <Button
          variant="filled"
          size="lg"
          label="Start verification"
          classes="w-full"
          onClick={handleGoToVerification}
        />
      </div>
    </div>
  );
};
