import EmptyStateIllustration from "assets/illustrations/generic-card-empty-state.svg";
import EmptyState from "components/EmptyState";
import Status from "components/Status";
import routes from "constants/routes";

interface ISubscriberListProps {
  subscribers: any;
  subscriptionIdentifier: string;
  currentTab: string;
}

const SubscriberList: React.FC<ISubscriberListProps> = ({
  subscribers,
  subscriptionIdentifier,
  currentTab,
}) => {
  const isEmptySubscribers = subscribers.length == 0;

  const handleEmptyState = () => {
    let title, info;
    if (currentTab == "all") {
      title = "No subscribers to show";
      info =
        "You can see your customers that have subscribed to your recurring payments here.";
    } else if (currentTab == "active") {
      title = "No active subscribers yet";
      info =
        "You can see your customers that are currently subscribed to your recurring payments here.";
    } else if (currentTab == "unsubscribed") {
      title = "You do not have any unsubscribed customer yet";
      info =
        "Customers that have been removed from your recurring payments will appear here.";
    }

    return (
      <div className="rounded-16 border border-black-50 bg-white p-48 xl:mx-32 xl:border-none xl:p-64">
        <EmptyState
          imgSrc={EmptyStateIllustration}
          imgAlt="List of subscriptions illustration"
          title={title}
          info={info}
        />
      </div>
    );
  };

  const handleShowSubscriber = (
    subscriptionIdentifier: string,
    identifier: string,
  ) => {
    window.location.href = routes.MANAGE.SUBSCRIBERS.SHOW(
      subscriptionIdentifier,
      identifier,
    );
  };

  return (
    <>
      {isEmptySubscribers && handleEmptyState()}
      {!isEmptySubscribers && (
        <>
          <div className="table-container xl:px-32 xl:pt-64">
            <div className="table-container__header">
              <div className="table-container__header__row">
                <p className="table-container__heading">Name</p>
                <p className="table-container__heading">Number of payments</p>
                <p className="table-container__heading">Total paid</p>
                <p className="table-container__heading">Status</p>
                <p className="table-container__heading">Next payment date</p>
              </div>
            </div>

            <div className="table-container__body">
              {subscribers.map((subscriber: any) => {
                return (
                  <div key={subscriber.id} className="table-container__row">
                    <div
                      className="table-container__data-group"
                      onClick={() =>
                        handleShowSubscriber(
                          subscriptionIdentifier,
                          subscriber.customer.identifier,
                        )
                      }
                    >
                      <div className="table-container__data">
                        <div>
                          <p className="font-medium text-14 xl:font-regular">
                            {subscriber.customer.name}
                          </p>
                          <p className="mt-4 text-12 text-black-300 xl:hidden">
                            {subscriber.numberOfPayments} payments
                          </p>
                        </div>
                      </div>
                      {/* [TABLETS & ABOVE] PAYMENT AMOUNT */}
                      <div className="table-container__data xs:hidden xl:table-cell">
                        <div>
                          <p className="text-12 text-black-300 xl:text-14 xl:text-black-500">
                            {subscriber.numberOfPayments}{" "}
                            <span className="xl:hidden">payments</span>
                          </p>
                        </div>
                      </div>

                      {/* TOTAL PAID & NUMBER OF PAYMENTS */}
                      <div className="table-container__data">
                        <div>
                          <p className="font-medium text-14 xl:font-regular">
                            {subscriber.totalPaid}
                          </p>
                          <p className="mt-4 text-right text-12 text-black-300 xl:hidden xl:text-left">
                            {subscriber.nextPaymentDateFormatted}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="table-container__data-group">
                      <div
                        className="table-container__data"
                        onClick={() =>
                          handleShowSubscriber(
                            subscriptionIdentifier,
                            subscriber.customer.identifier,
                          )
                        }
                      >
                        <Status
                          color={
                            subscriber.status === "Active" ? "green" : "gray"
                          }
                          label={
                            subscriber.status === "Active"
                              ? "Active"
                              : "Unsubscribed"
                          }
                        />
                      </div>
                      <div className="table-container__data">
                        <p className="hidden text-14 xl:flex">
                          {subscriber.nextPaymentDateFormatted}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SubscriberList;
