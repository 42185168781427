import { captureException, setUser, withScope } from "@sentry/react";
/**
 * Interface for additional context to be sent to Sentry.
 */
interface AdditionalContext {
  feature: string;
  function: string;
  file?: string;
  data?: string | number | boolean | Record<string, unknown> | any[];
  errorDetails?: any;
}
export class SentryLoggingService {
  /**
   * Sets the user context for Sentry based on the user ID stored in localStorage.
   */
  static setUserContext() {
    // Retrieve user ID from localStorage
    const userId = localStorage.getItem("userId");
    // Set user if defined
    setUser({ id: userId || null });
  }

  /**
   * Get the merchant context for Sentry based on the merchant ID stored in localStorage.
   */
  static getMerchantContext() {
    // Retrieve merchant ID from localStorage
    const merchantId = localStorage.getItem("merchantId");
    // Get merchant if defined
    return { merchantId: merchantId || null };
  }

  /**
   * Captures an exception and sends it to Sentry with additional context.
   *
   * @param {string} exception - The error object to be captured.
   * @param {AdditionalContext} additionalContext - Additional information about the error.
   */
  static captureException(
    errorMsg: string,
    additionalContext: AdditionalContext,
  ) {
    this.setUserContext(); // Set user context

    withScope((scope) => {
      // Push merchant id so we can filter/group issues by merchant
      scope.setTags(this.getMerchantContext());

      // Push extra content such as api payload for better context
      scope.setExtras({
        ...additionalContext,
      });

      const exception = new Error(errorMsg as string);
      exception.name = `${additionalContext.feature}_${additionalContext.function}`;
      // Capture error
      captureException(exception);
    });
  }
}
