import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export const Download = ({ size, fill = colorsV2.text[1], ...rest }: IIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=download, Size=24">
        <path
          id="Vector"
          d="M11.996 15.7794C11.8868 15.7794 11.7826 15.7586 11.6835 15.7171C11.5844 15.6756 11.489 15.6094 11.3973 15.5185L7.55656 11.6777C7.38663 11.5032 7.30583 11.299 7.31416 11.0651C7.32249 10.8311 7.40836 10.6292 7.57176 10.4592C7.74981 10.2887 7.95655 10.2046 8.19199 10.2069C8.42742 10.2092 8.6301 10.2953 8.80004 10.4652L11.1484 12.8326V4.70217C11.1484 4.46227 11.2307 4.25937 11.3952 4.09345C11.5597 3.92752 11.7628 3.84455 12.0044 3.84455C12.2461 3.84455 12.4477 3.92752 12.6093 4.09345C12.7709 4.25937 12.8517 4.46227 12.8517 4.70217V12.8326L15.225 10.4652C15.3916 10.2953 15.5884 10.2123 15.8154 10.2163C16.0424 10.2204 16.2442 10.3075 16.4209 10.4778C16.5893 10.648 16.6724 10.8519 16.6704 11.0894C16.6684 11.3269 16.5825 11.5313 16.4125 11.7027L12.6028 15.5185C12.5108 15.6094 12.414 15.6756 12.3124 15.7171C12.2108 15.7586 12.1053 15.7794 11.996 15.7794ZM5.55384 20.1495C5.09385 20.1495 4.69498 19.9806 4.35721 19.6428C4.01946 19.3051 3.85059 18.9062 3.85059 18.4462V15.7228C3.85059 15.4824 3.93284 15.2804 4.09736 15.1167C4.26188 14.953 4.46495 14.8712 4.70659 14.8712C4.94824 14.8712 5.14986 14.953 5.31146 15.1167C5.47304 15.2804 5.55384 15.4824 5.55384 15.7228V18.4462H18.4462V15.7228C18.4462 15.4824 18.5285 15.2804 18.693 15.1167C18.8575 14.953 19.0604 14.8712 19.3017 14.8712C19.543 14.8712 19.7456 14.953 19.9096 15.1167C20.0735 15.2804 20.1555 15.4824 20.1555 15.7228V18.4462C20.1555 18.9062 19.986 19.3051 19.6471 19.6428C19.3081 19.9806 18.9079 20.1495 18.4462 20.1495H5.55384Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
