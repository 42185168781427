import isEmpty from "lodash/isEmpty";
import type { HTMLProps, JSXElementConstructor } from "react";
import { cn } from "utils/classNames";

import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export interface IIconButtonProps
  extends Omit<HTMLProps<HTMLButtonElement>, "size"> {
  classes?: string;
  fill?: string;
  Icon: JSXElementConstructor<IIcon>;
  size?: "xs" | "sm" | "md";
}

export const IconButton = ({
  classes,
  fill,
  Icon,
  size = "sm",
  ...props
}: IIconButtonProps) => {
  const newFill = isEmpty(fill) ? colorsV2.text[1] : fill;

  return (
    <button
      // @ts-ignore
      type="button"
      className={cn("flex items-center justify-center rounded-full", {
        "cursor-not-allowed text-text-color-disabled hover:bg-transparent":
          props.disabled,
        "hover:bg-surface-3": !props.disabled,

        "h-12 w-12": size === "xs",
        "h-24 w-24": size === "sm",
        "h-32 w-32": size === "md",
        [`${classes}`]: !!classes,
      })}
      {...props}
    >
      <Icon
        size="18"
        fill={props.disabled ? colorsV2.text.disabled : newFill}
      />
    </button>
  );
};
