import type { IIcon } from "types/Icon";

export const MamoCardRounded = ({ size }: IIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={size} height={size} rx="20" fill="black" />
      <g clipPath="url(#clip0_128_12217)">
        <g filter="url(#filter0_d_128_12217)">
          <rect
            x="9.33301"
            y="13.3326"
            width="21.165"
            height="13.3333"
            rx="1.0356"
            fill="white"
          />
          <g clipPath="url(#clip1_128_12217)">
            <g clipPath="url(#clip2_128_12217)">
              <path
                d="M26.612 23.9737L26.0103 25.4091H25.6178L25.3218 24.2636C25.3038 24.193 25.2882 24.1672 25.2335 24.1374C25.1443 24.089 24.9969 24.0436 24.8672 24.0154L24.876 23.9737H25.5078C25.5884 23.9737 25.6608 24.0273 25.6791 24.12L25.8354 24.9507L26.2219 23.9737H26.612ZM28.15 24.9405C28.1516 24.5616 27.6261 24.5407 27.6297 24.3715C27.6309 24.32 27.6799 24.2652 27.7872 24.2513C27.8405 24.2443 27.9871 24.239 28.1533 24.3155L28.2186 24.0111C28.1292 23.9787 28.0142 23.9474 27.8712 23.9474C27.5042 23.9474 27.2459 24.1426 27.2437 24.422C27.2413 24.6286 27.4281 24.7439 27.5687 24.8126C27.7135 24.8829 27.762 24.9281 27.7615 24.991C27.7605 25.0873 27.6461 25.1298 27.5391 25.1315C27.3525 25.1343 27.2442 25.081 27.1579 25.0408L27.0906 25.3553C27.1773 25.3951 27.3375 25.4298 27.5035 25.4316C27.8936 25.4316 28.1488 25.2389 28.15 24.9405ZM29.1192 25.4092H29.4627L29.1629 23.9737H28.8459C28.7746 23.9737 28.7145 24.0152 28.6879 24.079L28.1306 25.4091H28.5206L28.598 25.1947H29.0744L29.1192 25.4091L29.1192 25.4092ZM28.7049 24.9005L28.9003 24.3616L29.0128 24.9005H28.7049H28.7049ZM27.1426 23.9737L26.8355 25.4091H26.4642L26.7714 23.9737H27.1426Z"
                fill="black"
              />
            </g>
          </g>
          <g clipPath="url(#clip3_128_12217)">
            <path
              d="M11.2093 14.3682C10.9782 14.3682 10.7629 14.46 10.6029 14.6269C10.4427 14.794 10.3597 15.0137 10.3694 15.2456C10.3876 15.6818 10.7433 16.0354 11.1792 16.0505C11.1893 16.0508 11.1994 16.051 11.2095 16.051C11.4293 16.051 11.636 15.9677 11.7945 15.8145C11.9602 15.6545 12.0515 15.4397 12.0515 15.2096C12.0515 14.7457 11.6736 14.3682 11.2093 14.3682L11.2093 14.3682ZM11.8568 15.2432C11.8402 15.5705 11.571 15.8398 11.2437 15.8563C11.0606 15.8655 10.8813 15.7967 10.7521 15.6675C10.6228 15.5382 10.5541 15.3591 10.5633 15.1758C10.5798 14.8486 10.8491 14.5793 11.1763 14.5628C11.3596 14.5535 11.5387 14.6223 11.668 14.7516C11.7972 14.8808 11.866 15.06 11.8568 15.2432Z"
              fill="black"
            />
            <path
              d="M11.2098 15.7123C11.4813 15.7123 11.7029 15.4946 11.7092 15.2246H10.7104C10.7168 15.4946 10.9384 15.7123 11.2098 15.7123Z"
              fill="black"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_128_12217"
          x="0.749676"
          y="13.3326"
          width="89.8317"
          height="90.5833"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="25.75" dy="42.9167" />
          <feGaussianBlur stdDeviation="17.1667" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_128_12217"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_128_12217"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_128_12217">
          <rect
            width="26.6667"
            height="26.6667"
            fill="white"
            transform="translate(6.6665 6.66663)"
          />
        </clipPath>
        <clipPath id="clip1_128_12217">
          <rect
            width="5.11327"
            height="2.39482"
            fill="white"
            transform="translate(24.6079 23.4944)"
          />
        </clipPath>
        <clipPath id="clip2_128_12217">
          <rect
            width="4.59547"
            height="1.48412"
            fill="white"
            transform="translate(24.8672 23.9474)"
          />
        </clipPath>
        <clipPath id="clip3_128_12217">
          <rect
            width="2.20065"
            height="2.20065"
            fill="white"
            transform="translate(10.1099 14.1093)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
