import { useEffect } from "react";
import { Helmet } from "react-helmet";

// COMPONENTS
import { MobileNavigation } from "./MobileNavigation";
import { NavigationDrawer } from "./NavigationDrawer";
import { NavigationRail } from "./NavigationRail";

import { useCheckPermissionsV2 } from "hooks/useCheckPermissionsV2";
import { useMerchant } from "hooks/useMerchant";
import { useScreenDimension } from "hooks/useScreenDimension";
// HOOKS
import { useAppSelector } from "store";

import { isAppPlatform } from "helpers/isAppPlatform";

export const DashboardNavigationContainer = () => {
  const { activePrimaryItem } = useAppSelector(
    (state) => state.dashboardNavigation,
  );
  const { merchantDetails, getMerchantDetails } = useMerchant();
  const { currentMerchant, currentUser, platform, featureToggles } =
    merchantDetails;
  const { checkPermissionsForNavigation } = useCheckPermissionsV2(
    currentUser.permissions,
  );
  const { isMobile } = useScreenDimension();

  const identifyUserGTMTrigger = () => {
    return (window as any).dataLayer.push({
      event: "userData",
      user_id: currentMerchant.identifier,
      last_30_days_gmv: currentMerchant.last30DaysGmv,
    });
  };

  useEffect(() => {
    getMerchantDetails();
    identifyUserGTMTrigger();
  }, []);

  useEffect(() => {
    checkPermissionsForNavigation({
      currentMerchant,
      featureToggles,
      platform,
    });
  });

  return (
    <>
      <Helmet>
        {/* 
          Note: 
            The below transition is very specific based on the animation timing of the opening of the Drawer (which is from MUI) which is 225ms. So please don't change the below.

            The grid is very specific as per design because the Drawer width is 21.6rem. So adding 8rem from the NavigationRail would total this to 29.6rem. So please don't change this unless design says otherwise.
         */}
        <style type="text/css">
          {`
            [data-testid="sidebar-root"] {
              height: 100%;
            }
            .dashboard-navigation-layout {
              display: grid;
              grid-template-columns:  ${
                isMobile
                  ? "minmax(0, 1fr)"
                  : activePrimaryItem?.children
                    ? "29.6rem minmax(29.6rem, 1fr)"
                    : "8rem minmax(8rem, 1fr)"
              };
              margin-top: ${isAppPlatform() ? "0rem" : isMobile ? "5.6rem" : "6.4rem"};
              transition: grid-template-columns 0.225s ease-in-out;
            }
          `}
        </style>
      </Helmet>

      {/* 💻 Only tablet and above - navigation rail and drawer */}
      <aside className="hidden h-full md:block">
        <NavigationRail />
        {activePrimaryItem?.children && <NavigationDrawer />}
      </aside>

      {/* 📱 Only mobile - navigation */}
      <aside className="block md:hidden">
        <MobileNavigation />
      </aside>
    </>
  );
};
