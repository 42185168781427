import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export const Task = ({ size, fill = colorsV2.text[1], ...rest }: IIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=task, Size=24">
        <path
          id="Vector"
          d="M10.856 15.5853L8.78051 13.5098C8.70608 13.4388 8.62667 13.3856 8.54229 13.3503C8.4579 13.3149 8.37044 13.2983 8.27989 13.3003C8.18934 13.3023 8.09968 13.322 8.01091 13.3595C7.92214 13.397 7.84424 13.4552 7.77721 13.5342C7.63518 13.68 7.56416 13.8512 7.56416 14.0478C7.56416 14.2444 7.63531 14.4149 7.77761 14.5592L10.2473 17.0282C10.3393 17.1192 10.4361 17.1854 10.5377 17.2269C10.6393 17.2684 10.7447 17.2891 10.854 17.2891C10.9633 17.2891 11.0675 17.2684 11.1666 17.2269C11.2657 17.1854 11.3611 17.1192 11.4528 17.0282L16.1299 12.3511C16.2673 12.208 16.3359 12.0381 16.3359 11.8413C16.3359 11.6446 16.2658 11.4755 16.1255 11.3342C15.9853 11.1929 15.8158 11.1223 15.6171 11.1223C15.4184 11.1223 15.2483 11.1931 15.1066 11.3347L10.856 15.5853ZM5.55384 22.1494C5.09385 22.1494 4.69498 21.9806 4.35721 21.6428C4.01946 21.3051 3.85059 20.9062 3.85059 20.4462V3.55379C3.85059 3.09218 4.01946 2.6919 4.35721 2.35297C4.69498 2.01402 5.09385 1.84454 5.55384 1.84454H13.8761C14.1119 1.84454 14.3366 1.89219 14.5502 1.98749C14.7639 2.08278 14.9473 2.20705 15.1006 2.36032L19.6397 6.89944C19.793 7.05271 19.9173 7.23617 20.0125 7.44982C20.1078 7.66347 20.1555 7.88817 20.1555 8.12392V20.4462C20.1555 20.9062 19.986 21.3051 19.6471 21.6428C19.3081 21.9806 18.9079 22.1494 18.4462 22.1494H5.55384ZM13.7212 7.35217V3.55379H5.55384V20.4462H18.4462V8.20379H14.5729C14.3325 8.20379 14.1304 8.12196 13.9667 7.95829C13.8031 7.79461 13.7212 7.59257 13.7212 7.35217Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
