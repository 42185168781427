import { RefreshInfoToolbar } from "../shared/RefreshInfoToolbar";
import { TaxRatesTable } from "./TaxRatesTable";

type TaxRatesProps = {
  taxRates?: TaxRatesWithPagination;
};

export const TaxRates = ({ taxRates }: TaxRatesProps) => {
  return (
    <section className="flex flex-col gap-24">
      <RefreshInfoToolbar />
      <TaxRatesTable taxRates={taxRates} />
    </section>
  );
};
