import { createContext } from "react";

type GlobalConfigContext = {
  globalConfig: GlobalConfig;
};

export const initState: GlobalConfigContext = {
  globalConfig: undefined,
};

export const GlobalConfigContext =
  createContext<GlobalConfigContext>(initState);

export const GlobalConfigProvider = ({
  children,
  value,
}: {
  children: any;
  value: {
    globalConfig: GlobalConfig;
  };
}) => {
  return (
    <GlobalConfigContext.Provider value={value}>
      {children}
    </GlobalConfigContext.Provider>
  );
};
