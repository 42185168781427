import isEmpty from "lodash/isEmpty";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { cn } from "utils/classNames";

import { Button, type ButtonVariants } from "design_system/Button";
import { Close } from "design_system/Icons";

import { colorsV2 } from "constants/colors";
import type { BannerProps } from "./typeDefs";

export const Banner = ({
  buttonProps,
  description,
  dismissable,
  emoji,
  hasButton,
  Icon,
  id,
  leadingElement,
  onClose,
  title,
  variant,
}: BannerProps) => {
  const [show, setShow] = useState(true);
  const [cookies, setCookie] = useCookies([`banner-${id}`]);
  const showBanner = show && !cookies[`banner-${id}`];

  const setDimissedCookie = () => {
    const expires = new Date();
    expires.setTime(expires.getTime() + 30 * 24 * 3600 * 1000);
    setCookie(`banner-${id}`, true, {
      expires: expires,
    });
  };

  const handleClose = () => {
    setShow(false);
    setDimissedCookie();
    onClose?.();
  };

  const handleLeadingIconColor = (): string => {
    if (variant === "info") return colorsV2.accent.blue[1];
    if (variant === "attention") return colorsV2.accent.yellow[1];
    if (variant === "critical") return colorsV2.accent.red[1];
    if (variant === "success") return colorsV2.accent.green[1];
  };

  const handleButtonVariant = (): ButtonVariants => {
    if (variant === "info" || variant === "attention" || variant === "success")
      return "outlined";
    if (variant === "critical") return "critical";
  };

  return showBanner ? (
    <section
      className={cn("flex items-start gap-8 rounded-8 p-16", {
        // VARIANT CONDITIONAL STYLING
        "bg-accent-yellow-03": variant === "attention",
        "bg-accent-red-04": variant === "critical",
        "bg-surface-2": variant === "info" || variant === "success",
      })}
    >
      {!isEmpty(leadingElement) && (
        <div className="py-2">
          {leadingElement === "icon" && (
            <Icon size="18" fill={handleLeadingIconColor()} />
          )}
          {leadingElement === "emoji" && (
            <span aria-hidden="true" role="img" className="text-16">
              {emoji}
            </span>
          )}
        </div>
      )}

      <div
        className={cn("flex-grow", {
          "space-y-16": hasButton,
        })}
      >
        <div
          className={cn("flex justify-between gap-24 md:gap-40", {
            "flex-col": hasButton && dismissable,
            "flex-col md:flex-row": hasButton && !dismissable,
          })}
        >
          <div className="space-y-4">
            <h1 className="label-large">{title}</h1>
            <p className="body-medium">{description}</p>
          </div>
          {hasButton && (
            <div className="flex-shrink-0">
              <Button
                size="md"
                variant={handleButtonVariant()}
                {...buttonProps}
              />
            </div>
          )}
        </div>
      </div>

      {dismissable && (
        <button onClick={handleClose}>
          <Close size="18" />
        </button>
      )}
    </section>
  ) : null;
};
