import type React from "react";
import { cn } from "utils/classNames";

export interface ICardProps {
  /** Optional: Any additional classes to be added to the container */
  cardClasses?: string;
  /** Can be textual content, JSX or another component nested */
  children: React.ReactNode;
  /** Optional: To add a 1px Black/50 border */
  hasBorder?: boolean;
  /** Optional: A callback for click event handling */
  onClick?: () => void;
}

export const Card = ({
  children,
  hasBorder,
  cardClasses,
  onClick,
}: ICardProps) => {
  return (
    <div
      className={cn("rounded-16 bg-white p-24", {
        "border border-black-50": hasBorder,
        [`${cardClasses}`]: !!cardClasses,
      })}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
