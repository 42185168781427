import { createSlice } from "@reduxjs/toolkit";

export const expenseDashboard = createSlice({
  name: "expenseDashboard",
  initialState: {
    // SIDE SHEET STATES
    accountingIntegration: {
      isXeroConnected: false,
      isMS365Connected: false,
    },
  },
  reducers: {
    setAccountingIntegration: (state, action) => {
      state.accountingIntegration = action.payload;
    },
  },
});

export const {
  // SIDE SHEET STATES
  setAccountingIntegration,
} = expenseDashboard.actions;

export default expenseDashboard.reducer;
