import isEmpty from "lodash/isEmpty";
import { useDispatch } from "react-redux";

// COMPONENTS
import { NavigationRailPrimaryItem } from "./NavigationRailPrimaryItem";

// HOOKS
import { useAppSelector } from "store";

import { getMerchantInfoFromLocalStorage } from "utils/getMerchantInfoFromLocalStorage";
import type { DashboardNavigationItem } from "../core/DashboardNavigation.types";
// OTHERS
import { bottomNavigationItems, topNavigationItems } from "../core/constant";
import { setActivePrimaryItem } from "../core/reducer/dashboardNavigationSlice";

export const NavigationRail = () => {
  const { currentMerchant } = getMerchantInfoFromLocalStorage();
  const { permissions } = useAppSelector((state) => state);
  const dispatch = useDispatch();

  const handlePrimaryItemClick = (item: DashboardNavigationItem) => {
    dispatch(setActivePrimaryItem(isEmpty(item.url) ? item : null));
  };

  const isPathActive = (item: DashboardNavigationItem): boolean => {
    // Checks if the current location starts with the item url (this will help for sub pages too)
    // Note: not every case there'll be a url so we'll have to add the guard
    if (location.pathname.startsWith(item.url)) {
      return true;
    }
    // Checks if there's children (which is an array) in the item, then calls the isPathActive (this function) and does the same check again
    // Refer to the DashboardNavigationItem type for better understanding as well as topNavigationItems/bottomNavigationItems constant
    if (item.children) {
      return item.children.some((child) => isPathActive(child));
    }
    return false;
  };

  return (
    <section
      className="fixed flex w-80 flex-col justify-between border-border-3 border-r bg-surface-2 py-24"
      style={{ height: "calc(100% - 6.4rem)", zIndex: 1003 }}
    >
      <div className=" flex h-full flex-col justify-between gap-16">
        <div className="flex flex-col gap-16">
          {topNavigationItems(permissions, currentMerchant?.signedUpFor).map(
            (item) => (
              <NavigationRailItem
                key={item.id}
                item={item}
                isActive={isPathActive(item)}
                handleClick={handlePrimaryItemClick}
              />
            ),
          )}
        </div>
        {bottomNavigationItems(permissions).map((item) => (
          <NavigationRailItem
            key={item.id}
            item={item}
            isActive={isPathActive(item)}
            handleClick={handlePrimaryItemClick}
          />
        ))}
      </div>
    </section>
  );
};

const NavigationRailItem = ({
  item,
  isActive,
  handleClick,
}: {
  item: DashboardNavigationItem;
  isActive: boolean;
  handleClick: (item: DashboardNavigationItem) => void;
}) => {
  const { label, Icon, url } = item;

  return (
    item.hasPermission && (
      <div
        key={label}
        className={isEmpty(url) ? "cursor-pointer" : ""}
        onClick={() => handleClick(item)}
      >
        <NavigationRailPrimaryItem
          active={isActive}
          Icon={Icon}
          label={label}
          url={url}
        />
      </div>
    )
  );
};
