import { type ReactElement, useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";

import { Button } from "design_system/Button/Button";
import { ChevronLeft, Close } from "design_system/Icons";
import { cn } from "utils/classNames";
import { SideSheetContext } from "./SideSheetContext";

const SideSheet = ({ children }) => {
  const { isSideSheetOpen, closeSideSheet } = useContext(SideSheetContext);
  const [shouldRender, setShouldRender] = useState(isSideSheetOpen);

  useEffect(() => {
    if (isSideSheetOpen) {
      setShouldRender(true);
    } else {
      const timer = setTimeout(() => {
        setShouldRender(false);
      }, 500); // Duration of the slide-out animation
      return () => clearTimeout(timer);
    }
  }, [isSideSheetOpen]);

  const handleClose = () => {
    // Initiates the slide-out animation and starts the closing process
    closeSideSheet();
  };

  return shouldRender
    ? createPortal(
        <div className="fixed inset-0 overflow-hidden" style={{ zIndex: 1200 }}>
          {/* Backdrop */}
          <div
            className={`fixed inset-0 bg-overlay-1 ${isSideSheetOpen ? "" : "hidden"}`}
            onClick={handleClose}
          ></div>

          {/* SideSheet */}
          <div
            style={{
              width: "600px",
            }}
            className={`fixed inset-y-0 right-0 max-w-full rounded-l-16 bg-surface-1 shadow-sidesheet ${isSideSheetOpen ? "animate-slideIn" : "animate-slideOut"}`}
          >
            <div className="relative flex h-full flex-col">{children}</div>
          </div>
        </div>,
        document.body,
      )
    : null;
};

export default SideSheet;

// Compound components for the SideSheet parts

SideSheet.Navbar = ({ children }) => (
  <div className="py-12 pr-24 pl-16 md:pt-24 md:pb-12">{children}</div>
);

SideSheet.Display = ({ children }) => (
  <div className="display-small">{children}</div>
);

SideSheet.Description = ({
  children,
  classNames = "",
}: { children: ReactElement | string; classNames?: string }) => (
  <div className={cn(`body-large text-text-color-02 ${classNames}`)}>
    {children}
  </div>
);

SideSheet.Display = ({ children }) => (
  <div className="display-small">{children}</div>
);

SideSheet.Description = ({
  children,
  classNames = "",
}: { children: ReactElement | string; classNames?: string }) => (
  <div className={cn(`body-large text-text-color-02 ${classNames}`)}>
    {children}
  </div>
);

SideSheet.Header = ({ children }) => (
  <div className="p-16 md:px-32 md:pt-12 md:pb-24">{children}</div>
);

SideSheet.Content = ({ children }) => (
  <div className="flex-grow overflow-y-auto p-16 md:p-32">{children}</div>
);

SideSheet.Actions = ({ children }) => (
  <div className="w-full flex-none p-16 md:p-24">{children}</div>
);

SideSheet.Close = ({ onClose }) => (
  <Button
    iconOnly
    Icon={Close}
    size="lg"
    variant="outlined"
    onClick={onClose}
  />
);

SideSheet.Back = ({ onBack }) => (
  <Button
    iconOnly
    Icon={ChevronLeft}
    size="lg"
    variant="outlined"
    classes="ml-0 md:ml-16"
    onClick={onBack}
  />
);
