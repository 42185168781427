import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export const ChevronLeft = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: IIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=chevron_left, Size=24">
        <path
          id="Vector"
          d="M10.2935 11.975L14.6467 16.3282C14.8167 16.4982 14.8974 16.6971 14.8891 16.925C14.8808 17.1529 14.7917 17.3518 14.6217 17.5217C14.4518 17.6917 14.2487 17.7766 14.0125 17.7766C13.7763 17.7766 13.5732 17.6917 13.4032 17.5217L8.45924 12.5777C8.36792 12.4864 8.30161 12.3911 8.26031 12.2919C8.21901 12.1926 8.19836 12.0869 8.19836 11.975C8.19836 11.863 8.21901 11.7574 8.26031 11.6581C8.30161 11.5589 8.36792 11.4636 8.45924 11.3723L13.4282 6.40325C13.5982 6.23333 13.8013 6.14837 14.0375 6.14837C14.2737 6.14837 14.4768 6.23333 14.6467 6.40325C14.8167 6.57318 14.9016 6.77627 14.9016 7.0125C14.9016 7.24873 14.8167 7.45182 14.6467 7.62175L10.2935 11.975Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
