import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export const Wallet = ({ size, fill = colorsV2.text[1], ...rest }: IIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=wallet, Size=24">
        <path
          id="Vector"
          d="M6.00004 20.1495C4.8562 20.1495 3.87867 19.7438 3.06744 18.9326C2.2562 18.1214 1.85059 17.1438 1.85059 16V8C1.85059 6.85617 2.2562 5.87763 3.06744 5.0644C3.87867 4.25117 4.8562 3.84455 6.00004 3.84455H18C19.1439 3.84455 20.1224 4.25117 20.9356 5.0644C21.7489 5.87763 22.1555 6.85617 22.1555 8V16C22.1555 17.1438 21.7489 18.1214 20.9356 18.9326C20.1224 19.7438 19.1439 20.1495 18 20.1495H6.00004ZM6.14949 8.19023H17.8805C18.3718 8.19023 18.8359 8.26722 19.2729 8.4212C19.7098 8.57518 20.1009 8.79384 20.4462 9.07717V8C20.4462 7.31595 20.2096 6.73723 19.7362 6.26385C19.2628 5.79048 18.6841 5.5538 18 5.5538H6.00004C5.31599 5.5538 4.73727 5.79048 4.26389 6.26385C3.79052 6.73723 3.55384 7.31595 3.55384 8V9.08912C3.90312 8.80181 4.29823 8.58016 4.73916 8.42417C5.18011 8.26821 5.65022 8.19023 6.14949 8.19023ZM3.73646 11.7636L15.4403 14.5848C15.5729 14.6221 15.7086 14.6263 15.8476 14.5973C15.9865 14.5683 16.1057 14.5082 16.2049 14.4169L20.0614 11.1804C19.8488 10.7931 19.5488 10.4828 19.1614 10.2495C18.7741 10.0161 18.3471 9.89945 17.8805 9.89945H6.14949C5.57015 9.89945 5.05748 10.0756 4.61146 10.428C4.16544 10.7803 3.87378 11.2255 3.73646 11.7636Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
