import type ISubscriptionRootProps from "interfaces/subscriptions/ISubscriptionRootProps";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SubscriptionListContainer from "./SubscriptionListContainer";

const SubscriptionsRoot = (props: ISubscriptionRootProps) => {
  const { hasBasic, isMerchantVerified, hasPremiumBadge } = props;

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/manage/subscriptions"
          element={
            <SubscriptionListContainer
              hasBasic={hasBasic}
              hasPremiumBadge={hasPremiumBadge}
              isMerchantVerified={isMerchantVerified}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default SubscriptionsRoot;
