import { ToggleGroup, ToggleGroupItem } from "@radix-ui/react-toggle-group";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { FadeIn } from "components/AnimatedElements/FadeIn";
import Button from "components/Button/Button";
import routes from "constants/routes";

import { isAppPlatform } from "helpers/isAppPlatform";
import { cn } from "utils/classNames";
import { ChoosePlanActionTypes } from "../reducers/choosePlanReducer";
import {
  growthExpeditedSettlementOptions,
  growthSettlementOptions,
  premiumExpeditedSettlementOptions,
  premiumSettlementOptions,
  ultraExpeditedSettlementOptions,
  ultraSettlementOptions,
} from "./constant";

interface IProps {
  state: any;
  dispatch: any;
  activePlan: any;
  eligibleForExpeditedSettlements: boolean;
}

const Step2 = (props: IProps) => {
  const navigate = useNavigate();
  const { state, dispatch, eligibleForExpeditedSettlements, activePlan } =
    props;
  const { selectedPlan } = state;

  const [settlementOption, setSettlementOption] = useState<string>(
    state.settlementOption,
  );

  const onNextStep = () => {
    dispatch({
      type: ChoosePlanActionTypes.SET_DATA,
      payload: (state.settlementOption = settlementOption),
    });

    if (!selectedPlan) {
      if (activePlan.name === "Growth") {
        dispatch({
          type: ChoosePlanActionTypes.SET_GROWTH_PLAN,
        });
      } else if (activePlan.name === "Premium") {
        dispatch({
          type: ChoosePlanActionTypes.SET_PREMIUM_PLAN,
        });
      } else if (activePlan.name === "Enterprise") {
        dispatch({
          type: ChoosePlanActionTypes.SET_ENTERPRISE_PLAN,
        });
      } else if (activePlan.name === "Ultra") {
        dispatch({
          type: ChoosePlanActionTypes.SET_ULTRA_PLAN,
        });
      }
    }

    navigate(routes.MANAGE.PLANS.CONFIRM_CHANGES());
  };

  const calculatedPremiumSettlementOptions = eligibleForExpeditedSettlements
    ? premiumExpeditedSettlementOptions
    : premiumSettlementOptions;

  const calculatedGrowthSettlementOptions = eligibleForExpeditedSettlements
    ? growthExpeditedSettlementOptions
    : growthSettlementOptions;

  const calculatedUltraSettlementOptions = eligibleForExpeditedSettlements
    ? ultraExpeditedSettlementOptions
    : ultraSettlementOptions;

  return (
    <>
      {/* HEADER WITH CLOSE AND BACK BUTTONS */}
      <header className="choose-plan__header">
        <FadeIn custom={0}>
          <h1>Choose your settlement period</h1>
          <p>
            Choose when you'd like your received payments to be sent to your
            bank account
          </p>
        </FadeIn>

        <button
          className="choose-plan__back-button md:bg-black-25"
          onClick={() => navigate(-1)}
        >
          <i className="material-icons-round">arrow_back</i>
        </button>
        {!isAppPlatform() && (
          <a
            className="choose-plan__close-button md:bg-black-25"
            href={routes.MANAGE.ACCOUNT_SETTINGS.PLAN()}
          >
            <i className="material-icons-round">close</i>
          </a>
        )}
      </header>

      {/* SETTLEMENT OPTIONS */}
      <FadeIn custom={1}>
        <section className="choose-plan__settlements space-y-32 md:space-y-48">
          <ToggleGroup type="single" onValueChange={setSettlementOption}>
            <div className="space-y-18">
              {selectedPlan === "Growth" &&
                calculatedGrowthSettlementOptions.map((item) => (
                  <div key={item.settlementPeriod} className="radio-group-blue">
                    <ToggleGroupItem
                      value={item.settlementPeriod.toString()}
                      className="radio-group-blue__label"
                    >
                      <div className="radio-group-blue__content flex w-full items-center justify-between">
                        <p>{item.text}</p>
                        <div
                          className={cn("text-right text-blue-500", {
                            "text-green-400": item.cost === "Free",
                          })}
                        >
                          {item.fee && (
                            <p>
                              +{item.fee}{" "}
                              <span className="font-regular text-12">
                                {" "}
                                transaction fee
                              </span>
                            </p>
                          )}
                          {item.cost && (
                            <p>
                              {item.cost}
                              {item.cost !== "Free" && (
                                <span className="font-regular text-12">
                                  {" "}
                                  per settlement
                                </span>
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                    </ToggleGroupItem>
                  </div>
                ))}

              {selectedPlan === "Ultra" &&
                calculatedUltraSettlementOptions.map((item) => (
                  <div key={item.settlementPeriod} className="radio-group-blue">
                    <ToggleGroupItem
                      value={item.settlementPeriod.toString()}
                      className="radio-group-blue__label"
                    >
                      <div className="radio-group-blue__content flex w-full items-center justify-between">
                        <p>{item.text}</p>
                        <div
                          className={cn("text-right text-blue-500", {
                            "text-green-400": item.cost === "Free" && !item.fee,
                          })}
                        >
                          {item.fee && (
                            <p>
                              +{item.fee}{" "}
                              <span className="font-regular text-12">
                                {" "}
                                transaction fee
                              </span>
                            </p>
                          )}
                          <p>{!item.fee && item.cost}</p>
                        </div>
                      </div>
                    </ToggleGroupItem>
                  </div>
                ))}

              {(selectedPlan === "Premium" || selectedPlan === "Enterprise") &&
                calculatedPremiumSettlementOptions.map((item) => (
                  <div key={item.settlementPeriod} className="radio-group-blue">
                    <ToggleGroupItem
                      value={item.settlementPeriod.toString()}
                      className="radio-group-blue__label"
                    >
                      <div className="radio-group-blue__content flex w-full items-center justify-between">
                        <p>{item.text}</p>
                        <div
                          className={cn("text-right text-blue-500", {
                            "text-green-400": item.cost === "Free",
                          })}
                        >
                          {item.fee && (
                            <p>
                              +{item.fee}{" "}
                              <span className="font-regular text-12">
                                {" "}
                                transaction fee
                              </span>
                            </p>
                          )}
                          {item.cost && (
                            <p>
                              {item.cost}
                              {item.cost !== "Free" && (
                                <span className="font-regular text-12">
                                  {" "}
                                  per settlement
                                </span>
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                    </ToggleGroupItem>
                  </div>
                ))}

              {!selectedPlan &&
                activePlan.name === "Growth" &&
                calculatedGrowthSettlementOptions.map((item) => (
                  <div key={item.settlementPeriod} className="radio-group-blue">
                    <ToggleGroupItem
                      value={item.settlementPeriod.toString()}
                      className="radio-group-blue__label"
                    >
                      <div className="radio-group-blue__content flex w-full items-center justify-between">
                        <p>{item.text}</p>
                        <div
                          className={cn("text-right text-blue-500", {
                            "text-green-400": item.cost === "Free" && !item.fee,
                          })}
                        >
                          {item.fee && (
                            <p>
                              +{item.fee}{" "}
                              <span className="font-regular text-12">
                                {" "}
                                transaction fee
                              </span>
                            </p>
                          )}
                          <p>{!item.fee && item.cost}</p>
                        </div>
                      </div>
                    </ToggleGroupItem>
                  </div>
                ))}

              {!selectedPlan &&
                activePlan.name === "Ultra" &&
                calculatedUltraSettlementOptions.map((item) => (
                  <div key={item.settlementPeriod} className="radio-group-blue">
                    <ToggleGroupItem
                      value={item.settlementPeriod.toString()}
                      className="radio-group-blue__label"
                    >
                      <div className="radio-group-blue__content flex w-full items-center justify-between">
                        <p>{item.text}</p>
                        <div
                          className={cn("text-right text-blue-500", {
                            "text-green-400": item.cost === "Free" && !item.fee,
                          })}
                        >
                          {item.fee && (
                            <p>
                              +{item.fee}{" "}
                              <span className="font-regular text-12">
                                {" "}
                                transaction fee
                              </span>
                            </p>
                          )}
                          <p>{!item.fee && item.cost}</p>
                        </div>
                      </div>
                    </ToggleGroupItem>
                  </div>
                ))}

              {!selectedPlan &&
                (activePlan.name === "Premium" ||
                  activePlan.name === "Enterprise") &&
                calculatedPremiumSettlementOptions.map((item) => (
                  <div key={item.settlementPeriod} className="radio-group-blue">
                    <ToggleGroupItem
                      value={item.settlementPeriod.toString()}
                      className="radio-group-blue__label"
                    >
                      <div className="radio-group-blue__content flex w-full items-center justify-between">
                        <p>{item.text}</p>
                        <div
                          className={cn("text-right text-blue-500", {
                            "text-green-400": item.cost === "Free",
                          })}
                        >
                          {item.fee && (
                            <p>
                              +{item.fee}{" "}
                              <span className="font-regular text-12">
                                {" "}
                                transaction fee
                              </span>
                            </p>
                          )}
                          {item.cost && (
                            <p>
                              {item.cost}
                              {item.cost !== "Free" && (
                                <span className="font-regular text-12">
                                  {" "}
                                  per settlement
                                </span>
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                    </ToggleGroupItem>
                  </div>
                ))}
            </div>
          </ToggleGroup>

          <div className="flex flex-row items-start gap-12 rounded-16 bg-blue-25 p-24 md:items-center md:gap-16 md:p-32">
            <div className="material-icons-round rounded-full bg-white p-12 text-24 text-blue-500 md:p-16">
              info
            </div>
            <div className="space-y-4">
              <p className="font-medium text-16">Settlement changes</p>
              <p className="text-14 text-black-300">
                Your updated settlement period will be applied to future
                payments. Any pending payments will be settled at the current
                settlement period.
              </p>
            </div>
          </div>

          <Button
            color="primary"
            size="lg"
            label="Continue"
            isDisabled={settlementOption == null}
            onClick={onNextStep}
          />
        </section>
      </FadeIn>
    </>
  );
};

export default Step2;
