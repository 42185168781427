import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export const NorthEast = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: IIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=north_east, Size=24">
        <path
          id="Vector"
          d="M17.2596 8.0585L5.82181 19.4965C5.63548 19.6802 5.41381 19.772 5.15681 19.772C4.89981 19.772 4.67948 19.6802 4.49581 19.4965C4.30931 19.3127 4.21606 19.0916 4.21606 18.8333C4.21606 18.5749 4.30931 18.3539 4.49581 18.1703L15.9336 6.7325H9.93981C9.67315 6.7325 9.44873 6.641 9.26656 6.458C9.08423 6.27517 8.99306 6.04867 8.99306 5.7785C8.99306 5.511 9.08423 5.28684 9.26656 5.106C9.44873 4.925 9.67315 4.8345 9.93981 4.8345H18.2066C18.4732 4.8345 18.6977 4.92567 18.8801 5.108C19.0624 5.29034 19.1536 5.51484 19.1536 5.7815V14.0483C19.1536 14.3176 19.0621 14.5434 18.8793 14.7258C18.6963 14.9079 18.4711 14.999 18.2036 14.999C17.9361 14.999 17.7119 14.9079 17.5311 14.7258C17.3501 14.5434 17.2596 14.3176 17.2596 14.0483V8.0585Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
