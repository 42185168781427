import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

/**
 * Used to generate tailwind classes conditionally, merge multiple classes
 * into a single class with tailwind-merge.
 * @param inputs - ClassValue[]
 * @returns {string}
 */
export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs));
