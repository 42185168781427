import type { FunctionComponent } from "react";

import { PageHeader } from "./PageHeader";

import type IPersonalProps from "./interface/IPersonalProps";

interface IProps {
  personal: IPersonalProps;
}

const PersonalContainer: FunctionComponent<IProps> = (props: IProps) => {
  const { firstName, lastName, phoneNumber, email } = props.personal;

  return (
    <>
      <PageHeader />

      <div className="account-settings">
        <div className="account-settings__personal-details">
          <div className="account-settings__personal-details__card">
            <ul className="account-settings__personal-details__card__list">
              {/* <!-- FIRST NAME --> */}
              <li className="account-settings__personal-details__card__list__item">
                <p className="account-settings__personal-details__card__list__item__heading">
                  First name
                </p>
                <p className="account-settings__personal-details__card__list__item__info">
                  {firstName}
                </p>
              </li>

              {/* <!-- LAST NAME --> */}
              <li className="account-settings__personal-details__card__list__item">
                <p className="account-settings__personal-details__card__list__item__heading">
                  Last name
                </p>
                <p className="account-settings__personal-details__card__list__item__info">
                  {lastName}
                </p>
              </li>

              {/* <!-- PHONE NUMBER --> */}
              <li className="account-settings__personal-details__card__list__item">
                <p className="account-settings__personal-details__card__list__item__heading">
                  Phone number
                </p>
                <p className="account-settings__personal-details__card__list__item__info">
                  {phoneNumber}
                </p>
              </li>

              {/* <!-- EMAIL --> */}
              <li className="account-settings__personal-details__card__list__item">
                <p className="account-settings__personal-details__card__list__item__heading">
                  Email
                </p>
                <p className="account-settings__personal-details__card__list__item__info">
                  {email}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalContainer;
