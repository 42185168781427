import { yupResolver } from "@hookform/resolvers/yup";
import { convertToLocaleStringHelper } from "helpers/currency";
import { getGlobalConfig } from "hooks/useGetGlobalConfig";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  BankAccountTypes,
  type IDisbursementManualState,
  NewDisbursementActionTypes,
  Steps,
} from "reducers/newDisbursementsReducer";

import * as Select from "@radix-ui/react-select";
import { currencyConfig } from "constants/currency";
import routes from "constants/routes";
import * as ibantools from "ibantools";
import * as Yup from "yup";

import Button from "components/Button/Button";
import { MBCurrencyInput } from "components/Inputs/CurrencyInput";
import TextInput from "components/Inputs/TextInput";

import "react-phone-input-2/lib/high-res.css";
import { isAppPlatform } from "helpers/isAppPlatform";
import { isEmpty, isUndefined, startsWith } from "lodash";

const ManualUpload = ({ state, dispatch, setCurrentStep, onPrevStep }: any) => {
  const [globalConfig, setGlobalConfig] = useState<GlobalConfig>();
  const [bankAccountType, setBankAccountType] = useState<string>(
    state.bankAccountType,
  );

  const manualUploadFormSchema = Yup.object().shape({
    amount: Yup.string()
      .required("Please enter an amount")
      .test(
        "amount",
        `The minimum payment amount is AED ${convertToLocaleStringHelper(globalConfig?.disbursement.minAmount)}`,
        (val) =>
          val != undefined &&
          Number.parseFloat(val) >=
            (globalConfig?.disbursement.minAmount || 10),
      )
      .test(
        "amount",
        `The maximum payment amount is AED ${convertToLocaleStringHelper(globalConfig?.disbursement.maxAmount)}`,
        (val) =>
          val != undefined &&
          Number.parseFloat(val) <= (globalConfig?.disbursement.maxAmount || 0),
      ),
    iban: Yup.string()
      .required("Please enter a valid IBAN")
      .test(
        "iban",
        "Please enter a valid IBAN",
        (val) =>
          !isEmpty(val) &&
          !isUndefined(val) &&
          ibantools.isValidIBAN(val) &&
          startsWith(val, "AE"),
      ),
    accountHolderName: Yup.string().when("isBusiness", {
      is: () => bankAccountType === BankAccountTypes.BUSINESS,
      then: Yup.string().required(
        "Please enter the name of the business this account belongs to",
      ),
    }),
    firstName: Yup.string().when("isPersonal", {
      is: () => bankAccountType === BankAccountTypes.PERSONAL,
      then: Yup.string().required("Please enter the recipient’s first name"),
    }),
    lastName: Yup.string().when("isPersonal", {
      is: () => bankAccountType === BankAccountTypes.PERSONAL,
      then: Yup.string().required("Please enter the recipient’s last name"),
    }),
    reason: Yup.string().required(
      "Please enter the reason for this disbursement",
    ),
  });

  const { errors, handleSubmit, control, register } =
    useForm<IDisbursementManualState>({
      defaultValues: {
        amount: state.amount,
        bankAccountType: state.bankAccountType,
        iban: state.iban,
        accountHolderName: state.accountHolderName,
        firstName: state.firstName,
        lastName: state.lastName,
        email: state.email,
        reason: state.reason,
      },
      resolver: yupResolver(manualUploadFormSchema),
      mode: "onBlur",
    });

  useEffect(() => {
    const fetchGlobalConfig = async () => {
      try {
        const config = await getGlobalConfig();
        setGlobalConfig(config);
      } catch (error) {
        console.error(error);
      }
    };

    fetchGlobalConfig();
  }, []);

  const onSubmit = (data: any) => {
    const formData = {
      ...data,
      bankAccountType,
    };
    dispatch({
      type: NewDisbursementActionTypes.SET_MANUAL_DETAILS,
      payload: formData,
    });
    setCurrentStep(Steps.ConfirmDisbursement);
  };

  const actionOnClick = (e: any) => {
    // When we focus on the description field and press the enter button.
    // It will trigger the action onclick in the form.
    // e.nativeEvent.pointerType will check type that you input
    // press enter => pointerType === ''
    if (e.nativeEvent.pointerType !== "") {
      onPrevStep();
    }
  };

  return (
    <>
      <header>
        <button
          className="create-link__back-button material-icons-round"
          onClick={actionOnClick}
        >
          arrow_back
        </button>
        {!isAppPlatform() && (
          <a
            href={routes.MANAGE.DISBURSEMENTS.INDEX()}
            className="create-link__close-button material-icons-round"
          >
            close
          </a>
        )}
      </header>
      <section className="create-link__customize-link-step">
        {/* PROCESS COMPONENT */}
        <div className="process pb-80 md:pb-48">
          <div className="process__content">
            <h1 className="process__content__heading">Add payout details</h1>
            <p className="process__content__info">
              Please fill out the information below to create a payout
            </p>
          </div>
          {/* aa */}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-32 md:space-y-48"
          >
            <div>
              <h2 className="heading-03">Amount</h2>
              <div className="form-field">
                <div className="form-field__control">
                  <MBCurrencyInput
                    control={control}
                    errors={errors}
                    fieldName="amount"
                    intlConfig={currencyConfig.AE}
                    placeholder="AED 0.00"
                    prefix="AED"
                    step={10}
                  />
                </div>
              </div>
            </div>

            {/* BANK ACCOUNT FIELDS */}
            <div className="space-y-16">
              <h2 className="heading-03">Choose type of bank account</h2>
              <Select.Root
                value={bankAccountType}
                onValueChange={setBankAccountType}
              >
                <Select.Trigger className="form-field__select">
                  <Select.Value>{bankAccountType}</Select.Value>
                  <Select.Icon className="material-icons-round">
                    keyboard_arrow_down
                  </Select.Icon>
                </Select.Trigger>

                <Select.Portal>
                  <Select.Content className="form-field__select__content">
                    <Select.Viewport>
                      <Select.Item
                        value={BankAccountTypes.BUSINESS}
                        className="form-field__select__item"
                      >
                        <Select.ItemText>Business</Select.ItemText>
                      </Select.Item>
                      <Select.Item
                        value={BankAccountTypes.PERSONAL}
                        className="form-field__select__item"
                      >
                        <Select.ItemText>Individual</Select.ItemText>
                      </Select.Item>
                    </Select.Viewport>
                  </Select.Content>
                </Select.Portal>
              </Select.Root>

              <Controller
                control={control}
                name="iban"
                render={(props) => (
                  <TextInput
                    inputId="iban"
                    name="iban"
                    data-testid="iban-dropdown"
                    labelText="IBAN"
                    onChange={(e) => {
                      props.onChange(
                        e.currentTarget.value.replace(/[^\w]/gi, ""),
                      );
                    }}
                    onBlur={props.onBlur}
                    value={props.value}
                    errors={errors.iban}
                    errorMessage={errors.iban?.message}
                  />
                )}
              />

              <div className="pt-32">
                <h2 className="heading-03">Account holder name</h2>

                {bankAccountType === BankAccountTypes.BUSINESS ? (
                  <TextInput
                    inputId="accountHolderName"
                    name="accountHolderName"
                    data-testid="account-holder-name"
                    placeholder="Business name"
                    onFocusText="Business name"
                    onBlurCapture={() => {
                      "Business name";
                    }}
                    register={register}
                    errors={errors.accountHolderName}
                    errorMessage={errors.accountHolderName?.message}
                  />
                ) : (
                  <div className="lg:-mb-32 flex flex-col gap-8 lg:flex-row">
                    <TextInput
                      inputId="firstName"
                      name="firstName"
                      labelText="First name"
                      register={register}
                      errors={errors.firstName}
                      errorMessage={errors.firstName?.message}
                    />
                    <TextInput
                      inputId="lastName"
                      name="lastName"
                      labelText="Last name"
                      register={register}
                      errors={errors.lastName}
                      errorMessage={errors.lastName?.message}
                      classes="-mt-24 lg:mt-0"
                    />
                  </div>
                )}
              </div>
            </div>

            <div>
              <h2 className="heading-03">Payout reason</h2>
              <TextInput
                inputId="reason"
                name="reason"
                placeholder="E.g. material payment"
                onFocusText="E.g. material payment"
                onBlurCapture={() => {
                  "E.g. material payment";
                }}
                errors={errors.reason ? true : false}
                errorMessage={errors.reason?.message}
                register={register}
              />
            </div>

            {/* LARGE BUTTON FOR TABLET AND ABOVE */}
            <div className="hidden md:flex">
              <Button
                color="primary"
                size="lg"
                label="Create payout"
                classes="mx-auto"
              />
            </div>

            {/* MEDIUM BUTTON FOR MOBILE */}
            <div className="md:hidden">
              <Button
                color="primary"
                size="md"
                label="Create payout"
                classes="w-full"
              />
            </div>
          </form>
        </div>
        {/* bb */}
      </section>
    </>
  );
};

export default ManualUpload;
