/* eslint-disable react/prop-types */
import { flexRender } from "@tanstack/react-table";
import { useCallback } from "react";

import Pagination from "components/ui/Pagination/PaginationV2";
import { Checkbox } from "design_system/Inputs/Checkbox";

import useTableLogic from "./useTableLogic";

import { colors, colorsV2 } from "constants/colors";
import { cn } from "utils/classNames";
import { initState } from "./constants/initState";
import type { TableV2Props } from "./typeDefs";

const Table: React.FC<TableV2Props> = ({
  columns,
  data,
  emptyState,
  containerClass,
  tableClass,
  styles = initState.styles,
  onClick,
  cellClass,
  headerRowClass,
  headerCellClass,
  paginationMeta,
  hiddenColumns,
  name,
  onSetPage,
  rowProps,
}: any) => {
  const {
    selectable,
    hoveredRow,
    allRowsSelected,
    isEmptyTable,
    table,
    topLevelHeadings,
    setHoveredRow,
    toggleRowSelected,
    selectAllRows,
  } = useTableLogic({ columns, data, hiddenColumns, name, rowProps });

  if (!table) return null;

  const getStickyColumnStyle = useCallback((index: number) => {
    // If the column is the first one (index 0), it should be sticky.
    if (index === 0) {
      return {
        // Telling typescript that sticky is one of css position property values not a string
        position: "sticky" as const,
        left: 0,
        // Ensure it stays on top during horizontal scroll
        zIndex: 999,
      };
    }
    // For other columns, return an empty object (no specific styling).
    return {};
  }, []);

  /**
   *
   * @param maxCells The number of columns
   * @param currentCellIndex The index of current column, starts from zero
   * @returns Styles for columns which are common in all tables
   */
  const getHeadingCellStyle = useCallback(
    (maxCells: number, currentCellIndex: number) => {
      const borderRadius = "8px";
      const baseStyles = {
        ...getStickyColumnStyle(currentCellIndex),
        borderRight: `1px solid ${colors.black[50]}`,
      };
      if (currentCellIndex === 0) {
        return {
          ...baseStyles,
          borderTopLeftRadius: borderRadius,
          borderBottomLeftRadius: borderRadius,
          backgroundColor: colorsV2.surface[2],
        };
      }
      if (currentCellIndex === maxCells - 1) {
        return {
          borderTopRightRadius: borderRadius,
          borderBottomRightRadius: borderRadius,
          borderRight: "0px",
        };
      }
      return baseStyles;
    },
    [getStickyColumnStyle],
  );

  const getBodyCellStyle = useCallback(
    (
      cell: any,
      currentCellIndex: number,
      maxCells: number,
      isRowSelected?: boolean,
      isRowHovered?: boolean,
    ) => {
      const cellWidth = cell.column.columnDef.size;
      const widthStyle = cellWidth ? { width: cellWidth } : {};
      const isBorderNeeded = !isRowHovered && !isRowSelected;
      const borderRadius = "8px";

      const baseStyle = {
        ...widthStyle,
        height: "100%",
        backgroundColor: isRowSelected
          ? colorsV2.surface[6]
          : isRowHovered
            ? colorsV2.surface[2]
            : colorsV2.surface[1],
        ...getStickyColumnStyle(currentCellIndex),
        borderTopLeftRadius:
          currentCellIndex === 0 && isBorderNeeded ? borderRadius : "0px",
        borderBottomLeftRadius:
          currentCellIndex === 0 && isBorderNeeded ? borderRadius : "0px",
        borderTopRightRadius:
          currentCellIndex === maxCells - 1 && isBorderNeeded
            ? borderRadius
            : "0px",
        borderBottomRightRadius:
          currentCellIndex === maxCells - 1 && isBorderNeeded
            ? borderRadius
            : "0px",
        borderRight: `1px solid ${colorsV2.border[3]}`,
        borderBottom: isBorderNeeded
          ? `1px solid ${colorsV2.border[3]}`
          : `1px solid ${colorsV2.surface[1]}`,
      };

      if (currentCellIndex === 0) {
        return {
          ...baseStyle,
          paddingLeft: selectable ? "16px" : "0px",
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
        };
      }
      if (currentCellIndex === maxCells - 1) {
        return {
          ...baseStyle,
          borderTopRightRadius: "8px",
          borderBottomRightRadius: "8px",
          borderRight: "0px",
        };
      }
      return { ...baseStyle };
    },
    [getStickyColumnStyle],
  );

  return (
    // Component Wrapper
    <div
      data-testid={`${name}-wrapper`}
      className={cn(`w-full ${containerClass}`)}
    >
      {/* Scroll Wrapper */}
      <div className="w-full overflow-x-auto">
        <table
          data-testid={name}
          style={{
            minWidth: isEmptyTable ? "100%" : styles.table.minWidth,
            ...styles.table,
          }}
          className={tableClass}
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup: any, index: number) => (
              <tr
                key={headerGroup.id}
                className={cn(
                  `h-40 bg-surface-2 p-4 text-text-color-03 ${headerRowClass}`,
                )}
              >
                {headerGroup.headers.map((header: any, index: number) => {
                  const alignment = header.column.columnDef.meta?.alignment;
                  const alignmentStyle =
                    alignment === "end"
                      ? {
                          justifyContent: "end",
                          paddingRight: "16px",
                        }
                      : {};

                  return (
                    <th
                      key={header.id}
                      className={cn(
                        `label-small pl-16 text-left ${headerCellClass}`,
                      )}
                      style={{
                        ...getHeadingCellStyle(
                          headerGroup.headers.length,
                          index,
                        ),
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          className="flex h-full w-full items-center gap-4"
                          style={alignmentStyle}
                        >
                          {index === 0 && selectable && (
                            // Master checkbox to select/deselect all rows
                            <Checkbox
                              classes="-mt-20 mr-14"
                              checkboxSize="sm"
                              name="allRows"
                              inputId="allRows"
                              checked={allRowsSelected}
                              onChange={() => selectAllRows()}
                            />
                          )}
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody className="divide-y divide-black-50">
            {isEmptyTable ? (
              <tr>
                <td colSpan={topLevelHeadings.length}>{emptyState}</td>
              </tr>
            ) : (
              table.getRowModel().rows.map((row: any) => {
                const isRowSelected = !![...rowProps.selectedRows].find(
                  (rowData: any) => {
                    return row.original.identifier === rowData.identifier;
                  },
                );
                const isRowHovered = hoveredRow === row.id;
                return (
                  <tr
                    key={row.id}
                    className={`h-full hover:bg-surface-2 ${
                      isRowSelected
                        ? "bg-surface-6 hover:bg-none"
                        : "bg-surface-01"
                    }`}
                    onMouseEnter={() => setHoveredRow(row.id)}
                    onMouseLeave={() => setHoveredRow(null)}
                  >
                    {row.getVisibleCells().map((cell: any, index: number) => (
                      <td
                        key={cell.id}
                        className={cn(`whitespace-nowrap ${cellClass}`)}
                        style={getBodyCellStyle(
                          cell,
                          index,
                          row.getVisibleCells().length,
                          isRowSelected,
                          isRowHovered,
                        )}
                      >
                        <div className="flex h-full w-full items-start gap-4">
                          {index === 0 && selectable && (
                            // Checkbox for each row
                            <Checkbox
                              classes="mt-28"
                              checkboxSize="sm"
                              name={`checkbox-row${row.id}`}
                              inputId={`checkbox-row${row.id}`}
                              checked={isRowSelected}
                              onChange={() =>
                                toggleRowSelected({
                                  rowId: row.id,
                                  ...row.original,
                                })
                              }
                            />
                          )}
                          <div
                            className="w-full"
                            onClick={() => onClick?.(cell.row.original)}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </div>
                        </div>
                      </td>
                    ))}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      {!isEmptyTable && paginationMeta?.from && (
        <div className="disbursements__pagination">
          <Pagination
            itemName={paginationMeta.itemName}
            meta={paginationMeta}
            onSetPage={onSetPage}
          />
        </div>
      )}
    </div>
  );
};

export default Table;
