import { useContext, useMemo } from "react";
import { cn } from "utils/classNames";

import { Divider } from "components/Divider";
import { TableRecord } from "components/Table/TableRecord";
import Table from "components/Table/V2/TableV2";
import { TableEmptyState } from "./TableEmptyState";
import { TransactionDetailSidesheet } from "./TransactionDetailSidesheet";

import { useScreenDimension } from "hooks/useScreenDimension";
import { useTransactionsTable } from "./useTransactionsTable";

import Pagination from "components/ui/Pagination/PaginationV2";
import { PAGINTATION_META_INIT_STATE } from "constants/table";
import { SideSheetContext } from "design_system/Sidesheet/SideSheetContext";
import type { TagColors } from "design_system/Tag/typeDefs";
import type {
  TransactionsTableProps,
  WalletTransaction,
} from "features/Wallet/WalletV2/typeDefs";
import {
  activityStatusMappings,
  activityTypeMappings,
} from "../constants/activityMapping";

export const TransactionsTable = ({ transactions }: TransactionsTableProps) => {
  const { isSideSheetOpen } = useContext(SideSheetContext);

  const { isMobile } = useScreenDimension();
  const { COLUMNS, handleCellClick, handleSetPage } = useTransactionsTable();

  const paginationMeta = {
    ...(transactions
      ? transactions.paginationMeta
      : PAGINTATION_META_INIT_STATE),
    itemName: "Transactions",
  };

  const columns = useMemo(() => COLUMNS, []);
  const tableData = useMemo(() => transactions?.data, [transactions?.data]);

  const isEmptyTable = !transactions || transactions?.data?.length === 0;

  if (!isMobile) {
    return (
      <div className="flex w-full justify-center">
        <Table
          name="transactions-table"
          // @ts-ignore
          columns={columns}
          data={tableData}
          paginationMeta={paginationMeta}
          onSetPage={handleSetPage}
          tableClass="table"
          headerRowClass="bg-black-25"
          headerCellClass="w-auto"
          rowProps={{
            selectable: false,
            selectedRows: [],
            setSelectedRows: () => {},
          }}
          emptyState={<TableEmptyState />}
        />
        {isSideSheetOpen && <TransactionDetailSidesheet />}
      </div>
    );
  }

  return isEmptyTable ? (
    <TableEmptyState />
  ) : (
    <>
      {transactions?.data?.map((transaction: WalletTransaction, index) => {
        const title = activityTypeMappings[transaction.breakdownType];
        const status = activityStatusMappings[transaction.status];

        return (
          <>
            <TableRecord
              key={transaction.id + index}
              className={cn({ "-mt-16": index === 0 })}
              onClick={handleCellClick(transaction)}
            >
              <TableRecord.Tag
                tagProps={{
                  size: "xl",
                  iconOnly: true,
                  color: status.color as TagColors,
                  label: status.label,
                  Icon: status.Icon,
                }}
              />
              <TableRecord.Column1
                label={title}
                supportingText={transaction.reference.id}
              />
              <TableRecord.Column2 label={transaction.amountFormatted} />
            </TableRecord>
            <Divider />
          </>
        );
      })}
      <div className="disbursements__pagination px-16">
        <Pagination
          itemName={paginationMeta.itemName}
          meta={paginationMeta}
          onSetPage={handleSetPage}
        />
      </div>
      {isSideSheetOpen && <TransactionDetailSidesheet />}
    </>
  );
};
