import { type QueryObserverResult, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { endpoints } from "constants/apiEndpoints";
import get from "lodash/get";
import qs from "qs";

import { RECORDS_PER_PAGE } from "constants/api";
import { PAGINTATION_META_INIT_STATE } from "constants/table";
import { SentryLoggingService } from "init/SentryLoggingService";

export const useGetExpenseAccounts = (configs) => {
  const { currency, enabled, page } = configs;

  const queryParams = {
    currency,
    page,
    per_page: RECORDS_PER_PAGE,
  };

  const getExpenseAccounts: QueryObserverResult<any> = useQuery({
    enabled,
    queryKey: ["getExpenseAccounts", { currency, page }],
    cacheTime: 0,
    queryFn: async () => {
      try {
        const response = await axios.get(
          `${endpoints.MANAGE.API.ACCOUNTING_INTEGRATIONS.EXPENSE_ACCOUNTS}?${qs.stringify(queryParams)}`,
        );

        const data = get(response, "data.data", []);
        const paginationMeta = get(
          response,
          "data.paginationMeta",
          PAGINTATION_META_INIT_STATE,
        );

        const formattedData = data.map((account: any) => {
          return {
            ...account,
            id: account.identifier,
            label: account.name,
            value: account.identifier,
          };
        });

        return {
          data: formattedData,
          paginationMeta,
        };
      } catch (error: any) {
        SentryLoggingService.captureException(error.message, {
          feature: "[Accounting][Expense Accounts]",
          file: "useGetExpenseAccounts.tsx",
          function: "useGetExpenseAccounts",
          data: JSON.stringify({ error }),
        });
      }
    },
  });

  return {
    getExpenseAccounts,
  };
};
