import { PopupButton } from "@typeform/embed-react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";

import { AvatarMenu } from "components/AvatarMenu";
import { Button } from "design_system/Button";
import { Dropdown } from "design_system/Dropdown/DropdownV2";
import { IconButton } from "design_system/IconButton";
// COMPONENTS
import { Add, Menu, Search } from "design_system/Icons";
import { GlobalSearch } from "features/shared/GlobalSearch";
import { GlobalSearchModal } from "features/shared/GlobalSearch/components/GlobalSearchMobileView/GlobalSearchModal";
import { SwitchBusiness } from "../core/components";

// HOOKS
import { useCheckPermissionsV2 } from "hooks/useCheckPermissionsV2";
import { useMerchant } from "hooks/useMerchant";
import { useScreenDimension } from "hooks/useScreenDimension";

// OTHERS
import { colorsV2 } from "constants/colors";
import { setOpenMobileNavigation } from "../DashboardNavigation/core/reducer/dashboardNavigationSlice";
import { showCreateButtonOptions } from "./helpers";

// ASSETS
import MamoFavicon from "assets/logos/mamo-business-favicon.svg";

export const TopBarContainer = () => {
  const { merchantDetails, getMerchantDetails } = useMerchant();
  const { paymentLinks, invoices, subscriptions, createButton } =
    useCheckPermissionsV2(merchantDetails.currentUser.permissions);
  const { isDesktop } = useScreenDimension();
  const dispatch = useDispatch();

  const { currentMerchant, currentUser } = merchantDetails;
  const isMerchantVerified = currentMerchant.status === "verified";
  const signedUpForCardsOnly = currentMerchant.signedUpFor === "cards_only";

  const [showGlobalSearchModal, setShowGlobalSearchModal] =
    useState<boolean>(false);

  useEffect(() => {
    getMerchantDetails();
  }, []);

  return (
    <>
      {/* 
        NOTE: Order of scrolling stickiness 
        - NavigationRail: zIndex will be the highest [1003]
        - For newer non-dashboard layouts, zIndex will also be [1003] but will still be above the dashboard layout due to the createPortal and absolute positioning. Please refer files such as ExpenseDetailPageRoot, ChallengeDisputePage, BusinessContainer, or TeamContainer
        - Topbar: zIndex will be [1002] since it's the first element when scrolling vertically
        - PageHeader (aka <header> tag in MasterHeader): zIndex will be one level below (1001) because it's the second element in order
        - Tabs: zIndex will be the same (1000). However, top stickiness positioning can change because of the difference in layout (prop in ds component)

        Helmet is added here because there's a div wrapping around this component which is being rendered by RoR. Because of that, sticky cannot be added directly via Tailwind.
      */}
      <Helmet>
        <style type="text/css">
          {`
              [data-testid="topbar-root"], .topbar {
                position: fixed;
                width: 100%;
                top: 0;
                z-index: 1002;
              }
            `}
        </style>
      </Helmet>

      <header className="topbar flex h-56 items-center md:h-64">
        {/* 💻 Only tablet and above - Mamo icon area */}
        <div className="hidden h-fit-content w-80 flex-shrink-0 border-border-3 border-r border-b bg-surface-2 py-20 md:block">
          <div className="flex h-full items-center justify-center">
            <img
              src={MamoFavicon}
              alt="Mamo Business logo"
              className="h-24 w-24 rounded-8"
            />
          </div>
        </div>

        {/* 📱 💻 Business name (and switch business, if applicable), global search, refer business, create button, and avatar */}
        <section className="flex w-full items-center justify-between gap-24 border-border-3 border-b bg-surface-1 px-16 py-12 md:grid md:grid-cols-12 md:px-32 md:py-0 lg:px-40">
          {/* 📱 Only mobile - Hamburger menu and Search icon  */}
          <div className="flex items-center gap-12 md:hidden">
            <IconButton
              Icon={Menu}
              fill={colorsV2.text[2]}
              onClick={() => dispatch(setOpenMobileNavigation(true))}
            />

            <IconButton
              Icon={Search}
              fill={colorsV2.text[2]}
              onClick={() => setShowGlobalSearchModal(true)}
            />
            <GlobalSearchModal
              businessName={currentMerchant.name}
              show={showGlobalSearchModal}
              setShow={setShowGlobalSearchModal}
            />
          </div>

          {/* 💻 Only tablet and above - Business name (and switch business, if applicable) */}
          <div className="hidden md:col-span-3 md:block lg:col-span-4">
            <SwitchBusiness />
          </div>

          {/* 💻 Only tablet and above - Global search */}
          <div className="hidden md:col-span-6 md:block lg:col-span-5">
            <div className="w-full">
              <GlobalSearch
                textInputProps={{
                  placeholder: "Search",
                  size: "small",
                  sx: {
                    width: "100%",
                  },
                }}
              />
            </div>
          </div>

          {/* Refer business, create button and avatar */}
          <div className="flex gap-8 md:col-span-3 md:gap-16 md:justify-self-end">
            {/* Refer a business */}
            {isMerchantVerified && (
              <PopupButton
                id="hAR4sICr"
                size={80}
                hidden={{
                  user_identifier: currentUser.identifier,
                  first_name: currentUser.firstName,
                  last_name: currentUser.lastName,
                }}
              >
                <Button
                  variant="custom"
                  emoji="🎁"
                  size="md"
                  iconOnly={!isDesktop}
                  label="Get AED 800"
                  classes="custom-light-gradient-btn"
                />
              </PopupButton>
            )}

            {/* Create button */}
            {isMerchantVerified &&
              !createButton.hasNoAccess &&
              !signedUpForCardsOnly && (
                <Dropdown
                  type="button"
                  buttonProps={{
                    variant: "outlined",
                    size: "md",
                    iconOnly: true,
                    Icon: Add,
                  }}
                  showTooltipOnButton={false}
                  options={showCreateButtonOptions({
                    paymentLinks,
                    subscriptions,
                    invoices,
                  })}
                />
              )}

            {/* Avatar menu */}
            <AvatarMenu
              permissions={merchantDetails.currentUser.permissions}
              merchantDetails={merchantDetails}
            />
          </div>
        </section>
      </header>
    </>
  );
};
