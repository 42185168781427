import type { IEmptyStateProps } from "interfaces/IEmptyStateProps";

const EmptyState = ({
  imgSrc,
  imgAlt,
  title,
  info,
  isButton,
  buttonLabel,
  buttonCtaLink,
}: IEmptyStateProps) => {
  return (
    <div className="flex w-full flex-col items-center justify-center text-center md:p-40">
      {imgSrc && <img src={imgSrc} alt={imgAlt} className="h-80 w-80" />}
      <div className="empty-state__content" style={{ maxWidth: "581px" }}>
        <h3 className="label-large text-center">{title}</h3>
        {info && (
          <p className="body-medium text-center text-text-color-02">{info}</p>
        )}
        {isButton && (
          <a
            href={buttonCtaLink}
            className="empty-state__content__cta button button--primary button--solid button--sm"
          >
            {buttonLabel}
          </a>
        )}
      </div>
    </div>
  );
};

export default EmptyState;
