import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export const DonutLarge = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: IIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.294 22.0805C8.62078 21.8764 6.38274 20.8095 4.57984 18.8796C2.77694 16.9497 1.87549 14.6565 1.87549 12C1.87549 9.32682 2.77694 7.02845 4.57984 5.1049C6.38274 3.18133 8.62078 2.11557 11.294 1.9076V4.60107C9.37331 4.78042 7.76199 5.57409 6.45999 6.98207C5.15801 8.39004 4.50701 10.0627 4.50701 12C4.50701 13.9373 5.15801 15.6121 6.45999 17.0244C7.76199 18.4368 9.37331 19.2243 11.294 19.3869V22.0805ZM12.794 22.0805V19.3869C14.5686 19.2536 16.0777 18.5578 17.3214 17.2994C18.5651 16.0411 19.2889 14.5246 19.4929 12.75H22.1304C21.9431 15.2732 20.9709 17.4164 19.2141 19.1796C17.4572 20.9428 15.3171 21.9098 12.794 22.0805ZM19.4929 11.25C19.3095 9.47537 18.5909 7.95689 17.3369 6.69457C16.0829 5.43224 14.5686 4.72607 12.794 4.57607V1.8826C15.3045 2.06992 17.4403 3.04718 19.2016 4.8144C20.9628 6.58162 21.9391 8.72682 22.1304 11.25H19.4929Z"
        fill={fill}
      />
    </svg>
  );
};
