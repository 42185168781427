import axios, { type AxiosError } from "axios";
import isEmpty from "lodash/isEmpty";
import type { Location, NavigateFunction } from "react-router-dom";

import { stringifiedQueryParams } from "constants/queryParams";
import routes from "constants/routes";
import { SentryLoggingService } from "init/SentryLoggingService";
import type { AppDispatch } from "store";
import {
  BANK_INFO_PAGE,
  BUSINESS_DETAILS_PAGE,
  BUSINESS_OWNERS_PAGE,
  MEMORANDUM_PAGE,
  OTHER_DOCUMENTS_PAGE,
  PERSONAL_DETAILS_PAGE,
  PROOF_OF_ADDRESS_PAGE,
  REVENUE_INFO_PAGE,
  SPEND_INFO_PAGE,
  TRADE_LICENSE_PAGE,
} from "./constant";
import type { VerificationData } from "./typeDefs";
import { fetchAndGetPercentComplete } from "./verificationSlice";

/**
 * Sanitizes a string value by removing non-numeric characters (except for decimal points and hyphens)
 * and converts it to a floating-point number.
 * Returns null if the input value is empty.
 *
 * @param {string} value - The input value to be sanitized.
 * @returns {number|null} - The sanitized floating-point number or null if the input is empty.
 */
const sanitizeValue = (value: string | number) => {
  if (typeof value === "number") {
    return value;
  }

  return isEmpty(value)
    ? null
    : Number.parseFloat(value.replace(/[^\d.-]/g, ""));
};

/**
 * Converts a string value to a number.
 * Returns null if the input value is empty.
 *
 * @param {string} value - The input value to be converted.
 * @returns {number|null} - The converted number or null if the input is empty.
 */
const convertToNumber = (value: string) => {
  if (typeof value === "number") {
    return value;
  }

  return isEmpty(value) ? null : Number(value);
};

/**
 * Creates a payload object based on the current page and the provided verification data.
 *
 * @param {object} location - Location object from React Router.
 * @param {object} payload - The verification data object.
 * @returns {object} - The payload object for the current page.
 */
export const createPayload = (
  location: Location<any>,
  payload: VerificationData,
) => {
  const { personalDetails, businessInformation, bankInformation } = payload;
  const { businessDetails, revenueInformation, spendInformation } =
    businessInformation;

  // Determine the current page based on the pathname
  const isPersonalDetailsPage = location.pathname === PERSONAL_DETAILS_PAGE;
  const isBusinessDetailsPage = location.pathname === BUSINESS_DETAILS_PAGE;
  const isRevenueInfoPage = location.pathname === REVENUE_INFO_PAGE;
  const isSpendInfoPage = location.pathname === SPEND_INFO_PAGE;
  const isBankInfoPage = location.pathname === BANK_INFO_PAGE;

  // Create and return the payload based on the current page
  if (isPersonalDetailsPage) {
    // If on the personal details page, return the personal details payload
    return {
      first_name: personalDetails.firstName,
      last_name: personalDetails.lastName,
      phone_number: personalDetails.phoneNumber,
      onboarding_role: personalDetails.onboardingRole,
      email: personalDetails.email,
      acquisition_source: personalDetails.acquisitionSource,
      referral_code: personalDetails.referral,
    };
  } else if (isBusinessDetailsPage) {
    // If on the business details page, return the business details payload
    return {
      name: businessDetails.name,
      plan_to_use: businessDetails.planToUse,
      website_url: businessDetails.websiteUrl,
      industry_id: businessDetails.industryId,
      what_they_sell: businessDetails.whatTheySell,
      how_they_acquire: businessDetails.howTheyAcquire,
      notes: businessDetails.notes,
    };
  } else if (isRevenueInfoPage) {
    // If on the revenue info page, return the revenue information payload
    return {
      how_they_accept_payments: revenueInformation.howTheyAcceptPayments,
      annual_estimated_gmv: sanitizeValue(
        revenueInformation.annualEstimatedGmv,
      ),
      expected_share_of_wallet: convertToNumber(
        revenueInformation.expectedShareOfWallet,
      ),
      percent_uae_customers: convertToNumber(
        revenueInformation.percentUaeCustomers,
      ),
      average_transaction_amount: sanitizeValue(
        revenueInformation.averageTransactionAmount,
      ),
    };
  } else if (isSpendInfoPage) {
    // If on the spend info page, return the spend information payload
    return {
      plan_to_use_corporate_cards: spendInformation.planToUseCorporateCards,
      expect_number_of_cards: convertToNumber(
        spendInformation.expectNumberOfCards,
      ),
      expect_spend_on_cards_monthly: sanitizeValue(
        spendInformation.expectSpendOnCardsMonthly,
      ),
      expect_atm_cash_withdrawal_monthly: sanitizeValue(
        spendInformation.expectAtmCashWithdrawalMonthly,
      ),
      how_they_pay_expenses: spendInformation.howTheyPayExpenses,
    };
  } else if (isBankInfoPage) {
    // If on the bank info page, return the bank information payload
    return {
      iban: bankInformation.iban,
      account_holder_name: bankInformation.accountHolderName,
    };
  } else {
    // If on any other page, return an empty object
    return {};
  }
};

/**
 * Navigates to the previous step based on the current page and user sign-up details.
 * @param {string} signedUpFor - Type of signup (e.g., "payments_only", "cards_only").
 * @param {string} complianceType - Type of compliance (e.g., "individual").
 * @param {object} location - Location object from React Router.
 * @param {function} navigate - Navigation function from React Router.
 */
export const previousStep = (
  signedUpFor: SignedUpFor,
  complianceType: ComplianceType,
  location: Location<any>,
  navigate: NavigateFunction,
) => {
  // Define a mapping from each page to its previous page.
  const routeMap = {
    [BUSINESS_DETAILS_PAGE]: PERSONAL_DETAILS_PAGE,
    [REVENUE_INFO_PAGE]: BUSINESS_DETAILS_PAGE,
    [SPEND_INFO_PAGE]: REVENUE_INFO_PAGE,
    [TRADE_LICENSE_PAGE]: SPEND_INFO_PAGE,
    [MEMORANDUM_PAGE]: TRADE_LICENSE_PAGE,
    [PROOF_OF_ADDRESS_PAGE]: MEMORANDUM_PAGE,
    [OTHER_DOCUMENTS_PAGE]: PROOF_OF_ADDRESS_PAGE,
    [BANK_INFO_PAGE]: OTHER_DOCUMENTS_PAGE,
    [BUSINESS_OWNERS_PAGE]: BANK_INFO_PAGE,
  };

  // Determine the type of signup and compliance.
  const signedUpForPaymentsOnly = signedUpFor === "payments_only";
  const signedUpForCardsOnly = signedUpFor === "cards_only";
  const isFreelancer = complianceType === "individual";

  // Get the previous page based on the current pathname.
  let prevPage = routeMap[location.pathname];

  // For "payments_only" signup, if the previous page is SPEND_INFO_PAGE, skip it.
  if (signedUpForPaymentsOnly && prevPage === SPEND_INFO_PAGE) {
    prevPage = routeMap[prevPage];
  }

  // For "cards_only" signup, if the previous page is BANK_INFO_PAGE or REVENUE_INFO_PAGE, skip it.
  if (
    signedUpForCardsOnly &&
    (prevPage === BANK_INFO_PAGE || prevPage === REVENUE_INFO_PAGE)
  ) {
    prevPage = routeMap[prevPage];
  }

  // For freelancers, if the previous page is MEMORANDUM_PAGE, skip both MEMORANDUM_PAGE and TRADE_LICENSE_PAGE.
  if (isFreelancer && prevPage === MEMORANDUM_PAGE) {
    prevPage = routeMap[routeMap[prevPage]];
  }

  // For freelancers, if the previous page is SPEND_INFO_PAGE, skip it.
  if (isFreelancer && prevPage === SPEND_INFO_PAGE) {
    prevPage = routeMap[prevPage];
  }

  // Navigate to the previous page if it exists, otherwise navigate to the personal details page.
  if (prevPage) {
    navigate(prevPage);
  } else {
    navigate(PERSONAL_DETAILS_PAGE);
  }
};

/**
 * Navigates to the next step based on the current page and user sign-up details.
 * @param {string} signedUpFor - Type of signup (e.g., "payments_only", "cards_only").
 * @param {string} complianceType - Type of compliance (e.g., "individual").
 * @param {object} location - Location object from React Router.
 * @param {function} navigate - Navigation function from React Router.
 */
export const nextStep = (
  signedUpFor: SignedUpFor,
  complianceType: ComplianceType,
  location: Location<any>,
  navigate: NavigateFunction,
) => {
  // Define a mapping from each page to its next page.
  const routeMap = {
    [PERSONAL_DETAILS_PAGE]: BUSINESS_DETAILS_PAGE,
    [BUSINESS_DETAILS_PAGE]: REVENUE_INFO_PAGE,
    [REVENUE_INFO_PAGE]: SPEND_INFO_PAGE,
    [SPEND_INFO_PAGE]: TRADE_LICENSE_PAGE,
    [TRADE_LICENSE_PAGE]: MEMORANDUM_PAGE,
    [MEMORANDUM_PAGE]: PROOF_OF_ADDRESS_PAGE,
    [PROOF_OF_ADDRESS_PAGE]: OTHER_DOCUMENTS_PAGE,
    [OTHER_DOCUMENTS_PAGE]: BANK_INFO_PAGE,
  };

  // Determine the type of signup and compliance.
  const signedUpForPaymentsOnly = signedUpFor === "payments_only";
  const signedUpForCardsOnly = signedUpFor === "cards_only";
  const isFreelancer = complianceType === "individual";

  // Get the next page based on the current pathname.
  let nextPage = routeMap[location.pathname];

  // For "payments_only" signup, if the next page is SPEND_INFO_PAGE, skip it.
  if (signedUpForPaymentsOnly && nextPage === SPEND_INFO_PAGE) {
    nextPage = routeMap[nextPage];
  }

  // For "cards_only" signup, if the next page is REVENUE_INFO_PAGE or BANK_INFO_PAGE, skip it.
  if (
    signedUpForCardsOnly &&
    (nextPage === REVENUE_INFO_PAGE || nextPage === BANK_INFO_PAGE)
  ) {
    nextPage = routeMap[nextPage];
  }

  // For freelancers, if the next page is TRADE_LICENSE_PAGE, skip both TRADE_LICENSE_PAGE and MEMORANDUM_PAGE.
  if (isFreelancer && nextPage === TRADE_LICENSE_PAGE) {
    nextPage = routeMap[routeMap[nextPage]];
  }

  // Navigate to the next page if it exists, otherwise navigate to the business owners page.
  if (nextPage) {
    navigate(nextPage);
  } else {
    navigate(BUSINESS_OWNERS_PAGE);
  }
};

/**
 * Saves changes by sending a PATCH request to update user details and optionally navigates to the next step.
 *
 * @param {string} signedUpFor - Type of signup (e.g., "payments_only", "cards_only").
 * @param {string} complianceType - Type of compliance (e.g., "individual").
 * @param {object} payload - The data to be sent in the PATCH request.
 * @param {function} dispatch - Dispatch function from Redux.
 * @param {object} location - Location object from React Router.
 * @param {function} navigate - Navigation function from React Router.
 * @param {boolean} [continueToNextStep] - Optional flag to indicate if navigation to the next step should occur.
 */
export const saveChanges = async (
  signedUpFor: SignedUpFor,
  complianceType: ComplianceType,
  payload: any,
  dispatch: AppDispatch,
  location: Location<any>,
  navigate: NavigateFunction,
  continueToNextStep?: boolean,
) => {
  // Send a PATCH request to update user details
  await axios
    .patch(
      `${routes.MANAGE.ONBOARDING.UPDATE_DETAILS()}?${stringifiedQueryParams}`,
      payload,
    )
    .then(() => {
      // Dispatch an action to fetch and update the percent complete status
      dispatch(fetchAndGetPercentComplete());
    })
    .catch((error: AxiosError) => {
      const messages = error.response.data.messages;

      SentryLoggingService.captureException(error.message, {
        feature: "[Verification][Update verification details]",
        function: "saveChanges",
        file: "helpers.ts",
        data: payload ? JSON.stringify(payload) : null,
        errorDetails: messages ? JSON.stringify(messages) : null,
      });
    });

  // If continueToNextStep is true, navigate to the next step
  if (continueToNextStep) {
    nextStep(signedUpFor, complianceType, location, navigate);
  }
};
