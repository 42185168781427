import { AutoRenew, Link, ReceiptLong } from "design_system/Icons";

import { colorsV2 } from "constants/colors";
import routes from "constants/routes";
import type { ButtonMenuOptions } from "design_system/Dropdown/typeDefs";
import type { TPermissions } from "types/Permissions";
import { handleGoToCreateInvoice } from "utils/invoiceUtils";

interface ShowCreateButtonOptionsProps {
  paymentLinks: TPermissions;
  subscriptions: TPermissions;
  invoices: TPermissions;
}

const handleGoToCreatePaymentLink = () => {
  window.location.href = routes.MANAGE.LINKS.NEW();
};

const handleGoToCreateSubscription = () => {
  window.location.href = routes.MANAGE.SUBSCRIPTIONS.NEW();
};

export const showCreateButtonOptions = ({
  paymentLinks,
  subscriptions,
  invoices,
}: ShowCreateButtonOptionsProps): ButtonMenuOptions[] => {
  const options: ButtonMenuOptions[] = [];

  if (paymentLinks.hasWriteAccess) {
    const paymentLinkCreateOption = {
      label: (
        <div className="flex items-center gap-12">
          <Link size="18" fill={colorsV2.text[3]} />
          <p>Payment link</p>
        </div>
      ),
      onClick: () => handleGoToCreatePaymentLink(),
    };
    options.push(paymentLinkCreateOption);
  }

  if (subscriptions.hasWriteAccess) {
    const subscriptionCreateOption = {
      label: (
        <div className="flex items-center gap-12">
          <AutoRenew size="18" fill={colorsV2.text[3]} />
          <p>Recurring payment</p>
        </div>
      ),
      onClick: () => handleGoToCreateSubscription(),
    };
    options.push(subscriptionCreateOption);
  }

  if (invoices.hasWriteAccess) {
    const invoiceCreateOption = {
      label: (
        <div className="flex items-center gap-12">
          <ReceiptLong size="18" fill={colorsV2.text[3]} />
          <p>Invoice</p>
        </div>
      ),
      onClick: () => handleGoToCreateInvoice(),
    };
    options.push(invoiceCreateOption);
  }

  return options;
};
