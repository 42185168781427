import { useState } from "react";
import { cn } from "utils/classNames";

import LoaderBusiness from "components/Loader/LoaderBusiness";
import Toast from "components/ui/Toast";
import { Tabs } from "design_system/Tab";
import {
  AccountingIntegration,
  ExpenseAccounts,
  PaymentAccounts,
  TaxRates,
  Vendors,
} from "./Tabs";

import { useGetAccountingIntegrations } from "hooks/AccountingIntegrations/useGetAccountingIntegrations";
import { useGetExpenseAccounts } from "hooks/AccountingIntegrations/useGetExpenseAccounts";
import { useGetPaymentAccounts } from "hooks/AccountingIntegrations/useGetPaymentAccounts";
import { useGetTaxRates } from "hooks/AccountingIntegrations/useGetTaxRates";
import { useGetVendors } from "hooks/AccountingIntegrations/useGetVendors";
import { useScreenDimension } from "hooks/useScreenDimension";
import { useAppSelector } from "store";

import type { AccountingIntegrationTabs } from "./constants/typeDefs";

export const AccountingDashboard = () => {
  const tableSlice = useAppSelector((store) => store.table);

  const { getAccountingIntegrations, isLoadingIntegrations } =
    useGetAccountingIntegrations({ enabled: true });

  /**
   * Note:
   * data can be undefined when api loading is underway. This 'undefined' will not work as expected for enabling follow up apis
   * so we need to make sure we use a boolean here
   */
  const hasHealthyConnection =
    !!getAccountingIntegrations.data?.hasHealthyConnection;

  const { getExpenseAccounts } = useGetExpenseAccounts({
    enabled: hasHealthyConnection,
    page: tableSlice.expenseAccounts.page,
  });

  const { getPaymentAccounts } = useGetPaymentAccounts({
    enabled: hasHealthyConnection,
    page: tableSlice.paymentAccounts.page,
  });

  const { getTaxRates } = useGetTaxRates({
    enabled: hasHealthyConnection,
    page: tableSlice.taxRates.page,
  });

  const { getVendors } = useGetVendors({
    enabled: hasHealthyConnection,
    page: tableSlice.vendors.page,
  });

  const { isMobile } = useScreenDimension();

  const [currentTab, setCurrentTab] =
    useState<AccountingIntegrationTabs>("Integration");

  if (
    isLoadingIntegrations ||
    (hasHealthyConnection &&
      (getExpenseAccounts.isLoading ||
        getPaymentAccounts.isLoading ||
        getTaxRates.isLoading ||
        getVendors.isLoading))
  ) {
    return <LoaderBusiness />;
  }

  return (
    <>
      <Toast />
      <Tabs
        value={currentTab}
        onChange={(e, newTab) => setCurrentTab(newTab)}
        size={isMobile ? "md" : "lg"}
      >
        <Tabs.Tab value="Integration" label="Integration" />
        {hasHealthyConnection && (
          <Tabs.Tab value="Payment accounts" label="Payment accounts" />
        )}
        {hasHealthyConnection && (
          <Tabs.Tab value="Expense accounts" label="Expense accounts" />
        )}
        {hasHealthyConnection && <Tabs.Tab value="Vendors" label="Vendors" />}
        {hasHealthyConnection && (
          <Tabs.Tab value="Tax rates" label="Tax rates" />
        )}
      </Tabs>

      <section
        className={cn("mt-20 md:mt-24 md:px-40", {
          "px-16": currentTab === "Integration",
        })}
      >
        {currentTab === "Integration" && (
          <AccountingIntegration
            integrationsData={getAccountingIntegrations.data}
          />
        )}

        {hasHealthyConnection && currentTab === "Payment accounts" && (
          <PaymentAccounts accounts={getPaymentAccounts.data} />
        )}

        {hasHealthyConnection && currentTab === "Expense accounts" && (
          <ExpenseAccounts accounts={getExpenseAccounts.data} />
        )}

        {hasHealthyConnection && currentTab === "Vendors" && (
          <Vendors vendors={getVendors.data} />
        )}

        {hasHealthyConnection && currentTab === "Tax rates" && (
          <TaxRates taxRates={getTaxRates.data} />
        )}
      </section>
    </>
  );
};
