import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export const Table = ({ size, fill = colorsV2.text[1], ...rest }: IIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=table, Size=24">
        <path
          id="Vector"
          d="M2.85059 19.4462V4.55379C2.85059 4.07968 3.01634 3.67628 3.34784 3.34359C3.67935 3.01089 4.08135 2.84454 4.55384 2.84454H19.4462C19.9204 2.84454 20.3238 3.01089 20.6564 3.34359C20.9891 3.67628 21.1555 4.07968 21.1555 4.55379V19.4462C21.1555 19.9187 20.9891 20.3207 20.6564 20.6522C20.3238 20.9837 19.9204 21.1494 19.4462 21.1494H4.55384C4.08135 21.1494 3.67935 20.9837 3.34784 20.6522C3.01634 20.3207 2.85059 19.9187 2.85059 19.4462ZM4.55384 8.42227H19.4462V4.55379H4.55384V8.42227ZM10.018 13.9342H13.9821V9.92227H10.018V13.9342ZM10.018 19.4462H13.9821V15.4342H10.018V19.4462ZM4.55384 13.9342H8.51796V9.92227H4.55384V13.9342ZM15.4821 13.9342H19.4462V9.92227H15.4821V13.9342ZM4.55384 19.4462H8.51796V15.4342H4.55384V19.4462ZM15.4821 19.4462H19.4462V15.4342H15.4821V19.4462Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
