import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export const BarChart = ({ size, fill = colorsV2.text[1], ...rest }: IIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=bar_chart, Size=24">
        <path
          id="Vector"
          d="M18.3315 20.1494C17.8283 20.1494 17.3993 19.9731 17.0444 19.6204C16.6894 19.2678 16.512 18.8394 16.512 18.3353V14.7554C16.512 14.2514 16.6905 13.8218 17.0474 13.4668C17.4043 13.1118 17.8348 12.9342 18.3387 12.9342C18.8426 12.9342 19.2714 13.1118 19.625 13.4668C19.9787 13.8218 20.1555 14.2514 20.1555 14.7554V18.3353C20.1555 18.8394 19.9777 19.2678 19.6221 19.6204C19.2665 19.9731 18.8363 20.1494 18.3315 20.1494ZM11.9978 20.1494C11.496 20.1494 11.0683 19.9721 10.7147 19.6174C10.3611 19.2628 10.1843 18.8343 10.1843 18.332V5.66794C10.1843 5.16566 10.3608 4.73619 10.7139 4.37954C11.067 4.02288 11.4965 3.84454 12.0023 3.84454C12.504 3.84454 12.9317 4.02311 13.2854 4.38024C13.639 4.73738 13.8158 5.16606 13.8158 5.66629V18.3319C13.8158 18.8343 13.6392 19.2628 13.2861 19.6174C12.933 19.9721 12.5036 20.1494 11.9978 20.1494ZM5.66736 20.1494C5.16341 20.1494 4.73463 19.9731 4.38101 19.6204C4.02739 19.2678 3.85059 18.8392 3.85059 18.3347V10.6672C3.85059 10.1627 4.02814 9.73284 4.38324 9.37752C4.73832 9.0222 5.16784 8.84454 5.67179 8.84454C6.17572 8.84454 6.60442 9.0222 6.95789 9.37752C7.31135 9.73284 7.48809 10.1627 7.48809 10.6672V18.3347C7.48809 18.8392 7.31061 19.2678 6.95566 19.6204C6.60073 19.9731 6.17129 20.1494 5.66736 20.1494Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
