import { type QueryObserverResult, useQuery } from "@tanstack/react-query";
import axios from "axios";
import get from "lodash/get";

import { endpoints } from "constants/apiEndpoints";
import { stringifiedQueryParams } from "constants/queryParams";
import { SentryLoggingService } from "init/SentryLoggingService";

export const useGetUsersAccounts = () => {
  const getUsersAccounts: QueryObserverResult<UsersAccount[], Error> = useQuery(
    {
      queryKey: ["getUsersAccounts"],
      queryFn: async () => {
        try {
          const url = endpoints.MANAGE.API.ACCOUNTS.INDEX;
          const response = await axios.get(`${url}?${stringifiedQueryParams}`);
          const usersAccounts: UsersAccount[] = get(response, "data.data", []);

          return usersAccounts;
        } catch (error: any) {
          const messages = error.response.data.messages;

          SentryLoggingService.captureException(error.message, {
            feature: "[Global][Get users accounts]",
            file: "useGetUsersAccounts.ts",
            errorDetails: messages ? JSON.stringify(messages) : null,
            function: "useGetUsersAccounts",
          });
        }
      },
    },
  );

  return {
    getUsersAccounts,
  };
};
