import { useState } from "react";
import { useDispatch } from "react-redux";

import { Button } from "design_system/Button";
import { Modal } from "design_system/Modal";
import { CategoriesInputField } from "./CategoriesInputField";

import { useScreenDimension } from "hooks/useScreenDimension";
import { useAddExpenseCategories } from "../../../hooks/useAddExpenseCategories";
import { useValidateExpenseCategory } from "../../../hooks/useValidateExpenseCategory";

import { generalErrors } from "constants/errorMessages";
import { snack } from "design_system/Snackbar";
import { SentryLoggingService } from "init/SentryLoggingService";
import { showHideAddCategoryModal } from "../../../../reducer/expenseSettingsSlice";

export const AddCategoryModal = ({ show }) => {
  /* ---------------------------------
   *  HOOKS
   * ---------------------------------
   */

  const { isMobile } = useScreenDimension();
  const dispatch = useDispatch();

  const { addExpenseCategories } = useAddExpenseCategories();
  const { validateExpenseCategory } = useValidateExpenseCategory();
  /* ---------------------------------
   *  STATES
   * ---------------------------------
   */

  const [categories, setCategories] = useState<any>([]);

  /* ---------------------------------
   *  CONSTANTS
   * ---------------------------------
   */
  const invalidCategoriesCount =
    categories.filter((category) => !category.isValid)?.length ?? 0;
  const hasInvalidCategories = invalidCategoriesCount > 0 ?? false;

  const isDisabled =
    addExpenseCategories.isLoading ||
    validateExpenseCategory.isLoading ||
    categories?.length === 0 ||
    hasInvalidCategories;
  /* ---------------------------------
   *  HELPERS
   * ---------------------------------
   */
  const handleCloseModal = () => {
    dispatch(
      showHideAddCategoryModal({
        showOrHideModal: "hide",
      }),
    );
  };

  const onSave = async () => {
    try {
      const categoryNames = categories.flatMap((c) => c.name);

      await addExpenseCategories.mutateAsync(categoryNames);
      snack({
        title: "New categories added successfully",
        leadingElement: "icon",
        variant: "success",
      });

      dispatch(showHideAddCategoryModal({ showOrHideModal: "hide" }));
    } catch (error: any) {
      snack({
        title: generalErrors.SOMETHING_WENT_WRONG,
        leadingElement: "icon",
        variant: "critical",
      });

      SentryLoggingService.captureException(error.message, {
        feature: "[Expense Settings][addExpenseCategories]",
        function: "addExpenseCategories",
        file: "useAddExpenseCategories.tsx",
        data: JSON.stringify({ categories }),
      });
    }
  };

  return (
    <>
      <Modal
        show={show}
        modalWidth="48rem"
        hasCloseButton={isMobile}
        onClose={handleCloseModal}
      >
        <div className="space-y-32 md:space-y-40">
          <section className="text-center md:space-y-16">
            <h1 className="header-medium">Add a category</h1>
            <p className="body-medium w-11/12">
              Create new expense categories. You can add one or more categories.
            </p>
          </section>

          <section>
            <CategoriesInputField
              categories={categories}
              invalidCategoriesCount={invalidCategoriesCount}
              setCategories={setCategories}
              validateExpenseCategory={validateExpenseCategory}
            />
          </section>

          <section className="flex items-center gap-16">
            <Button
              label="Save"
              variant="filled"
              size="lg"
              classes="w-full"
              disabled={isDisabled}
              onClick={onSave}
            />
          </section>
        </div>
      </Modal>
    </>
  );
};
