import { useDispatch } from "react-redux";

import { TableCell } from "components/Table/TableCell";

import { setPagination } from "reducers/redux/TableReducer/tableReducer";

type TColumnCell = {
  row: {
    original: any;
  };
};
export const useVendorsTable = () => {
  const dispatch = useDispatch();

  const COLUMNS = [
    {
      header: "Vendor name",
      accessorKey: "name",
      cell: (props: TColumnCell) => (
        <TableCell mainContent={props.row.original.name} />
      ),
    },
    {
      header: "Currency",
      accessorKey: "currency",
      cell: (props: TColumnCell) => {
        return <TableCell mainContent={props.row.original.currency} />;
      },
    },
  ];

  const handleSetPage = (newPageNumber: number) => {
    dispatch(setPagination({ tableName: "vendors", page: newPageNumber }));
  };

  return {
    COLUMNS,
    handleSetPage,
  };
};
