import { type ModalProps, Modal as MuiModal } from "@mui/material";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { isFunction } from "lodash";
import { cn } from "utils/classNames";

import { IconButton } from "design_system/IconButton";
import { ChevronLeft, Close } from "design_system/Icons";

import { useScreenDimension } from "hooks/useScreenDimension";
import { customTheme } from "../shared/theme";

export interface IModalProps extends Omit<ModalProps, "open" | "onClose"> {
  dynamicHeight?: boolean;
  hasBackButton?: boolean;
  hasCloseButton?: boolean;
  modalWidth?: string;
  show: boolean;
  title?: string;
  onBack?: () => void;
  onClose: () => void;
}

export const Modal = ({
  dynamicHeight,
  hasBackButton,
  hasCloseButton,
  modalWidth = "60rem",
  show,
  title,
  onBack,
  onClose,
  ...props
}: IModalProps) => {
  const { isMobile } = useScreenDimension();
  const outerTheme = useTheme();

  let modalHeight;
  if (isMobile) {
    modalHeight = "100%";
  } else {
    modalHeight = dynamicHeight ? "calc(100% - 20%)" : "fit-content";
  }

  const modalStyle = {
    height: modalHeight,
    maxHeight: isMobile ? "unset" : "calc(100% - 48px)",
    maxWidth: isMobile ? "100%" : modalWidth,
    width: isMobile ? "100%" : modalWidth,
  };

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <MuiModal open={show} onClose={onClose} className="BlazeModal" {...props}>
        <div
          className="w-full overflow-auto bg-surface-1 md:w-auto md:rounded-16 md:shadow-lg"
          style={modalStyle}
        >
          {(title || hasBackButton || hasCloseButton) && (
            <header className="flex items-center justify-between gap-16 p-16 md:p-24">
              <IconButton
                Icon={ChevronLeft}
                classes={cn({
                  "pointer-events-none cursor-none opacity-0": !hasBackButton,
                  "cursor-pointer opacity-100": hasBackButton,
                })}
                onClick={() => {
                  if (isFunction(onBack)) {
                    onBack();
                  } else {
                    onClose();
                  }
                }}
              />
              {title ? (
                <p className="header-small flex-grow-1 text-center">{title}</p>
              ) : (
                <div></div>
              )}
              <IconButton
                Icon={Close}
                classes={cn({
                  "pointer-events-none cursor-none opacity-0": !hasCloseButton,
                  "cursor-pointer opacity-100": hasCloseButton,
                })}
                onClick={onClose}
              />
            </header>
          )}
          <div
            className={cn("mt-40 px-16 pb-16 md:mt-0 md:px-40 md:pb-40", {
              "pt:16 md:pt-40": !title && !hasCloseButton && !hasBackButton,
            })}
          >
            {props.children}
          </div>
        </div>
      </MuiModal>
    </ThemeProvider>
  );
};
