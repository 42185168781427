import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export const GridView = ({ size, fill = colorsV2.text[1], ...rest }: IIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=grid_view, Size=24">
        <path
          id="Vector"
          d="M4.55384 11.25C4.08544 11.25 3.68446 11.0832 3.35091 10.7495C3.01736 10.4159 2.85059 10.0129 2.85059 9.54075V4.5538C2.85059 4.08377 3.01736 3.68139 3.35091 3.34665C3.68446 3.01192 4.08544 2.84455 4.55384 2.84455H9.54676C10.0152 2.84455 10.4161 3.01192 10.7497 3.34665C11.0833 3.68139 11.25 4.08377 11.25 4.5538V9.54075C11.25 10.0129 11.0833 10.4159 10.7497 10.7495C10.4161 11.0832 10.0152 11.25 9.54676 11.25H4.55384ZM4.55384 21.1495C4.08544 21.1495 3.68446 20.9827 3.35091 20.6491C3.01736 20.3156 2.85059 19.9146 2.85059 19.4462V14.4533C2.85059 13.9849 3.01736 13.5839 3.35091 13.2503C3.68446 12.9168 4.08544 12.75 4.55384 12.75H9.54676C10.0152 12.75 10.4161 12.9168 10.7497 13.2503C11.0833 13.5839 11.25 13.9849 11.25 14.4533V19.4462C11.25 19.9146 11.0833 20.3156 10.7497 20.6491C10.4161 20.9827 10.0152 21.1495 9.54676 21.1495H4.55384ZM14.4593 11.25C13.9871 11.25 13.5842 11.0832 13.2505 10.7495C12.9169 10.4159 12.75 10.0129 12.75 9.54075V4.5538C12.75 4.08377 12.9169 3.68139 13.2505 3.34665C13.5842 3.01192 13.9871 2.84455 14.4593 2.84455H19.4462C19.9163 2.84455 20.3187 3.01192 20.6534 3.34665C20.9881 3.68139 21.1555 4.08377 21.1555 4.5538V9.54075C21.1555 10.0129 20.9881 10.4159 20.6534 10.7495C20.3187 11.0832 19.9163 11.25 19.4462 11.25H14.4593ZM14.4593 21.1495C13.9871 21.1495 13.5842 20.9827 13.2505 20.6491C12.9169 20.3156 12.75 19.9146 12.75 19.4462V14.4533C12.75 13.9849 12.9169 13.5839 13.2505 13.2503C13.5842 12.9168 13.9871 12.75 14.4593 12.75H19.4462C19.9163 12.75 20.3187 12.9168 20.6534 13.2503C20.9881 13.5839 21.1555 13.9849 21.1555 14.4533V19.4462C21.1555 19.9146 20.9881 20.3156 20.6534 20.6491C20.3187 20.9827 19.9163 21.1495 19.4462 21.1495H14.4593ZM4.55384 9.54075H9.54676V4.5538H4.55384V9.54075ZM14.4593 9.54075H19.4462V4.5538H14.4593V9.54075ZM14.4593 19.4462H19.4462V14.4533H14.4593V19.4462ZM4.55384 19.4462H9.54676V14.4533H4.55384V19.4462Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
