import { useMemo } from "react";
import { cn } from "utils/classNames";

import { Divider } from "components/Divider";
import { TableRecord } from "components/Table/TableRecord";
import Table from "components/Table/V2/TableV2";
import { TableEmptyState } from "./TableEmptyState";

import { useScreenDimension } from "hooks/useScreenDimension";
import { useVendorsTable } from "./useVendorsTable";

type VendorsTableProps = {
  vendors?: VendorsWithPagination;
};

export const VendorsTable = ({ vendors }: VendorsTableProps) => {
  const { isMobile } = useScreenDimension();

  const { COLUMNS, handleSetPage } = useVendorsTable();

  const paginationMeta = {
    ...vendors.paginationMeta,
    itemName: "Vendors",
  };

  const columns = useMemo(() => COLUMNS, []);
  const tableData = useMemo(() => vendors.data, [vendors.data]);
  const isEmpty = !vendors || vendors.data.length === 0;

  if (!isMobile) {
    return (
      <div className="flex w-full justify-center">
        <Table
          name="vendors-table"
          // @ts-ignore
          columns={columns}
          data={tableData}
          paginationMeta={paginationMeta}
          onSetPage={handleSetPage}
          tableClass="table"
          headerRowClass="bg-black-25"
          headerCellClass="w-auto"
          rowProps={{
            selectable: false,
            selectedRows: [],
            setSelectedRows: () => {},
          }}
          emptyState={<TableEmptyState />}
        />
      </div>
    );
  }
  return isEmpty ? (
    <TableEmptyState />
  ) : (
    <>
      {vendors.data.map((vendor: VendorOption, index) => {
        return (
          <>
            <TableRecord
              key={vendor.name + index}
              variant="noAvatar"
              className={cn({ "-mt-16": index === 0 })}
            >
              <TableRecord.Column1 label={vendor.name} />
              <TableRecord.Column2 label={vendor.currency} />
            </TableRecord>
            <Divider />
          </>
        );
      })}
    </>
  );
};
