import moment from "moment";
import { cn } from "utils/classNames";

interface IProps {
  hasCustomBranding?: boolean;
  iconStyles?: object;
  referenceInfo?: string;
  createdAt?: string | undefined;
  text?: string;
  title?: string;
}

const PaymentInfo = ({
  hasCustomBranding,
  iconStyles,
  referenceInfo,
  createdAt,
  text,
  title,
}: IProps) => {
  const formattedDateTime = moment(createdAt).format(
    "MMMM DD, YYYY [at] h:mm A",
  );

  return (
    <div className="flex flex-col items-center gap-24 rounded-24 bg-white px-24 py-40 shadow-md md:rounded-32">
      <span
        className="material-icons-round flex h-56 w-56 justify-center rounded-full bg-green-300 p-16 align-center text-24"
        style={iconStyles}
      >
        check
      </span>

      <div className="space-y-12 text-center">
        <h2 data-testid="payment-info-title">{title}</h2>
        <p className="text-16 text-black-300">{text}</p>
      </div>

      <div
        className={cn("w-full rounded-12 p-12 text-center text-16", {
          "border border-black-50 border-solid text-black-300":
            hasCustomBranding,
          "bg-blue-25 text-blue-500": !hasCustomBranding,
        })}
      >
        <p>{referenceInfo}</p>
      </div>

      <p className="text-14 text-black-300">{formattedDateTime}</p>
    </div>
  );
};

export default PaymentInfo;
