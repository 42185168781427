import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { useResendInviteMail } from "../../shared/hooks/useResendInviteMail";

import { UserContext } from "../../shared/context/UserProvider";

import type { ButtonMenuOptions } from "design_system/Dropdown/typeDefs";
import { snack } from "design_system/Snackbar";
import { teamRoutes } from "../../shared/constant";

export const useUserOptions = () => {
  const { setUserInfo, setShowRemoveUserModal } = useContext(UserContext);
  const { resendInviteMail } = useResendInviteMail();
  const navigate = useNavigate();

  const handleEditPermissions = (teamMember: TeamMember) => {
    setUserInfo(teamMember);
    navigate(teamRoutes.userRoles);
  };

  const handleShowRemoveUserModal = (teamMember: TeamMember) => {
    setUserInfo(teamMember);
    setShowRemoveUserModal(true);
  };

  const handleResendInviteEmail = (teamMember: TeamMember) => {
    const { email, identifier } = teamMember;

    resendInviteMail.mutateAsync(identifier).then(() => {
      snack({
        title: `Invitation resent to ${email}`,
        leadingElement: "icon",
        variant: "success",
      });
    });
  };

  const getOptions = (teamMember: TeamMember) => {
    const { isPending } = teamMember;

    const options: ButtonMenuOptions[] = [
      {
        label: "Edit permissions",
        onClick: () => handleEditPermissions(teamMember),
      },
      {
        label: <p className="text-error-1">Remove user</p>,
        onClick: () => handleShowRemoveUserModal(teamMember),
      },
    ];

    if (isPending) {
      options.splice(1, 0, {
        label: "Resend invite",
        onClick: () => handleResendInviteEmail(teamMember),
      });
    }

    return options;
  };

  return {
    getOptions,
  };
};
