import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import toast from "react-hot-toast";

import { endpoints } from "constants/apiEndpoints";
import { generalErrors } from "constants/errorMessages";
import { SentryLoggingService } from "init/SentryLoggingService";
import type {
  WalletsAndCardsApiResponse,
  WalletsAndCardsData,
} from "../typeDefs";

export const useGetWalletsAndCards = () => {
  const getWalletsAndCards: WalletsAndCardsApiResponse = useQuery({
    queryKey: ["getWalletsAndCards"],
    queryFn: async () => {
      try {
        const {
          data: { data },
        } = await axios.get(endpoints.MANAGE.API.WALLETS.INDEX);
        return data as WalletsAndCardsData;
      } catch (error: any) {
        toast.error(generalErrors.SOMETHING_WENT_WRONG, {
          duration: 3000,
        });
        SentryLoggingService.captureException(error.message, {
          feature: "[Wallets V2]",
          file: "useGetWalletsAndCards.tsx",
          function: "useGetWalletsAndCards",
        });
        return undefined;
      }
    },
  });

  return {
    getWalletsAndCards,
  };
};
