import axios from "axios";
import { useState } from "react";

import { endpoints } from "constants/apiEndpoints";

export const useGetRoles = () => {
  const [roles, setRoles] = useState<TeamMemberRole[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getAllRoles = async () => {
    setLoading(true);

    try {
      const url = endpoints.MANAGE.API.SETTINGS.TEAM.ROLES;
      const {
        data: { data },
      } = await axios.get(url);
      setRoles(data);
    } catch (error) {
      console.error(
        `[useGetRoles][getAllRoles] Trouble getting roles: ${error}`,
      );
    } finally {
      setLoading(false);
    }
  };

  return {
    roles,
    loading,
    getAllRoles,
  };
};
