import axios from "axios";
import qs from "qs";
import ReactOnRails from "react-on-rails";
import { useNavigate } from "react-router-dom";
import { cn } from "utils/classNames";

import { FadeIn } from "components/AnimatedElements/FadeIn";
import Button from "components/Button/Button";

import routes from "constants/routes";
import { isAppPlatform } from "helpers/isAppPlatform";
import { getSettlementOptions } from "../Step2/constant";
import { plansList } from "../constant";

const Step3 = ({ state }: any) => {
  const navigate = useNavigate();
  const { selectedPlan, settlementOption, activePlan } = state;
  const settlementOptions = getSettlementOptions(selectedPlan);

  const handleReturnPlan = () => {
    if (selectedPlan) {
      return selectedPlan;
    } else {
      return activePlan.name;
    }
  };

  const calculateVAT = (amount: any) => {
    const amountNumber = amount.replace("AED ", "");
    const vatAmount = 0.05 * amountNumber;
    return +vatAmount.toFixed(2);
  };

  const calculateTotal = (amount: any) => {
    const amountNumber = amount.replace("AED ", "");
    return calculateVAT(amount) + +amountNumber;
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleNextStep = async () => {
    const queryParams = {
      authenticity_token: ReactOnRails.authenticityToken(),
    };

    axios
      .patch(
        `${routes.MANAGE.PLANS.UPGRADE()}?${qs.stringify(queryParams)}`,
        {
          settlement_period: Number.parseInt(settlementOption),
          plan_name: selectedPlan.toLowerCase(),
        },
        {
          headers: { accept: "application/json" },
        },
      )
      .then((_res) => {
        navigate(routes.MANAGE.PLANS.SUCCESS());
      })
      .catch((error) => {
        // TODO: Toast here
        console.error(error);
      });
  };

  return (
    <>
      {/* HEADER WITH CLOSE AND BACK BUTTONS */}
      <header className="choose-plan__header">
        <FadeIn custom={0}>
          <h1>
            Confirm {selectedPlan ? selectedPlan : "your settlement period"}
          </h1>
          {selectedPlan && (
            <p>Get started and reap all the {selectedPlan} benefits!</p>
          )}
        </FadeIn>

        <button
          className="choose-plan__back-button md:bg-black-25"
          onClick={handleGoBack}
        >
          <i className="material-icons-round">arrow_back</i>
        </button>
        {!isAppPlatform() && (
          <a
            className="choose-plan__close-button md:bg-black-25"
            href={routes.MANAGE.ACCOUNT_SETTINGS.PLAN()}
          >
            <i className="material-icons-round">close</i>
          </a>
        )}
      </header>

      <FadeIn custom={1}>
        <section className="choose-plan__confirm space-y-32">
          <div className="space-y-18">
            {plansList.map(
              (item) =>
                handleReturnPlan() === item.name && (
                  <div className="space-y-18">
                    {/* PLAN INFO */}
                    <div
                      className="rounded-16 p-24 text-18"
                      style={{ border: "1px solid #E5E9F0" }}
                    >
                      <>
                        <div className="flex justify-between font-medium">
                          <p>{item.name} plan</p>
                          <p>{item.amount}</p>
                        </div>

                        <div className="space-y-16">
                          <div className="mt-24 flex justify-between">
                            <p>VAT</p>
                            <p>AED {calculateVAT(item.amount)}</p>
                          </div>
                          <div className="flex justify-between">
                            <p>Total</p>
                            <p className="font-medium text-blue-500">
                              AED {calculateTotal(item.amount)}/month
                            </p>
                          </div>

                          {handleReturnPlan() !== "Growth" && (
                            <div className="text-14 text-black-300">
                              <p>
                                Your subscription will automatically renew each
                                month by deducting the fee from your settlement
                                balance. You may change pricing plans any time.
                              </p>
                            </div>
                          )}
                        </div>
                      </>
                    </div>

                    {/* TRANSACTION FEES INFO */}
                    <div
                      className="rounded-16 p-24 text-18"
                      style={{ border: "1px solid #E5E9F0" }}
                    >
                      <>
                        <div className="space-y-4">
                          <p className="font-medium">Transaction fee</p>
                          <p className="text-14 text-black-300">
                            Card transaction fees apply to every transaction
                          </p>
                        </div>

                        <div className="space-y-16">
                          <div className="mt-24 flex justify-between">
                            <p>UAE cards</p>
                            <p className="font-medium text-blue-500">
                              {item.fees.uae} + {item.fees.fixed}
                            </p>
                          </div>
                          <div className="flex justify-between">
                            <p>International cards</p>
                            <p className="font-medium text-blue-500">
                              {item.fees.intl} + {item.fees.fixed}
                            </p>
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                ),
            )}

            {/* SETTLEMENT PERIOD INFO */}
            {settlementOptions.map(
              (item) =>
                settlementOption === item.settlementPeriod.toString() && (
                  <div
                    className="rounded-16 p-24 text-18"
                    style={{ border: "1px solid #E5E9F0" }}
                  >
                    <>
                      <div className="font-medium">
                        <p>Settlement period</p>
                      </div>

                      <div className="mt-24 flex justify-between">
                        <p>{item.text}</p>
                        <div className="space-y-4">
                          <p
                            className={cn("font-medium text-blue-500", {
                              "text-green-400": item.cost === "Free",
                            })}
                          >
                            {!item.fee && item.cost}
                            {item.cost && (
                              <>
                                {item.cost !== "Free" && (
                                  <span>/settlement</span>
                                )}
                              </>
                            )}
                          </p>
                          {item.fee && (
                            <p
                              className={cn("text-right", {
                                "font-medium text-blue-500": !item.cost,
                                "text-12 text-black-300": item.cost,
                              })}
                            >
                              +{item.fee} transaction fee
                            </p>
                          )}
                        </div>
                      </div>
                    </>
                  </div>
                ),
            )}
          </div>

          <p className="text-center text-14 text-black-300">
            By continuing, you agree to our terms and conditions.
            <a
              href="https://www.mamopay.com/legal/terms-business"
              target="_blank"
              rel="noopener noreferrer"
              className="font-medium text-blue-500"
            >
              {" "}
              Learn more.
            </a>
          </p>

          <Button
            color="primary"
            size="lg"
            label={!selectedPlan ? "Confirm changes" : "Confirm"}
            onClick={handleNextStep}
          />
        </section>
      </FadeIn>
    </>
  );
};

export default Step3;
