import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export const Assignment = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: IIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M4.55384 21.1494C4.08544 21.1494 3.68446 20.9827 3.35091 20.6491C3.01736 20.3156 2.85059 19.9146 2.85059 19.4462V4.55379C2.85059 4.08376 3.01736 3.68138 3.35091 3.34664C3.68446 3.01191 4.08544 2.84454 4.55384 2.84454H9.52939C9.63664 2.26121 9.92124 1.78204 10.3832 1.40704C10.8451 1.03204 11.3841 0.844543 12 0.844543C12.616 0.844543 13.1549 1.03204 13.6169 1.40704C14.0788 1.78204 14.3634 2.26121 14.4707 2.84454H19.4462C19.9163 2.84454 20.3187 3.01191 20.6534 3.34664C20.9881 3.68138 21.1555 4.08376 21.1555 4.55379V19.4462C21.1555 19.9146 20.9881 20.3156 20.6534 20.6491C20.3187 20.9827 19.9163 21.1494 19.4462 21.1494H4.55384ZM4.55384 19.4462H19.4462V4.55379H4.55384V19.4462ZM7.05384 16.9462H13.8131V15.4462H7.05384V16.9462ZM7.05384 12.75H16.9462V11.25H7.05384V12.75ZM7.05384 8.55379H16.9462V7.05379H7.05384V8.55379ZM12 4.11087C12.2472 4.11087 12.4608 4.02084 12.6408 3.84079C12.8209 3.66074 12.9109 3.44714 12.9109 3.19999C12.9109 2.95284 12.8209 2.73924 12.6408 2.55919C12.4608 2.37914 12.2472 2.28912 12 2.28912C11.7529 2.28912 11.5393 2.37914 11.3592 2.55919C11.1792 2.73924 11.0892 2.95284 11.0892 3.19999C11.0892 3.44714 11.1792 3.66074 11.3592 3.84079C11.5393 4.02084 11.7529 4.11087 12 4.11087Z"
        fill={fill}
      />
    </svg>
  );
};
