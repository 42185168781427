import axios, { type AxiosAdapter } from "axios";
import routes from "constants/routes";

import {
  cacheAdapterEnhancer,
  throttleAdapterEnhancer,
} from "axios-extensions";

axios.create({
  adapter: throttleAdapterEnhancer(
    cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
      cacheFlag: "useCache",
    }),
  ),
});

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // If API returns 403 status code, redirect users to forbidden page
    if (error.response.status === 403) {
      window.location.href = routes.MANAGE.ERRORS.FORBIDDEN();
    }

    if (error.response.status === 401) {
      window.location.href = routes.MANAGE.SESSIONS.LOGIN();
    }

    return Promise.reject(error);
  },
);

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept"] = "application/json";
