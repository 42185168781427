import { InputAdornment } from "@mui/material";

import { Search } from "design_system/Icons";
import { TextInput } from "design_system/Inputs/TextInput";
import { EndAdornment } from "../EndAdornment";

import { colorsV2 } from "constants/colors";

export const SearchInput = ({ isLoading, onClose, hasText, ...restProps }) => {
  return (
    <TextInput
      color="secondary"
      variant="outlined"
      fullWidth
      size="small"
      {...restProps}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search size="18" fill={colorsV2.text[2]} />
          </InputAdornment>
        ),
        endAdornment: hasText ? (
          <EndAdornment
            searchText={restProps.value}
            isLoading={isLoading}
            onClose={onClose}
          />
        ) : null,
      }}
      className="BlazeSearchInput"
    />
  );
};
