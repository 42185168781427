import type { TPlanFeatures } from "./types";

export const plansList = [
  {
    id: "growth",
    name: "Growth",
    amount: "AED 0",
    fees: {
      uae: "2.9%",
      intl: "3.4%",
      fixed: "AED 1",
    },
  },
  {
    id: "premium",
    name: "Premium",
    amount: "AED 149",
    fees: {
      uae: "2.7%",
      intl: "3.2%",
      fixed: "AED 0.8",
    },
  },
  {
    id: "ultra",
    name: "Ultra",
    amount: "AED 399",
    fees: {
      uae: "1.9%",
      intl: "2.4%",
      fixed: "AED 0.8",
    },
  },
];

export const allFeatures: TPlanFeatures[] = [
  {
    id: "receiving-payments",
    title: "Receiving payments",
    list: [
      {
        feature: {
          text: "Transaction fee on UAE cards",
          growth: {
            text: "2.9% + AED 1",
          },
          premium: {
            text: "2.7% + AED 0.80",
          },
          ultra: {
            text: "1.9% + AED 0.80",
            tooltip:
              "Spend with Mamo Card and get 1.9% + AED 0.80 transaction fees on payments equivalent to your total card spend. Payments that exceed this amount will incur 2.7% + AED 0.80 transaction fees.",
            subtext: "Up to card spend",
          },
        },
      },
      {
        feature: {
          text: "Transaction fee on international cards",
          growth: {
            text: "+ 0.5%",
          },
          premium: {
            text: "+ 0.5%",
          },
          ultra: {
            text: "+ 0.5%",
          },
        },
      },
      {
        feature: {
          text: "Receive payments in other currencies",
          growth: {
            text: "+ 2%",
          },
          premium: {
            text: "+ 1.5%",
          },
          ultra: {
            text: "+ 1.4%",
          },
        },
      },
    ],
  },
  {
    id: "spend-management",
    title: "Spend management",
    list: [
      {
        feature: {
          text: "Corporate cards",
          growth: {
            text: "1 free card",
            subtext: "+ AED 19/month per card",
          },
          premium: {
            text: "3 free cards",
            subtext: "+ AED 9/month per card",
          },
          ultra: {
            text: "Unlimited free cards",
          },
        },
      },
      {
        feature: {
          text: "ATM withdrawals in the UAE",
          growth: {
            text: "Free",
          },
          premium: {
            text: "Free",
          },
          ultra: {
            text: "Free",
          },
        },
      },
      {
        feature: {
          text: "ATM withdrawals internationally",
          growth: {
            text: "AED 20",
          },
          premium: {
            text: "AED 20",
          },
          ultra: {
            text: "AED 20",
          },
        },
      },
      {
        feature: {
          text: "UAE payouts",
          tooltip:
            "Save time by initiating bank transfers to pay vendors and settle your invoices directly from your Mamo Business balance.",
          subtext: "To business and individuals",
          growth: {
            text: "--",
          },
          premium: {
            text: "10 free payouts",
            subtext: "+ AED 2.5 per payout",
          },
          ultra: {
            text: "20 free payouts",
            subtext: "+ AED 1.5 per payout",
          },
        },
      },
    ],
  },
  {
    id: "settlements",
    title: "Settlements",
    list: [
      {
        feature: {
          text: "Standard 5 business days",
          growth: {
            text: "Free",
          },
          premium: {
            text: "Free",
          },
          ultra: {
            text: "Free",
          },
        },
      },
      {
        feature: {
          text: "3 business days",
          growth: {
            text: "+ 1%",
          },
          premium: {
            text: "+ 0.5%",
          },
          ultra: {
            text: "Free",
          },
        },
      },
      {
        feature: {
          text: "Same business day",
          growth: {
            text: "+ 3%",
          },
          premium: {
            text: "+ 1.5%",
          },
          ultra: {
            text: "+ 1.4%",
          },
        },
      },
    ],
  },
  {
    id: "features",
    title: "Features",
    list: [
      {
        feature: {
          text: "Create unlimited payment links with customizable fields",
          growth: {
            text: "yes",
          },
          premium: {
            text: "yes",
          },
          ultra: {
            text: "yes",
          },
        },
      },
      {
        feature: {
          text: "Create and send invoices from your dashboard",
          growth: {
            text: "yes",
          },
          premium: {
            text: "yes",
          },
          ultra: {
            text: "yes",
          },
        },
      },
      {
        feature: {
          text: "Accept payments via Apple Pay and Google Pay",
          growth: {
            text: "yes",
          },
          premium: {
            text: "yes",
          },
          ultra: {
            text: "yes",
          },
        },
      },
      {
        feature: {
          text: "Integrate our API on your website",
          growth: {
            text: "yes",
          },
          premium: {
            text: "yes",
          },
          ultra: {
            text: "yes",
          },
        },
      },
      {
        feature: {
          text: "Enable Buy Now Pay Later with Tabby",
          growth: {
            text: "yes",
          },
          premium: {
            text: "yes",
          },
          ultra: {
            text: "yes",
          },
        },
      },
      {
        feature: {
          text: "Branded payment links and invoices",
          growth: {
            text: "--",
          },
          premium: {
            text: "yes",
          },
          ultra: {
            text: "yes",
          },
        },
      },
      {
        feature: {
          text: "Subscriptions",
          growth: {
            text: "--",
          },
          premium: {
            text: "yes",
          },
          ultra: {
            text: "yes",
          },
        },
      },
    ],
  },
  {
    id: "support",
    title: "Support",
    list: [
      {
        feature: {
          text: "Help center access and chatbot support",
          growth: {
            text: "yes",
          },
          premium: {
            text: "yes",
          },
          ultra: {
            text: "yes",
          },
        },
      },
      {
        feature: {
          text: "Mamo account specialist response time",
          growth: {
            text: "2 business days",
          },
          premium: {
            text: "1 business day",
          },
          ultra: {
            text: "3 business hours",
          },
        },
      },
    ],
  },
  {
    id: "optional-add-ons",
    title: "Optional add-ons",
    list: [
      {
        feature: {
          text: "VIP support - 1 business hour",
          growth: {
            text: "AED 500 per month",
          },
        },
      },
      {
        feature: {
          text: "Full hands-on API integration services",
          growth: {
            text: "Contact us for a quote",
          },
        },
      },
      {
        feature: {
          text: "Expedited same-day account verification",
          growth: {
            text: "AED 300 one-time",
          },
        },
      },
    ],
  },
];
