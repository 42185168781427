import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export const Menu = ({ size, fill = colorsV2.text[1], ...rest }: IIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=menu, Size=24">
        <path
          id="Vector"
          d="M3.70221 18.2212C3.46181 18.2212 3.25977 18.1389 3.09609 17.9744C2.93242 17.8099 2.85059 17.6048 2.85059 17.3592C2.85059 17.1176 2.93242 16.9159 3.09609 16.7543C3.25977 16.5927 3.46181 16.5119 3.70221 16.5119H20.2979C20.5383 16.5119 20.7413 16.5942 20.907 16.7587C21.0726 16.9233 21.1555 17.1263 21.1555 17.368C21.1555 17.6136 21.0726 17.8172 20.907 17.9788C20.7413 18.1404 20.5383 18.2212 20.2979 18.2212H3.70221ZM3.70221 12.8516C3.46181 12.8516 3.25977 12.7694 3.09609 12.6048C2.93242 12.4403 2.85059 12.2373 2.85059 11.9956C2.85059 11.754 2.93242 11.5524 3.09609 11.3907C3.25977 11.2292 3.46181 11.1484 3.70221 11.1484H20.2979C20.5383 11.1484 20.7413 11.2306 20.907 11.3951C21.0726 11.5597 21.1555 11.7627 21.1555 12.0044C21.1555 12.246 21.0726 12.4476 20.907 12.6092C20.7413 12.7708 20.5383 12.8516 20.2979 12.8516H3.70221ZM3.70221 7.48805C3.46181 7.48805 3.25977 7.40579 3.09609 7.24127C2.93242 7.07674 2.85059 6.87167 2.85059 6.62605C2.85059 6.3844 2.93242 6.18278 3.09609 6.0212C3.25977 5.8596 3.46181 5.7788 3.70221 5.7788H20.2979C20.5383 5.7788 20.7413 5.86106 20.907 6.02557C21.0726 6.19009 21.1555 6.39317 21.1555 6.63482C21.1555 6.88044 21.0726 7.08405 20.907 7.24565C20.7413 7.40725 20.5383 7.48805 20.2979 7.48805H3.70221Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
