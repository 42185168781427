import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export const WhatsApp = ({ size, fill = colorsV2.text[1], ...rest }: IIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clip-path="url(#clip0_2486_9914)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.3898 3.48501C18.1465 1.23878 15.1628 0.00113964 11.9838 0C5.43322 0 0.101997 5.33065 0.099718 11.883C0.0985783 13.9776 0.646174 16.0221 1.68609 17.8239L0 23.9825L6.29991 22.33C8.03556 23.2771 9.99003 23.7756 11.9787 23.7762H11.9838C18.5333 23.7762 23.8651 18.445 23.8674 11.8927C23.8685 8.7171 22.6337 5.7318 20.3898 3.48558V3.48501ZM11.9838 21.7693H11.9798C10.2077 21.7688 8.46922 21.2924 6.95238 20.3926L6.59166 20.1784L2.85308 21.159L3.85083 17.5139L3.61606 17.1401C2.62743 15.5674 2.10491 13.7497 2.10605 11.8836C2.10833 6.43781 6.53923 2.0069 11.9879 2.0069C14.6261 2.00804 17.106 3.03656 18.9709 4.90386C20.836 6.77059 21.8622 9.25273 21.8611 11.8915C21.8588 17.3379 17.4279 21.7688 11.9838 21.7688V21.7693ZM17.4017 14.372C17.1048 14.2232 15.645 13.5052 15.3725 13.4061C15.1002 13.3069 14.9025 13.2574 14.7047 13.5548C14.507 13.8523 13.9378 14.5213 13.7645 14.719C13.5913 14.9173 13.4181 14.9418 13.1212 14.793C12.8244 14.6443 11.8676 14.3309 10.7331 13.3195C9.85048 12.532 9.25439 11.5599 9.08122 11.2624C8.90798 10.965 9.06298 10.8043 9.21109 10.6567C9.34442 10.5234 9.50798 10.3097 9.65668 10.1365C9.80546 9.96325 9.85445 9.83907 9.95357 9.6413C10.0528 9.44301 10.0032 9.26983 9.92911 9.12106C9.85498 8.97235 9.26128 7.51074 9.01339 6.91644C8.77233 6.33752 8.52736 6.41615 8.34557 6.40647C8.17233 6.39792 7.97463 6.39621 7.77633 6.39621C7.57803 6.39621 7.25662 6.47029 6.98427 6.76774C6.71191 7.06515 5.94491 7.78369 5.94491 9.24471C5.94491 10.7057 7.0088 12.1183 7.1575 12.3166C7.30621 12.5149 9.25154 15.5139 12.23 16.8005C12.9383 17.1065 13.4916 17.2894 13.9229 17.4262C14.6341 17.6524 15.2814 17.6205 15.7931 17.5441C16.3635 17.4587 17.5498 16.8256 17.7971 16.1321C18.0444 15.4386 18.0444 14.8437 17.9704 14.7201C17.8963 14.5964 17.698 14.5218 17.4011 14.3731L17.4017 14.372Z"
          fill="#25D366"
        />
      </g>
      <defs>
        <clipPath id="clip0_2486_9914">
          <rect width="23.8674" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
