export const PAGINTATION_META_INIT_STATE: TPaginationMeta = {
  page: 1,
  perPage: 10,
  totalPages: null,
  nextPage: null,
  prevPage: null,
  from: null,
  to: null,
  totalCount: null,
};
