import type { RenderFunctionResult } from "react-on-rails/node_package/lib/types";
import { Provider } from "react-redux";

import { ReactQueryProvider } from "components/Providers";
import TeamContainer from "./TeamContainer";

import type IRailsContext from "interfaces/IRailsContext";
import { store } from "store";

export default (
  props: any,
  railsContext: IRailsContext,
): RenderFunctionResult => {
  return () => (
    <ReactQueryProvider>
      <Provider store={store}>
        <TeamContainer props={props} railsContext={railsContext} />
      </Provider>
    </ReactQueryProvider>
  );
};
