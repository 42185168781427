import { createContext, useState } from "react";

import { showHideIntercom } from "utils/displayUtils";
import { eventEmitter } from "utils/eventEmitter";

export const sideSheetContextInitValues = {
  isSideSheetOpen: false,
  openSideSheet: () => {},
  closeSideSheet: () => {},
};

// Create a context to manage the state of the SideSheet
export const SideSheetContext = createContext(sideSheetContextInitValues);

export const SideSheetProvider = ({ children }) => {
  const [isSideSheetOpen, setIsSideSheetOpen] = useState(false);

  // Function to open the SideSheet
  const openSideSheet = () => {
    showHideIntercom("hide");
    setIsSideSheetOpen(true);
  };

  // Function to close the SideSheet
  const closeSideSheet = () => {
    showHideIntercom("show");
    setIsSideSheetOpen(false);
    eventEmitter.emit("sideSheetClosed");
  };

  return (
    <SideSheetContext.Provider
      value={{ isSideSheetOpen, openSideSheet, closeSideSheet }}
    >
      {children}
    </SideSheetContext.Provider>
  );
};
