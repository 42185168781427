import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export const Delete = ({ size, fill = colorsV2.text[1], ...rest }: IIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=delete, Size=24">
        <path
          id="Vector"
          d="M6.47723 21.1495C6.0031 21.1495 5.59969 20.9837 5.26701 20.6522C4.93432 20.3207 4.76798 19.9187 4.76798 19.4462V5.29783H4.49298C4.25258 5.29783 4.05055 5.21557 3.88688 5.05105C3.7232 4.88654 3.64136 4.68345 3.64136 4.4418C3.64136 4.20017 3.7232 3.99855 3.88688 3.83695C4.05055 3.67535 4.25258 3.59455 4.49298 3.59455H8.59843C8.59843 3.35 8.68027 3.14593 8.84393 2.98233C9.00762 2.81875 9.20966 2.73695 9.45006 2.73695H14.5441C14.7845 2.73695 14.9865 2.81991 15.1502 2.98583C15.3139 3.15174 15.3957 3.35465 15.3957 3.59455H19.5071C19.7475 3.59455 19.9496 3.67682 20.1132 3.84135C20.2769 4.00587 20.3588 4.20895 20.3588 4.45058C20.3588 4.69223 20.2769 4.89384 20.1132 5.05543C19.9496 5.21703 19.7475 5.29783 19.5071 5.29783H19.2321V19.4462C19.2321 19.9187 19.0658 20.3207 18.7331 20.6522C18.4004 20.9837 17.997 21.1495 17.5229 21.1495H6.47723ZM17.5229 5.29783H6.47723V19.4462H17.5229V5.29783ZM9.09733 17.344H10.699V7.36903H9.09733V17.344ZM13.3011 17.344H14.9088V7.36903H13.3011V17.344Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
