import { hexToRgb } from "utils/hexToRgbUtils";

const palette = {
  blue: {
    950: "#1A2F6B",
    900: "#20388D",
    800: "#1C31B5",
    700: "#222BE2",
    600: "#3333FF",
    500: "#4D4FFF",
    400: "#7073FF",
    300: "#999EFF",
    200: "#BDBFFF",
    100: "#DCDBFF",
    50: "#ECEBFF",
  },
  pink: {
    950: "#570024",
    900: "#99024B",
    800: "#B40051",
    700: "#D70056",
    600: "#FF0066",
    500: "#FF2474",
    400: "#FF5D93",
    300: "#FF97B8",
    200: "#FFC6D8",
    100: "#FFE0E9",
    50: "#FFEFF3",
  },
  green: {
    950: "#00331E",
    900: "#0B5B38",
    800: "#0A7142",
    700: "#058F51",
    600: "#01CB6D",
    500: "#0ADB7A",
    400: "#34F49A",
    300: "#75FFBF",
    200: "#B3FFDA",
    100: "#D6FFED",
    50: "#F0FFF7",
  },
  yellow: {
    950: "#431F05",
    900: "#723D11",
    800: "#864A0D",
    700: "#A35E05",
    600: "#CC8602",
    500: "#ECAF06",
    400: "#FDC812",
    300: "#FFE366",
    200: "#FFEE89",
    100: "#FFF9C2",
    50: "#FEFBE8",
  },
  red: {
    950: "#470C08",
    900: "#831F19",
    800: "#9E1E16",
    700: "#BF2016",
    600: "#E3291D",
    500: "#F6483D",
    400: "#FD756C",
    300: "#FFA8A2",
    200: "#FFCBC8",
    100: "#FFE3E1",
    50: "#FEF3F2",
  },
  gray: {
    950: "#23272E",
    900: "#343A46",
    800: "#3A4252",
    700: "#434E61",
    600: "#526077",
    500: "#64748B",
    400: "#8695AA",
    300: "#B1BBC8",
    200: "#D5D9E2",
    100: "#ECEEF2",
    50: "#F6F7F9",
  },
  white: {
    100: "#FFFFFF",
  },
  black: {
    100: "#000000",
  },
};

// NOTE: ALL COLORS WILL START REFERENCING colorsV2 OBJECT. REMOVE THE OLD colors OBJECT ONCE EVERYTHING'S TRANSITIONED
export const colorsV2 = {
  primary: {
    1: palette.blue[600],
    2: palette.blue[700],
  },
  secondary: {
    1: palette.blue[100],
    2: palette.blue[200],
  },
  qualitative: {
    aqua: "#3DC8F5",
    blue: {
      1: palette.blue[500],
      2: palette.blue[300],
    },
    orange: "#FF961A",
    pink: palette.pink[600],
    green: {
      1: palette.green[600],
      2: palette.green[700],
      3: palette.green[400],
    },
    azure: "#167AF3",
    purple: "#8426F9",
    yellow: {
      1: palette.yellow[400],
      2: palette.yellow[600],
    },
    gray: palette.gray[200],
  },
  error: {
    1: palette.red[600],
    2: palette.red[700],
  },
  surface: {
    1: palette.white[100],
    2: palette.gray[50],
    3: palette.gray[100],
    4: palette.gray[200],
    5: palette.gray[300],
    6: `rgba(${hexToRgb(palette.blue[50])}, 0.6)`,
    7: `rgba(${hexToRgb(palette.gray[50])}, 0.75)`,
  },
  border: {
    1: palette.gray[300],
    2: palette.gray[200],
    3: palette.gray[100],
    4: palette.blue[400],
    5: palette.gray[700],
  },
  text: {
    1: palette.gray[950],
    2: palette.gray[700],
    3: palette.gray[500],
    onprimary: palette.white[100],
    disabled: palette.gray[300],
  },
  accent: {
    green: {
      1: palette.green[700],
      2: palette.green[200],
      3: palette.green[300],
    },
    yellow: {
      1: palette.yellow[600],
      2: palette.yellow[700],
      3: palette.yellow[100],
      4: palette.yellow[200],
    },
    pink: {
      1: palette.pink[600],
      2: palette.pink[100],
      3: palette.pink[200],
    },
    blue: {
      1: palette.blue[600],
      2: palette.blue[50],
      3: palette.blue[100],
    },
    red: {
      1: palette.red[600],
      2: palette.red[200],
      3: palette.red[300],
      4: palette.red[100],
    },
  },
  overlay: {
    1: `rgba(${hexToRgb(palette.gray[100])}, 0.4)`,
  },
};

// ⚠️ NOTE: BELOW USAGE IS DEPRECATED. USE colorsV2
export const colors = {
  blue: {
    500: "#3333ff",
    400: "#5252ff",
    300: "#7070ff",
    200: "#9999ff",
    100: "#c2c2ff",
    50: "#e7e7ff",
    25: "#f3f3ff",
  },
  pink: {
    500: "#ff0066",
    400: "#ff267d",
    300: "#ff4d94",
    200: "#ff80b3",
    100: "#ffb3d1",
    50: "#ffe0ed",
    25: "#fff4f9",
  },
  yellow: {
    500: "#ffd147",
    400: "#ffd863",
    300: "#ffdf7e",
    200: "#ffe8a3",
    100: "#fff1c8",
    50: "#fff6dd",
    25: "#fffbf1",
  },
  red: {
    500: "#e3291d",
    400: "#f64830",
    300: "#ff6b57",
    200: "#ff9f8a",
    100: "#ffc8bc",
    50: "#ffe8e3",
    25: "#fff5f2",
  },
  green: {
    500: "#1e8a5e",
    400: "#2db37c",
    300: "#46d39a",
    200: "#7ee0b8",
    100: "#bef0dc",
    50: "#e3faf1",
    25: "#f0fbf7",
  },
  black: {
    500: "#080826",
    400: "#26303e",
    300: "#525a67",
    200: "#9ba1b3",
    100: "#c7cedc",
    50: "#e5e9f0",
    25: "#f3f5f9",
  },
  white: "#ffffff",
};
