import * as Tabs from "@radix-ui/react-tabs";
import { useNavigate } from "react-router-dom";

import { FadeIn } from "components/AnimatedElements/FadeIn";
import Button from "components/Button/Button";
import { Tooltip } from "components/Tooltip";

import routes from "constants/routes";
import { isAppPlatform } from "helpers/isAppPlatform";
import { allFeatures, plansList } from "../constant";
import type IChoosePlanProps from "../interfaces/IChoosePlanProps";
import { ChoosePlanActionTypes } from "../reducers/choosePlanReducer";

interface IStep1Props {
  activePlan: IChoosePlanProps["activePlan"];
  plans: IChoosePlanProps["plans"];
  state: any;
  dispatch: any;
}

const Step1 = ({ activePlan, plans, state, dispatch }: IStep1Props) => {
  const navigate = useNavigate();

  const handleIsActive = (plan: any) => {
    if (activePlan.name === plan.name) {
      return true;
    }
    return false;
  };

  const handleFeatureContent = (feature: any) => {
    if (feature === "yes") {
      return (
        <span className="material-icons-round text-24 text-green-400">
          check_circle
        </span>
      );
    }

    if (feature) {
      return feature;
    }
  };

  const handleNextStep = (plan: any) => {
    if (plan.name === "Growth") {
      dispatch({
        type: ChoosePlanActionTypes.SET_GROWTH_PLAN,
      });
    } else if (plan.name === "Premium") {
      dispatch({
        type: ChoosePlanActionTypes.SET_PREMIUM_PLAN,
      });
    } else if (plan.name === "Ultra") {
      dispatch({
        type: ChoosePlanActionTypes.SET_ULTRA_PLAN,
      });
    }
    navigate(routes.MANAGE.PLANS.CHOOSE_SETTLEMENT());
  };

  return (
    <section className="space-y-32 bg-black-25 py-80 md:space-y-40 md:px-32 lg:px-48">
      {/* TITLE AND CLOSE BUTTON */}
      <FadeIn custom={0}>
        <header className="mx-24 mb-40 text-center md:mx-48 lg:mx-auto lg:mb-64">
          <h1 data-testid="cp-step1-title">Choose your plan</h1>
          <p
            data-testid="cp-step1-subtitle"
            className="mt-16 text-16 text-black-300 md:text-18"
          >
            Explore our plans and choose the right one for your business
          </p>
          {!isAppPlatform() && (
            <a
              className="choose-plan__close-button"
              href={routes.MANAGE.ACCOUNT_SETTINGS.PLAN()}
            >
              <i className="material-icons-round">close</i>
            </a>
          )}
        </header>
      </FadeIn>

      {/* DESKTOPS AND ABOVE */}
      <FadeIn custom={1}>
        <div className="hidden lg:block">
          {/* HEADER */}
          <div className="choose-plan__table sticky top-0">
            <div className="choose-plan__table__header"></div>
            {plansList.map((plan) => (
              <div className="choose-plan__table__header">
                <div>
                  <p className="heading-02 font-bold" data-testid={plan.id}>
                    {plan.name}
                  </p>
                  <div
                    className="text-16 text-blue-500"
                    data-testid={plan.amount}
                  >
                    <span className="font-medium">{plan.amount}</span>{" "}
                    <span className="font-regular">/ month</span>
                  </div>
                </div>

                <Button
                  color="primary"
                  size="sm"
                  label={
                    handleIsActive(plan) ? "Current plan" : `Get ${plan.name}`
                  }
                  onClick={() => handleNextStep(plan)}
                  isDisabled={handleIsActive(plan)}
                />
              </div>
            ))}
          </div>

          {/* FEATURE SECTION ROW */}
          {allFeatures.map((features) => (
            <>
              {/* TITLE ROW */}
              <div className="choose-plan__table">
                <div
                  className="heading-02 pt-24 pr-12 pl-36 font-bold"
                  key={features.id}
                  data-testid={features.id}
                >
                  {features.title}
                </div>
                <div></div>
                {features.list.map((list) => (
                  <>
                    {list.feature.premium && (
                      <div className="border-black-25 border-l"></div>
                    )}
                    {list.feature.ultra && (
                      <div className="border-black-25 border-l"></div>
                    )}
                  </>
                ))}
              </div>

              {/* CONTENT ROWS */}
              <div className="choose-plan__table border-black-25 border-b pt-12">
                {features.list.map((list) => (
                  <>
                    <div
                      className="choose-plan__table__row pl-36"
                      data-value={list.feature.text}
                    >
                      <div className="flex flex-row items-center gap-8">
                        {list.feature.text}
                        {list.feature.tooltip && (
                          <Tooltip
                            description={list.feature.tooltip}
                            iconSize="14"
                            contentWidth="28.7rem"
                            contentPosition="right"
                          />
                        )}
                      </div>

                      {list.feature.subtext && (
                        <span className="text-12 text-black-300">
                          {list.feature.subtext}
                        </span>
                      )}
                    </div>

                    <div className="choose-plan__table__row font-medium">
                      {handleFeatureContent(list.feature.growth.text)}
                      {list.feature.growth.subtext && (
                        <span className="font-regular text-12 text-black-300">
                          {list.feature.growth.subtext}
                        </span>
                      )}
                    </div>

                    {list.feature.premium ? (
                      <div className="choose-plan__table__row border-black-25 border-l font-medium">
                        {handleFeatureContent(list.feature.premium.text)}
                        {list.feature.premium.subtext && (
                          <span className="font-regular text-12 text-black-300">
                            {list.feature.premium.subtext}
                          </span>
                        )}
                      </div>
                    ) : (
                      // Note: This is added so that the UI doesn't break in places where we don't have content. This will act as an empty row
                      <div></div>
                    )}

                    {list.feature.ultra ? (
                      <div className="choose-plan__table__row border-black-25 border-l font-medium">
                        <div className="flex flex-row items-center gap-8">
                          {handleFeatureContent(list.feature.ultra?.text)}
                          {list.feature.ultra.tooltip && (
                            <Tooltip
                              description={list.feature.ultra?.tooltip}
                              iconSize="14"
                              contentWidth="28.7rem"
                              contentPosition="right"
                            />
                          )}
                        </div>
                        {list.feature.ultra.subtext && (
                          <span className="font-regular text-12 text-black-300">
                            {list.feature.ultra.subtext}
                          </span>
                        )}
                      </div>
                    ) : (
                      // Note: This is added so that the UI doesn't break in places where we don't have content. This will act as an empty row
                      <div></div>
                    )}
                  </>
                ))}
              </div>
            </>
          ))}
        </div>
      </FadeIn>

      {/* MOBILE */}
      <FadeIn custom={1}>
        <div className="lg:hidden">
          <Tabs.Root defaultValue="Growth">
            <Tabs.List className="mx-auto w-fit-content rounded-full bg-center bg-cover bg-gradient-noise bg-no-repeat p-4">
              {plansList.map((plan) => (
                <Tabs.Trigger value={plan.name} className="gradient-tabs">
                  {plan.name}
                </Tabs.Trigger>
              ))}
            </Tabs.List>

            {plansList.map((plan) => (
              <Tabs.Content
                value={plan.name}
                className="mt-32 bg-white md:rounded-20"
              >
                {/* PRICING SECTION */}
                <div className="border-black-25 border-b pb-16">
                  <div className="px-24 py-16 font-medium text-18">Pricing</div>

                  <div className="space-y-12 px-24 py-8">
                    <div className="flex flex-row justify-between gap-8 text-black-400">
                      <div className="flex-1">
                        <div className="flex flex-row items-center gap-8">
                          <span className="text-14">Monthly billing</span>
                        </div>
                      </div>

                      <div className="flex-1 text-right font-medium text-12">
                        {plans[2].name === plan.name && (
                          <p>
                            {plan.amount}{" "}
                            <span className="font-regular">/ month</span>
                          </p>
                        )}

                        {plans[0].name === plan.name && (
                          <p>
                            {plan.amount}{" "}
                            <span className="font-regular">/ month</span>
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="pt-8">
                      <Button
                        color="primary"
                        size="sm"
                        label={
                          handleIsActive(plan)
                            ? "Current plan"
                            : `Get ${plan.name}`
                        }
                        classes="w-full"
                        onClick={() => handleNextStep(plan)}
                        isDisabled={
                          handleIsActive(plan) || activePlan.name === "Ultra"
                        }
                      />
                    </div>
                  </div>
                </div>

                {/* ALL FEATURES SECTION */}
                {allFeatures.map((features) => (
                  <div className="border-black-25 border-b pb-16">
                    <div
                      key={features.id}
                      className="px-24 py-16 font-medium text-18"
                    >
                      {features.title}
                    </div>

                    {features.list.map((list) => (
                      <div className="flex flex-row justify-between gap-8 px-24 py-8 text-black-400">
                        <div className="flex-1">
                          <div className="flex flex-row items-center gap-8">
                            <span className="text-14">{list.feature.text}</span>
                            {list.feature.tooltip && (
                              <Tooltip
                                description={list.feature.tooltip}
                                iconSize="14"
                                contentWidth="28.7rem"
                                contentPosition="right"
                              />
                            )}
                          </div>
                          {list.feature.subtext && (
                            <span className="text-10 text-black-300">
                              {list.feature.subtext}
                            </span>
                          )}
                        </div>

                        <div className="flex-1 text-right font-medium text-12">
                          {plans[2].name === plan.name && (
                            <>
                              <p>
                                {handleFeatureContent(list.feature.growth.text)}
                              </p>
                              {list.feature.growth.subtext && (
                                <p className="font-regular text-10 text-black-300">
                                  {list.feature.growth.subtext}
                                </p>
                              )}
                            </>
                          )}

                          {plans[0].name === plan.name && (
                            <>
                              <p>
                                {handleFeatureContent(
                                  list.feature.premium?.text,
                                )}
                              </p>
                              {list.feature.premium?.subtext && (
                                <p className="font-regular text-10 text-black-300">
                                  {list.feature.premium?.subtext}
                                </p>
                              )}
                            </>
                          )}

                          {plans[4].name === plan.name && (
                            <>
                              <div className="flex flex-row items-center justify-end gap-8">
                                <p>
                                  {handleFeatureContent(
                                    list.feature.ultra?.text,
                                  )}
                                </p>
                                {list.feature.ultra?.tooltip && (
                                  <Tooltip
                                    description={list.feature.ultra?.tooltip}
                                    iconSize="14"
                                    contentWidth="28.7rem"
                                    contentPosition="right"
                                  />
                                )}
                              </div>
                              {list.feature.ultra?.subtext && (
                                <p className="font-regular text-10 text-black-300">
                                  {list.feature.ultra?.subtext}
                                </p>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </Tabs.Content>
            ))}
          </Tabs.Root>
        </div>
      </FadeIn>

      <div className="mt-24 text-center text-12 text-black-200 md:text-14">
        <p>All prices are exclusive of VAT</p>
      </div>
    </section>
  );
};

export default Step1;
