import { useDispatch } from "react-redux";

import { TableCell } from "components/Table/TableCell";

import { setPagination } from "reducers/redux/TableReducer/tableReducer";

type TColumnCell = {
  row: {
    original: any;
  };
};
export const useTaxRatesTable = () => {
  const dispatch = useDispatch();

  const COLUMNS = [
    {
      header: "Name",
      accessorKey: "name",
      cell: (props: TColumnCell) => (
        <TableCell mainContent={props.row.original.name} />
      ),
    },
    {
      header: "Tax rate",
      accessorKey: "totalTaxRate",
      meta: {
        alignment: "end",
      },
      cell: (props: TColumnCell) => {
        const record = props.row.original;

        return (
          <TableCell
            mainContent={record.totalTaxRate ? `${record.totalTaxRate}%` : "-"}
            classNames={{
              container: "w-full",
              mainContent: "text-right",
            }}
          />
        );
      },
    },
  ];

  const handleSetPage = (newPageNumber: number) => {
    dispatch(setPagination({ tableName: "taxRates", page: newPageNumber }));
  };

  return {
    COLUMNS,
    handleSetPage,
  };
};
