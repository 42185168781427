import { Button } from "design_system/Button";
import { DefaultCard } from "design_system/Illustrations/Cards";

import routes from "constants/routes";

export const CardsUpsellBanner = () => {
  const handleMoveToKyb = () => {
    window.location.href = routes.MANAGE.CARDS.KYB();
  };

  return (
    <>
      {/* Web View */}
      <section className="hidden h-220 w-851 justify-between rounded-12 bg-gradient-noise-light-web p-40 md:flex">
        <div className="w-7/12 text-surface-1 ">
          <span className="header-medium mt-6">
            Corporate cards, built for saving
          </span>
          <p className="body-medium mt-8">
            No transaction fees. No FX fees. Issue virtual and physical cards,
            set spend limits, and manage company-wide expenses in real-time.
          </p>
          <Button
            label="Get started"
            size="md"
            variant="outlined"
            classes="mt-16"
            onClick={handleMoveToKyb}
          />
        </div>
        <DefaultCard size="222" />
      </section>

      {/* Mobile View */}
      <section className="flex flex-col items-center gap-24 rounded-12 bg-cover bg-gradient-noise-light-mobile bg-no-repeat px-16 py-24 md:hidden">
        <div className="w-full text-center text-16 text-surface-1">
          <span className="header-small">
            Corporate cards, built for saving
          </span>
          <p className="body-small mt-8">
            No transaction fees. No FX fees. Issue virtual and physical cards,
            set spend limits, and manage company-wide expenses in real-time.
          </p>
        </div>
        <DefaultCard size="222" />
        <Button
          label="Get started"
          size="md"
          variant="outlined"
          classes="mt-16 w-full"
          onClick={handleMoveToKyb}
        />
      </section>
    </>
  );
};
