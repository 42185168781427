import { type FunctionComponent, useEffect } from "react";
import toast from "react-hot-toast";

import Card from "components/ui/Card";
import Toast from "components/ui/Toast";
import { PageHeader } from "./PageHeader";

import routes from "constants/routes";
import { useToast } from "hooks/useToast";
import type IBankProps from "./interface/IBankProps";

interface IProps {
  bank: IBankProps;
}

export const BankContainer: FunctionComponent<IProps> = (props: IProps) => {
  const { iban, bankName, accountHolderName } = props.bank;
  const { toastDetails } = useToast();

  const handleGoToPage = () => {
    window.location.href = routes.MANAGE.ACCOUNT_SETTINGS.BANK.UPDATE_IBAN();
  };

  useEffect(() => {
    if (toastDetails.type) {
      toast[toastDetails.type](toastDetails.message, { duration: 3000 });
    }
  }, [toastDetails.message]);

  return (
    <>
      <Toast />
      <PageHeader />

      <div className="account-settings">
        <div className="mx-auto" style={{ maxWidth: "714px" }}>
          <Card hasBorder classes="text-16">
            {/* IBAN */}
            <div className="mb-24 space-y-4 border-black-50 border-b pb-24">
              <p className="font-medium">IBAN</p>
              <div className="flex flex-row items-center gap-16 pt-8">
                <p className="text-black-300">{iban}</p>
                <span
                  className="material-icons-round cursor-pointer text-18"
                  onClick={handleGoToPage}
                >
                  edit
                </span>
              </div>
            </div>

            {/* BANK NAME */}
            <div className="mb-24 space-y-4 border-black-50 border-b pb-24">
              <p className="font-medium">Bank name</p>
              <p className="text-black-300">{bankName}</p>
            </div>

            {/* ACCOUNT HOLDER NAME */}
            <div className="space-y-4">
              <p className="font-medium">Account holder name</p>
              <p className="text-black-300">{accountHolderName}</p>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};
