import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export const Edit = ({ size, fill = colorsV2.text[1], ...rest }: IIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=edit, Size=24">
        <path
          id="Vector"
          d="M4.54196 19.506H5.57021L16.5974 8.46685L15.5691 7.4386L4.54196 18.4777V19.506ZM19.868 7.39783L16.6441 4.17392L17.6658 3.14628C17.964 2.84804 18.3326 2.70209 18.7716 2.70842C19.2105 2.71476 19.5836 2.86757 19.8909 3.16685L20.9061 4.17608C21.2133 4.47934 21.362 4.84728 21.352 5.2799C21.342 5.7125 21.1897 6.07613 20.8951 6.3708L19.868 7.39783ZM3.83001 21.0718C3.58731 21.0718 3.38334 20.9892 3.21809 20.824C3.05284 20.6588 2.97021 20.455 2.97021 20.2124V18.1912C2.97021 18.0782 2.98987 17.9731 3.02919 17.8758C3.06851 17.7785 3.13381 17.6842 3.22511 17.5929L15.5941 5.22392L18.824 8.44185L6.45502 20.8169C6.3637 20.9081 6.26659 20.9735 6.16369 21.0128C6.06081 21.0521 5.95701 21.0718 5.85229 21.0718H3.83001ZM16.0892 7.95273L15.5691 7.4386L16.5974 8.46685L16.0892 7.95273Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
