import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export const Search = ({ size, fill = colorsV2.text[1], ...rest }: IIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=search, Size=24">
        <path
          id="Vector"
          d="M9.41417 15.8766C7.58359 15.8766 6.03401 15.2417 4.76542 13.9718C3.49684 12.7018 2.86255 11.1696 2.86255 9.375C2.86255 7.58043 3.49752 6.04818 4.76745 4.77825C6.03737 3.50833 7.57377 2.87337 9.37667 2.87337C11.1796 2.87337 12.7118 3.50833 13.9734 4.77825C15.235 6.04818 15.8658 7.58168 15.8658 9.37875C15.8658 10.1009 15.7521 10.7923 15.5248 11.453C15.2974 12.1137 14.9564 12.7337 14.5017 13.3131L20.5158 19.2772C20.6857 19.4437 20.7707 19.6501 20.7707 19.8964C20.7707 20.1426 20.6857 20.3507 20.5158 20.5207C20.3438 20.6946 20.1341 20.7815 19.8869 20.7815C19.6397 20.7815 19.4348 20.6946 19.2723 20.5207L13.2832 14.5375C12.7872 14.9586 12.2072 15.287 11.5432 15.5229C10.8792 15.7587 10.1695 15.8766 9.41417 15.8766ZM9.3913 14.1734C10.7201 14.1734 11.8475 13.7047 12.7735 12.7673C13.6995 11.8299 14.1625 10.6991 14.1625 9.375C14.1625 8.0509 13.6993 6.92015 12.7727 5.98275C11.8461 5.04533 10.719 4.57663 9.3913 4.57663C8.04973 4.57663 6.91009 5.04533 5.97237 5.98275C5.03466 6.92015 4.5658 8.0509 4.5658 9.375C4.5658 10.6991 5.03437 11.8299 5.9715 12.7673C6.90862 13.7047 8.04855 14.1734 9.3913 14.1734Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
