import type { FunctionComponent } from "react";

import PersonalContainer from "./PersonalContainer";
import type IPersonalProps from "./interface/IPersonalProps";

const PersonalRoot: FunctionComponent<IPersonalProps> = (
  props: IPersonalProps,
) => {
  return <PersonalContainer personal={props} />;
};

export default PersonalRoot;
