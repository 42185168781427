import isEmpty from "lodash/isEmpty";
import type React from "react";
import { useEffect, useState } from "react";

import { Banner } from "design_system/Banner";
import { ErrorFill } from "design_system/Icons";
import { ComplianceBannerModal } from "./ComplianceBannerModal";

interface ComplianceBannerProps {
  currentMerchant: CurrentMerchant;
  showComplianceBanner: boolean;
  setShowComplianceBanner: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ComplianceBanner = ({
  currentMerchant,
  showComplianceBanner,
  setShowComplianceBanner,
}: ComplianceBannerProps) => {
  const [showModal, setShowModal] = useState<boolean>();
  const [documentsList, setDocumentsList] =
    useState<ComplianceDocument[]>(null);

  const documentsToUpdate = currentMerchant?.documentsToUpdate;
  const hasExpiredDocument = documentsList?.some((doc) => doc.expired);

  useEffect(() => {
    if (!isEmpty(documentsToUpdate)) {
      setDocumentsList(documentsToUpdate);
    }
  }, [documentsToUpdate]);

  useEffect(() => {
    if (!isEmpty(documentsList)) {
      setShowComplianceBanner(true);
    } else {
      setShowComplianceBanner(false);
    }
  }, [documentsList]);

  return (
    <>
      {showComplianceBanner && (
        <Banner
          id="expiry-docs"
          leadingElement="icon"
          Icon={ErrorFill}
          variant={hasExpiredDocument ? "critical" : "attention"}
          title="You have documents that are expired or about to expire"
          description="Please update your documents to ensure your account remains active."
          hasButton
          buttonProps={{
            label: "Update",
            onClick: () => setShowModal(true),
          }}
        />
      )}

      <ComplianceBannerModal
        show={showModal}
        setShowModal={setShowModal}
        documentsList={documentsList}
        setDocumentsList={setDocumentsList}
      />
    </>
  );
};
