import { useState } from "react";
import { useDispatch } from "react-redux";

import { useGetExpenseCategories } from "hooks/Expenses/useGetExpenseCategories";
import { useAppSelector } from "store";

import { PAGINTATION_META_INIT_STATE } from "constants/table";
import { showHideAddCategoryModal } from "../reducer/expenseSettingsSlice";
import type { Tab, UseDashboard } from "../typeDefs";

export const useDashboard = (): UseDashboard => {
  const dispatch = useDispatch();
  const { page } = useAppSelector((state) => state.table.expenseCategories);
  const [currentTab, setCurrentTab] = useState<Tab>("Categories");

  const { getExpenseCategories } = useGetExpenseCategories({
    mode: "withPagination",
    page,
  });

  return {
    currentTab,
    expenseCategories: getExpenseCategories.data || {
      data: [],
      paginationMeta: PAGINTATION_META_INIT_STATE,
    },
    isLoadingExpenseCategories: getExpenseCategories.isLoading || false,
    handleAddCategory: () =>
      dispatch(showHideAddCategoryModal({ showOrHideModal: "show" })),
    setCurrentTab,
  };
};
