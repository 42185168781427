import type { HTMLProps } from "react";
import { cn } from "utils/classNames";

export interface IButtonProps extends HTMLProps<HTMLButtonElement> {
  color?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "yellow"
    | "yellow-light"
    | "pink"
    | "red"
    | "green"
    | "white"
    | "wl-black"
    | string;
  size?: any;
  isIcon?: boolean;
  Icon?: React.ReactNode;
  iconPosition?: "left" | "right";
  iconFont?: string;
  iconStyle?: object;
  iconClasses?: string;
  onClick?: any;
  isOutlined?: boolean;
  isDisabled?: boolean;
  label?: string;
  classes?: string;
  style?: object;
  img?: string;
  type?: "button" | "reset" | "submit";
}

const Button = ({
  color,
  size,
  isIcon,
  Icon,
  iconPosition,
  iconFont,
  iconStyle,
  iconClasses,
  onClick,
  isOutlined,
  isDisabled,
  label,
  classes,
  style,
  type,
}: IButtonProps) => {
  if (isIcon && !iconPosition) {
    return (
      <button
        className={cn(
          `button button--solid`,
          `button--${color}`,
          `button--${size}`,
          `button--icon`,
          {
            "button-outlined": isOutlined,
          },
          classes,
        )}
        style={style}
        onClick={onClick}
        disabled={isDisabled}
        type={type}
      >
        {Icon && Icon}
        {!Icon && (
          <span
            className={cn(`material-icons-round`, iconClasses)}
            style={iconStyle}
          >
            {iconFont}
          </span>
        )}
      </button>
    );
  } else if (iconPosition === "right") {
    return (
      <button
        className={cn(
          "button button--solid",
          `button--${color}`,
          `button--${size}`,
          `button--icon-${iconPosition}`,
          {
            "button-outlined": isOutlined,
          },
          classes,
        )}
        style={style}
        onClick={onClick}
        disabled={isDisabled}
        type={type}
      >
        {label}
        {Icon && Icon}
        {!Icon && (
          <span
            className={cn("material-icons-round", iconClasses)}
            style={iconStyle}
          >
            {iconFont}
          </span>
        )}
      </button>
    );
  } else if (iconPosition === "left") {
    return (
      <button
        className={cn(
          "button button--solid",
          `button--${color}`,
          `button--${size}`,
          `button--icon-${iconPosition}`,
          {
            "button-outlined": isOutlined,
          },
          classes,
        )}
        style={style}
        onClick={onClick}
        disabled={isDisabled}
        type={type}
      >
        {Icon && Icon}
        {!Icon && (
          <span
            className={cn("material-icons-round", iconClasses)}
            style={iconStyle}
          >
            {iconFont}
          </span>
        )}
        {label}
      </button>
    );
  } else {
    return (
      <button
        className={cn(
          "button button--solid",
          `button--${color}`,
          `button--${size}`,
          {
            "button-outlined": isOutlined,
          },
          classes,
        )}
        style={style}
        onClick={onClick}
        disabled={isDisabled}
        type={type}
      >
        {label}
      </button>
    );
  }
};

export const ApplePayButton = ({ onClick, isDisabled }: IButtonProps) => {
  return (
    <>
      <button
        onClick={onClick}
        disabled={isDisabled}
        className="apple-pay-button apple-pay-button-black"
        type="button"
      >
        Pay with <span className="logo"></span>
      </button>
    </>
  );
};

export const GooglePayButton = ({
  classes,
  onClick,
  isDisabled,
}: IButtonProps) => {
  return (
    <>
      <button
        className={cn("google-pay-button google-pay-button-black", classes)}
        onClick={onClick}
        disabled={isDisabled}
        type="button"
      >
        <img
          src="https://www.gstatic.com/instantbuy/svg/dark/pay/en.svg"
          alt="Google Pay button"
          className="logo"
        />
      </button>
    </>
  );
};

export default Button;
