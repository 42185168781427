import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { cn } from "utils/classNames";

import Toast from "components/ui/Toast";
import { ComplianceBanner } from "./ComplianceBanner";
import { HolidayBanner } from "./HolidayBanner";
import { VerificationBanner } from "./VerificationBanner";

import { useGlobalConfig } from "hooks/useGetGlobalConfig";
import { useAppSelector } from "store";
import { useGetRole } from "utils/rbacUtils";

import { GlobalConfigProvider } from "providers/GlobalConfigProvider";
import { setShowGlobalBanner } from "reducers/redux/GlobalBanner/globalBannerSlice";
import { getMerchantInfoFromLocalStorage } from "utils/getMerchantInfoFromLocalStorage";

export const GlobalBanner = () => {
  const { globalConfig } = useGlobalConfig();
  const { hasPermission } = useGetRole();
  const merchantDetails = getMerchantInfoFromLocalStorage();
  const dispatch = useDispatch();
  const showGlobalBanner = useAppSelector(
    (state) => state.globalBanner.showGlobalBanner,
  );

  const [showComplianceBanner, setShowComplianceBanner] =
    useState<boolean>(null);

  const { currentMerchant, currentUser } = merchantDetails;
  const { permissions } = currentUser;
  const { status, planSelected, notCompleteMessages } = currentMerchant;

  const isVerificationCompleteAndPricingNotSelected =
    status === "verified" && !planSelected;
  const isVerificationFailed = status === "failed_verification";
  const isVerificationNotComplete = status === "not_complete";
  const isVerificationPending =
    status === "pending_verification" && planSelected;
  const isVerificationPendingAndPricingNotSelected =
    status === "pending_verification" && !planSelected;

  const showVerificationBanner =
    isVerificationCompleteAndPricingNotSelected ||
    isVerificationFailed ||
    isVerificationNotComplete ||
    isVerificationPending ||
    isVerificationPendingAndPricingNotSelected;

  useEffect(() => {
    const showHolidayBanner = !isEmpty(globalConfig?.holidayBanner);

    dispatch(
      setShowGlobalBanner(
        showHolidayBanner || showComplianceBanner || showVerificationBanner,
      ),
    );
  }, [
    globalConfig?.holidayBanner,
    showComplianceBanner,
    showVerificationBanner,
  ]);

  return (
    <GlobalConfigProvider value={{ globalConfig }}>
      <Toast />

      <section
        className={cn("flex flex-col gap-16", {
          "mb-16": showGlobalBanner,
        })}
      >
        {globalConfig?.holidayBanner && (
          <HolidayBanner {...globalConfig.holidayBanner} />
        )}

        {hasPermission("settings:write", permissions) && (
          <ComplianceBanner
            currentMerchant={currentMerchant}
            showComplianceBanner={showComplianceBanner}
            setShowComplianceBanner={setShowComplianceBanner}
          />
        )}

        {showVerificationBanner && (
          <VerificationBanner
            isVerificationCompleteAndPricingNotSelected={
              isVerificationCompleteAndPricingNotSelected
            }
            isVerificationFailed={isVerificationFailed}
            isVerificationNotComplete={isVerificationNotComplete}
            isVerificationPending={isVerificationPending}
            isVerificationPendingAndPricingNotSelected={
              isVerificationPendingAndPricingNotSelected
            }
            notCompleteMessages={notCompleteMessages}
          />
        )}
      </section>
    </GlobalConfigProvider>
  );
};
