import { useEffect, useState } from "react";
import toast from "react-hot-toast";

import Button from "components/Button/Button";
import { CodeBlock } from "components/CodeBlock";
import Card from "components/ui/Card";
import Toast from "components/ui/Toast";
import { PageHeader } from "./PageHeader";

import { copyText } from "pages/Payment/Pay/helpers";
import { maskedApiKey, scriptString } from "./constant";
import {
  copyAPIkey,
  downloadMagentoExt,
  downloadPrestaShopExt,
  downloadWooCommerceExt,
  fetchApiKey,
  handleCopyScript,
} from "./utils";

import MagentoLogo from "assets/logos/magento.svg";
import PrestaShopLogo from "assets/logos/prestashop.png";
import ShopifyLogo from "assets/logos/shopify.svg";
import WooCommerceLogo from "assets/logos/woocommerce.png";
import ZapierLogo from "assets/logos/zapier.svg";

const Developer = () => {
  const [isApiKeyVisible, setIsApiKeyVisible] = useState<boolean>(false);
  const [apiKey, setApiKey] = useState<string>(maskedApiKey);

  const handleCopyAPIKey = async () => {
    try {
      const apiKey = await fetchApiKey();
      copyText(apiKey);
      copyAPIkey();
      toast.success("API key copied!", {
        duration: 2000,
        style: { fontSize: "16px" },
      });
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        duration: 3000,
      });
    }
  };

  useEffect(() => {
    fetchApiKey()
      .then((apiKey: any) => setApiKey(apiKey))
      .catch((error: any) => {
        toast.error(`${error.response.data.message}`, {
          duration: 3000,
        });
      });
  }, []);

  return (
    <>
      <Toast />
      <PageHeader />

      <section className="developer">
        {/* API KEY SECTION */}
        <Card hasBorder classes="space-y-16 md:space-y-24">
          <div className="flex items-start justify-between">
            <div className="space-y-8">
              <h3 data-testid="developer-api-key-title">API key</h3>
              <p className="text-16 text-black-300">
                Your unique identifier for connecting with our API.
                <br />
                Please do not share your key with anyone, for security reasons
              </p>
            </div>

            <div className="hidden lg:block">
              <Button
                color="primary"
                size="md"
                iconPosition="left"
                iconFont="content_copy"
                label="Copy key"
                onClick={handleCopyAPIKey}
              />
            </div>
          </div>

          <div className="developer__api-key">
            <p
              style={{ wordBreak: "break-all" }}
              data-testid="developer-api-key"
            >
              {isApiKeyVisible ? apiKey : maskedApiKey}
            </p>
            <button
              data-testid="developer-toggle-api-key"
              type="button"
              className="material-icons-round"
              onClick={() => setIsApiKeyVisible(!isApiKeyVisible)}
            >
              {isApiKeyVisible ? "visibility_off" : "visibility"}
            </button>
          </div>

          <div className="lg:hidden">
            <Button
              color="primary"
              size="md"
              iconPosition="left"
              iconFont="content_copy"
              label="Copy key"
              onClick={handleCopyAPIKey}
            />
          </div>
        </Card>

        {/* API DOCUMENTATION SECTION */}
        <Card hasBorder classes="developer__doc">
          <div className="space-y-8">
            <h3 data-testid="developer-api-doc-title">API documentation</h3>
            <p className="text-16 text-black-300">
              Our primary resource for any support you and your developers need
              to get started with our API
            </p>
          </div>

          <a
            href="https://mamopay.readme.io/"
            target="_blank"
            className="button button--secondary button--solid button--md"
            rel="noreferrer"
          >
            Open documentation
          </a>
        </Card>

        {/* CHECKOUT EXPERIENCE SECTION */}
        <Card hasBorder classes="space-y-16 md:space-y-24">
          <div className="flex items-start justify-between gap-32">
            <div className="flex-1 space-y-8">
              <h3 data-testid="developer-checkout-xp-title">
                Checkout experience
              </h3>
              <div className="space-y-12 text-16 text-black-300">
                <p>
                  For checkout on your website, add the snippet below to your
                  website&rsquo;s checkout page to avoid redirecting your
                  customers to a Mamo hosted page.
                </p>
                <p>
                  When added, the{" "}
                  <code className="rounded-4 bg-black-25 p-6 text-14 text-black-500">
                    link_type=inline
                  </code>{" "}
                  should be passed as part of the payment link creation payload.
                </p>
              </div>
            </div>

            <div className="hidden lg:block">
              <Button
                color="primary"
                size="md"
                iconPosition="left"
                iconFont="content_copy"
                label="Copy script"
                onClick={handleCopyScript}
              />
            </div>
          </div>

          <CodeBlock
            // @ts-ignore
            language="html"
          >
            {scriptString}
          </CodeBlock>

          <div className="lg:hidden">
            <Button
              color="primary"
              size="md"
              iconPosition="left"
              iconFont="content_copy"
              label="Copy script"
              onClick={handleCopyScript}
            />
          </div>
        </Card>

        {/* EXTENSIONS SECTION */}
        {/* NOTE: Trick on how to make files on Google Drive downloadable: https://www.labnol.org/internet/direct-links-for-google-drive/28356/ */}
        <Card hasBorder classes="space-y-16 md:space-y-24">
          <div className="space-y-8">
            <h3 data-testid="developer-extensions-title">Extensions</h3>
            <p className="text-16 text-black-300">
              We have a growing list of plugins to enable the easy integration
              of our services into your platform of choice
            </p>
          </div>

          <div className="grid grid-cols-1 gap-16 md:grid-cols-2 md:gap-24 xl:grid-cols-4">
            <Card hasBorder classes="space-y-16">
              <img src={ShopifyLogo} alt="" className="h-48" />
              <div className="flex items-center justify-between">
                <h4 data-testid="developer-shopify-title">Shopify</h4>
                <a
                  data-testid="developer-download-shopify-ext"
                  href="https://apps.shopify.com/mamo-payment-app"
                  className="material-icons-round text-18 text-black-200"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  open_in_new
                </a>
              </div>
            </Card>

            <Card hasBorder classes="space-y-16">
              <img src={WooCommerceLogo} alt="" className="h-48" />
              <div className="flex items-center justify-between">
                <h4 data-testid="developer-woocommerce-title">WooCommerce</h4>
                <a
                  data-testid="developer-download-wc-ext"
                  href="https://drive.google.com/uc?export=download&id=1u_fWO8pFGFDYXSiL_GVn8m51S6XNY9DE"
                  onClick={downloadWooCommerceExt}
                  className="material-icons-round text-18 text-black-200"
                >
                  download
                </a>
              </div>
            </Card>

            <Card hasBorder classes="space-y-16">
              <img src={PrestaShopLogo} alt="" className="h-48" />
              <div className="flex items-center justify-between">
                <h4 data-testid="developer-prestashop-title">PrestaShop</h4>
                <a
                  data-testid="developer-download-ps-ext"
                  href="https://drive.google.com/uc?export=download&id=1aFTa-n_iEO5-keghcf5kpruYjv_eUkuL"
                  onClick={downloadPrestaShopExt}
                  className="material-icons-round text-18 text-black-200"
                >
                  download
                </a>
              </div>
            </Card>

            <Card hasBorder classes="space-y-16">
              <img src={MagentoLogo} alt="" className="h-48" />
              <div className="flex items-center justify-between">
                <h4 data-testid="developer-magento-title">Magento</h4>
                <a
                  data-testid="developer-download-magento-ext"
                  href="https://drive.google.com/uc?export=download&id=1_1CrY85lkeRatESsOuNuT9Lslv4UpUzn"
                  onClick={downloadMagentoExt}
                  className="material-icons-round text-18 text-black-200"
                >
                  download
                </a>
              </div>
            </Card>
          </div>
        </Card>

        {/* INTEGRATIONS SECTION */}
        <Card hasBorder classes="space-y-16 md:space-y-24">
          <div className="space-y-8">
            <h3 data-testid="developer-integrations-title">Integrations</h3>
            <p className="text-16 text-black-300">
              Our integrations with other products to streamline your workflows
            </p>
          </div>

          <Card
            hasBorder
            classes="flex flex-col gap-16 items-start xl:flex-row xl:items-center"
          >
            <div className="space-y-16">
              <img src={ZapierLogo} alt="Zapier logo" className="h-32" />

              <div className="space-y-8">
                <h3 data-testid="developer-zapier-title">
                  Integrate Mamo with Zapier
                </h3>
                <p className="text-16 text-black-300">
                  Zapier lets you connect Mamo Business with thousands of the
                  most popular apps, so you can automate your work and have more
                  time for what matters most—no code required.
                </p>
              </div>
            </div>

            <a
              href="https://zapier.com/apps/mamo-business/integrations"
              target="_blank"
              className="button button--secondary button--solid button--md xl:flex-shrink-0"
              rel="noreferrer"
            >
              Check it out
            </a>
          </Card>
        </Card>
      </section>
    </>
  );
};

export default Developer;
