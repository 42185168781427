import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export const ArrowDownward = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: IIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=arrow_downward, Size=24">
        <path
          id="Vector"
          d="M11.1484 16.9049V4.70218C11.1484 4.45811 11.2292 4.25416 11.3908 4.09033C11.5524 3.92648 11.7554 3.84455 12 3.84455C12.2446 3.84455 12.4477 3.92648 12.6093 4.09033C12.7708 4.25416 12.8516 4.45811 12.8516 4.70218V16.9049L18.3533 11.4033C18.5232 11.2333 18.7234 11.1472 18.954 11.1449C19.1846 11.1426 19.3854 11.2266 19.5563 11.3968C19.7278 11.5671 19.8126 11.7678 19.8106 11.9989C19.8086 12.2301 19.7226 12.4313 19.5527 12.6027L12.6027 19.5527C12.5108 19.6437 12.4151 19.7099 12.3157 19.7514C12.2163 19.7929 12.1108 19.8136 11.9993 19.8136C11.8878 19.8136 11.7825 19.7929 11.6835 19.7514C11.5844 19.7099 11.489 19.6437 11.3973 19.5527L4.44728 12.5968C4.27336 12.4208 4.1864 12.2194 4.1864 11.9925C4.1864 11.7656 4.27383 11.5672 4.44868 11.3973C4.61889 11.2274 4.81764 11.1424 5.04493 11.1424C5.27223 11.1424 5.47083 11.2274 5.64075 11.3973L11.1484 16.9049Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
