import * as Sentry from "@sentry/browser";
import axios from "axios";
import { get } from "lodash";
import { useState } from "react";

import { endpoints } from "constants/apiEndpoints";
import { MERCHANT_INFO_INIT_STATE } from "constants/initStates/merchantInfo";

interface IUseMerchant {
  isLoading: boolean;
  merchantDetails: TMerchantInfo;
  getMerchantDetails: () => Promise<TMerchantInfo>;
}

export const useMerchant = (): IUseMerchant => {
  const [merchantDetails, setMerchantDetail] = useState(
    MERCHANT_INFO_INIT_STATE,
  );
  const [isLoading, setIsLoading] = useState(true);

  const getMerchantDetails = async (): Promise<TMerchantInfo> => {
    try {
      const { data } = await axios.get(endpoints.MANAGE.API.MERCHANT.INFO);

      Sentry.setUser({
        merchantID: get(data, "currentMerchant.identifier", ""),
        userID: get(data, "currentUser.identifier", ""),
        email: get(data, "currentUser.email", ""),
      });
      Sentry.setTag("email", get(data, "currentUser.email", ""));

      setMerchantDetail(data);
      setIsLoading(false);
      localStorage.removeItem("merchant");
      localStorage.setItem("merchant", JSON.stringify(data));
      return data;
    } catch (error) {
      setIsLoading(false);
      console.error(`[useMerchant][getMerchantDetails] - `, error);
      return MERCHANT_INFO_INIT_STATE;
    }
  };

  return {
    isLoading,
    merchantDetails,
    getMerchantDetails,
  };
};
