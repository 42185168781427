import { createContext } from "react";

export interface IUserContext {
  userInfo: TeamMember;
  showRemoveUserModal: boolean;
  setUserInfo: (state: any) => void;
  setShowRemoveUserModal: (state: boolean) => void;
}

export const initState: IUserContext = {
  userInfo: {
    identifier: "",
    firstName: "",
    lastName: "",
    fullName: "",
    email: "",
    isPending: false,
    roles: [],
  },
  showRemoveUserModal: false,
  setUserInfo: () => {},
  setShowRemoveUserModal: () => {},
};

export const UserContext = createContext<IUserContext>(initState);

export const UserProvider = ({
  children,
  value,
}: { children: any; value: IUserContext }) => {
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
