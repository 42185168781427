import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export const Close = ({ size, fill = colorsV2.text[1], ...rest }: IIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=close, Size=24">
        <path
          id="Vector"
          d="M11.9999 13.1935L6.82167 18.3717C6.65173 18.5417 6.45282 18.6266 6.22492 18.6266C5.99702 18.6266 5.7981 18.5417 5.62817 18.3717C5.45825 18.2018 5.37329 18.0029 5.37329 17.775C5.37329 17.5471 5.45825 17.3482 5.62817 17.1783L10.8064 12L5.62817 6.82175C5.45825 6.65182 5.37329 6.4529 5.37329 6.225C5.37329 5.9971 5.45825 5.79818 5.62817 5.62825C5.7981 5.45833 5.99702 5.37337 6.22492 5.37337C6.45282 5.37337 6.65173 5.45833 6.82167 5.62825L11.9999 10.8065L17.1782 5.62825C17.3481 5.45833 17.547 5.37337 17.7749 5.37337C18.0028 5.37337 18.2017 5.45833 18.3717 5.62825C18.5416 5.79818 18.6265 5.9971 18.6265 6.225C18.6265 6.4529 18.5416 6.65182 18.3717 6.82175L13.1934 12L18.3717 17.1783C18.5416 17.3482 18.6265 17.5471 18.6265 17.775C18.6265 18.0029 18.5416 18.2018 18.3717 18.3717C18.2017 18.5417 18.0028 18.6266 17.7749 18.6266C17.547 18.6266 17.3481 18.5417 17.1782 18.3717L11.9999 13.1935Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
