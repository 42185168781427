import { useMutation } from "@tanstack/react-query";
import axios, { type AxiosError } from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "store";

import { queryParams } from "constants/queryParams";
import routes from "constants/routes";
import { SentryLoggingService } from "init/SentryLoggingService";
import {
  updateErrorMessage,
  updateSessionExpired,
} from "reducers/redux/Access/SignUp/signUpSlice";
import { SignUpSteps, errorType, errorsMapping } from "../../shared/constant";

export const useSignUp = () => {
  const { data } = useAppSelector((state) => state.signUp);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signUp = useMutation({
    mutationKey: ["signUp"],
    mutationFn: async () => {
      await axios
        .post(routes.ONBOARDING.ACCOUNTS.SIGN_UP_VALIDATION(), {
          ...queryParams,
          step: SignUpSteps.SIGNUP,
          email: data.email,
        })
        .then(() => {
          navigate(routes.ONBOARDING.VERIFY_OTP());
        });
    },
    onError: (error: AxiosError) => {
      if (errorType[error.response.data.error_code] === "email") {
        dispatch(
          updateErrorMessage({
            errorMessage: {
              email: errorsMapping[error.response.data.error_code],
            },
          }),
        );
      } else {
        SentryLoggingService.captureException(error.message, {
          feature: "[SignUp][Sign up via email]",
          function: "signUp",
          file: "useSignUp",
          data: data ? JSON.stringify(data) : null,
        });
      }
    },
    onSuccess: () => {
      dispatch(
        updateErrorMessage({
          errorMessage: {
            email: null,
          },
        }),
      );
      dispatch(updateSessionExpired({ sessionExpired: false }));
    },
  });

  return { signUp };
};
