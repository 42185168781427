import { RefreshInfoToolbar } from "../shared/RefreshInfoToolbar";
import { PaymentAccountsTable } from "./PaymentAccountsTable";

type PaymentAccountsProps = {
  accounts?: PaymentAccountsWithPagination;
};

export const PaymentAccounts = ({ accounts }: PaymentAccountsProps) => {
  return (
    <section className="flex flex-col gap-24">
      <RefreshInfoToolbar />
      <PaymentAccountsTable accounts={accounts} />
    </section>
  );
};
