import { useMemo } from "react";
import { cn } from "utils/classNames";

import { Divider } from "components/Divider";
import { TableRecord } from "components/Table/TableRecord";
import Table from "components/Table/V2/TableV2";
import { TableEmptyState } from "./TableEmptyState";

import { useScreenDimension } from "hooks/useScreenDimension";
import { useExpenseAccountsTable } from "./useExpenseAccountsTable";

type ExpenseAccountsTableProps = {
  accounts?: ExpenseAccountsWithPagination;
};

export const ExpenseAccountsTable = ({
  accounts,
}: ExpenseAccountsTableProps) => {
  const { isMobile } = useScreenDimension();

  const { COLUMNS, handleSetPage } = useExpenseAccountsTable();

  const paginationMeta = {
    ...accounts.paginationMeta,
    itemName: "Expense accounts",
  };

  const columns = useMemo(() => COLUMNS, []);
  const tableData = useMemo(() => accounts.data, [accounts.data]);

  const isEmptyTable = !accounts || accounts.data?.length === 0;

  if (!isMobile) {
    return (
      <div className="flex w-full justify-center">
        <Table
          name="expense-accounts-table"
          // @ts-ignore
          columns={columns}
          data={tableData}
          paginationMeta={paginationMeta}
          onSetPage={handleSetPage}
          tableClass="table"
          headerRowClass="bg-black-25"
          headerCellClass="w-auto"
          rowProps={{
            selectable: false,
            selectedRows: [],
            setSelectedRows: () => {},
          }}
          emptyState={<TableEmptyState />}
        />
      </div>
    );
  }
  return isEmptyTable ? (
    <TableEmptyState />
  ) : (
    <>
      {accounts.data.map((account: ExpenseAccountOption, index) => {
        return (
          <>
            <TableRecord
              key={account.name + index}
              variant="noAvatar"
              className={cn({ "-mt-16": index === 0 })}
            >
              <TableRecord.Column1 label={account.name} />
              <TableRecord.Column2 label={account.currency} />
            </TableRecord>
            <Divider />
          </>
        );
      })}
    </>
  );
};
