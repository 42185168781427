import { motion } from "framer-motion";
import type { ReactElement } from "react";

const getVariants = (direction) => {
  const distance = 50;

  switch (direction) {
    case "top":
      return {
        initial: { opacity: 0, y: -distance },
        in: { opacity: 1, y: 0 },
        out: { opacity: 0, y: distance },
      };
    case "bottom":
      return {
        initial: { opacity: 0, y: distance },
        in: { opacity: 1, y: 0 },
        out: { opacity: 0, y: -distance },
      };
    case "left":
      return {
        initial: { opacity: 0, x: -distance },
        in: { opacity: 1, x: 0 },
        out: { opacity: 0, x: distance },
      };
    case "right":
      return {
        initial: { opacity: 0, x: distance },
        in: { opacity: 1, x: 0 },
        out: { opacity: 0, x: -distance },
      };
    default:
      return {
        initial: { opacity: 0, x: distance },
        in: { opacity: 1, x: 0 },
        out: { opacity: 0, x: -distance },
      };
  }
};

const transition = {
  type: "tween",
  ease: "circOut",
  duration: 0.4,
};

export const SlideIn = ({
  direction = "bottom",
  element,
}: { direction?: Position; element: ReactElement }) => {
  const variants = getVariants(direction);

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={variants}
      transition={transition}
    >
      {element}
    </motion.div>
  );
};
