import type { ReactElement } from "react";
import type { TStatusColors } from "types/Colors";
import { cn } from "utils/classNames";

interface IProps {
  color?: TStatusColors | string;
  containerClasses?: string;
  hasIcon?: boolean;
  iconClasses?: string;
  iconFont?: string;
  label?: string;
  Icon?: ReactElement;
}

const Status = ({
  color,
  containerClasses,
  hasIcon,
  iconClasses,
  iconFont,
  label,
  Icon,
}: IProps) => {
  return (
    <span className={cn(`status status--${color}`, containerClasses)}>
      {Icon && Icon}
      {hasIcon && (
        <i className={`material-icons-round status--icon ${iconClasses}`}>
          {iconFont}
        </i>
      )}
      {label}
    </span>
  );
};

export default Status;
