import CurrencyInput from "react-currency-input-field";

import { convertToLocaleStringHelper } from "helpers/currency";

import FieldMessage from "../FieldMessage/FieldMessage";

interface IProps {
  amount?: number;
  amountCurrency: string;
  editable?: boolean;
  errorMessage?: string;
  errors?: boolean;
  showLabel?: boolean;
  onChange?: any;
}

const Price = ({
  amount,
  amountCurrency,
  editable,
  errorMessage,
  errors,
  showLabel = true,
  onChange,
}: IProps) => {
  const localeMapping: { [currency: string]: string } = {
    AED: "en-AE",
    USD: "en-US",
    EUR: "en-EU",
    GBP: "en-GB",
    SAR: "en-SA",
  };

  if (editable) {
    return (
      <div className="flex flex-col items-center justify-center gap-8">
        {showLabel && <p className="text-12 text-black-300">Enter amount</p>}
        <CurrencyInput
          data-testid="payment-link-step1-currency-input"
          className="text-center font-medium text-40 placeholder-black-200 outline-none"
          placeholder={`${amountCurrency} 0.00`}
          decimalsLimit={2}
          decimalScale={2}
          prefix={
            amountCurrency === "AED"
              ? `${amountCurrency}`
              : `${amountCurrency} `
          }
          intlConfig={{
            locale: localeMapping[amountCurrency],
            currency: amountCurrency,
          }}
          onValueChange={(value, _name) => onChange(value)}
          value={amount == 0 ? undefined : amount}
        />
        {errors && (
          <FieldMessage
            messageType="error"
            icon="error"
            messageText={errorMessage}
            classes="mt-12"
          />
        )}
      </div>
    );
  } else {
    return (
      <div className="space-y-8 text-center">
        <div className="block text-12 text-black-300">Subtotal</div>
        <span className="block font-medium text-40">
          <span className="text-38">{amountCurrency} </span>
          {convertToLocaleStringHelper(amount)}
        </span>
        <input type="hidden" name="amount" value={amount} />
      </div>
    );
  }
};

export default Price;
