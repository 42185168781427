import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export const Folder = ({ size, fill = colorsV2.text[1], ...rest }: IIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=folder, Size=24">
        <path
          id="Vector"
          d="M3.55384 20.1495C3.09385 20.1495 2.69498 19.9764 2.35721 19.6303C2.01946 19.2842 1.85059 18.8895 1.85059 18.4462V5.5538C1.85059 5.10885 2.01946 4.71274 2.35721 4.36548C2.69498 4.01819 3.09385 3.84455 3.55384 3.84455H9.75059C9.98287 3.84455 10.2057 3.8922 10.4191 3.9875C10.6325 4.08278 10.8165 4.20779 10.9713 4.36253L12.0277 5.4163H20.4462C20.8912 5.4163 21.2873 5.58935 21.6346 5.93545C21.9818 6.28155 22.1555 6.67626 22.1555 7.11958V18.4462C22.1555 18.8895 21.9818 19.2842 21.6346 19.6303C21.2873 19.9764 20.8912 20.1495 20.4462 20.1495H3.55384ZM3.55384 18.4462H20.4462V7.11958H11.3163L9.75059 5.5538H3.55384V18.4462Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
