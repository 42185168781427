import { ReactQueryProvider } from "components/Providers";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { WalletContainer } from "./WalletContainer";
import { DetailPage } from "./WalletV2/DetailPage/DetailPage";

import { store } from "store";

export const WalletRoot = () => {
  return (
    <ReactQueryProvider>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/manage/wallet/:identifier" element={<DetailPage />} />
            <Route path="/manage/finances" element={<WalletContainer />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </ReactQueryProvider>
  );
};
