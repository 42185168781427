import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export const VerifiedUser = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: IIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=verified_user, Size=24">
        <path
          id="Vector"
          d="M10.975 13.1272L9.01796 11.1701C8.86398 11.0161 8.67367 10.9391 8.44704 10.9391C8.22042 10.9391 8.02812 11.0179 7.87014 11.1754C7.71615 11.3309 7.63916 11.5193 7.63916 11.7406C7.63916 11.962 7.71615 12.1544 7.87014 12.3179L10.2973 14.7701C10.4701 14.944 10.6718 15.031 10.9022 15.031C11.1326 15.031 11.3328 14.944 11.5028 14.7701L16.099 10.1739C16.2569 10.0164 16.3359 9.82941 16.3359 9.61287C16.3359 9.39632 16.2611 9.21739 16.1115 9.07608C15.9618 8.93477 15.7743 8.86829 15.5488 8.87662C15.3234 8.88496 15.1408 8.95977 15.0011 9.10108L10.975 13.1272ZM12 22.0435C11.8994 22.0435 11.8051 22.0341 11.7172 22.0155C11.6292 21.9968 11.5433 21.9688 11.4593 21.9315C9.12269 21.1482 7.27042 19.7259 5.90249 17.6646C4.53455 15.6034 3.85059 13.3559 3.85059 10.9223V6.06685C3.85059 5.70207 3.95217 5.37236 4.15534 5.07772C4.3585 4.78311 4.62576 4.57205 4.95711 4.44455L11.4033 2.0375C11.6066 1.96287 11.8055 1.92555 12 1.92555C12.1946 1.92555 12.3935 1.96287 12.5968 2.0375L19.043 4.44455C19.3743 4.57205 19.6426 4.78311 19.8477 5.07772C20.0529 5.37236 20.1555 5.70207 20.1555 6.06685V10.9223C20.1555 13.3559 19.4705 15.6034 18.1006 17.6646C16.7307 19.7259 14.8774 21.1482 12.5408 21.9315C12.4568 21.9689 12.3709 21.9968 12.2829 22.0155C12.1949 22.0342 12.1007 22.0435 12 22.0435ZM12 20.3772C13.8968 19.7239 15.4453 18.5271 16.6457 16.7867C17.8461 15.0463 18.4462 13.093 18.4462 10.9267V6.06685L12 3.63477L5.55384 6.06975V10.924C5.55384 13.092 6.15402 15.0463 7.35439 16.7867C8.55475 18.5271 10.1033 19.7239 12 20.3772Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
