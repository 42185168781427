import { useCallback } from "react";

import { isAppSession_v2 } from "pages/Payment/Pay/helpers";
import type { TPermissions } from "types/Permissions";

type ExpenseVisibilityParams = {
  cards: TPermissions;
  complianceType: ComplianceType;
  expenses: TPermissions;
  expensesFeatureEnabled: boolean;
  location: "sidebar" | "page";
  myExpenses: TPermissions;
  platform: string;
};

type UseGetExpenseVisibilityResponse = {
  getExpenseVisibility: (params: ExpenseVisibilityParams) => boolean;
};

/**
 * Custom hook to determine the visibility of expense options.
 * @returns {Function} A memoized function to calculate the expense visibility based on several conditions.
 */
export const useGetExpenseVisibility = (): UseGetExpenseVisibilityResponse => {
  /**
   * Determines if the expense options should be visible.
   *
   * @param cards - Object containing card permissions.
   * @param expenses - Object containing expenses permissions for reading everyone's expenses.
   * @param expensesFeatureEnabled - Flag indicating if the merchant has activated the expenses feature from paywall.
   * @param complianceType - The merchant's KYC type.
   * @param myExpenses - Object containing expenses permissions.
   * @param platform - The platform - ios or android.
   * @param location - The current location where this hook is required.
   * @returns True if the expense options should be shown, false otherwise.
   */
  const getExpenseVisibility = useCallback(
    ({
      cards,
      expenses,
      expensesFeatureEnabled,
      complianceType,
      myExpenses,
      platform,
      location,
    }: ExpenseVisibilityParams): boolean => {
      const isFreelancer = complianceType === "individual";

      if (location === "sidebar" && isAppSession_v2(platform)) {
        return false;
      }
      if (isFreelancer) {
        return false;
      }

      const hasReadExpensePermission =
        expenses.hasReadAccess || myExpenses.hasReadAccess;

      switch (true) {
        // Expense has been activated from paywall and current user has access to it
        case expensesFeatureEnabled && hasReadExpensePermission:
          return true;
        // Expense has not been activated, merchant is a business and current user has access to view all cards
        case !expensesFeatureEnabled && cards.hasReadAccess:
          return true;
        default:
          return false;
      }
    },
    [],
  );

  return {
    getExpenseVisibility,
  };
};
